import React, { useState } from "react";
import StepOne from "./stepOne";
import StepTwo from "./stepTwo";
import StepThree from "./stepThree";
import { Grid, Hidden, makeStyles } from "@material-ui/core";
import { useMutator } from "../../hooks/useApi";
import { getFlexipayUrl } from "../../utils/urlHelpers";
import { useErrorOccurredNotification } from "../../hooks/useNotificationState";
import { szamlazzMuiTheme } from "../../theme";
import { ThemeProvider as MUIThemeProvider } from "@material-ui/core/styles";
import calculatorImage from "../../images/flexibill_arkalkulator_img.png";

const urlParams = new URLSearchParams(window.location.search);
const source = urlParams.get("source");

const useFixCheckboxGreenToSzamlazzhuStyles = makeStyles({
  root: {
    "& label > span.Mui-checked svg rect": {
      stroke: "#FF6630",
      fill: "#FF6630"
    },
    "& label > span svg rect": {
      stroke: "#FF6630"
    },
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield"
    }
  }
});

const SubscriptionPage = () => {
  const [page, setPage] = useState(0);
  const [step1Data, setStep1Data] = useState(null);
  const [result, setResult] = useState(null);
  const [post] = useMutator("POST");
  const notifyError = useErrorOccurredNotification();
  const styles = useFixCheckboxGreenToSzamlazzhuStyles();

  return (
    <MUIThemeProvider theme={szamlazzMuiTheme}>
      <div className={styles.root}>
        <div style={{ height: "500px", padding: "20px" }}>
          <Grid container>
            {page === 0 && (
              <>
                <Grid item xs={8}>
                  <StepOne
                    onClick={data => {
                      setStep1Data(data);
                      setPage(1);
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={4}
                  style={{ alignSelf: "center", textAlign: "center" }}
                >
                  <img
                    style={{ width: "300px", margin: "auto" }}
                    src={calculatorImage}
                  />
                </Grid>
              </>
            )}
            {page === 1 && (
              <>
                <Grid item xs={8}>
                  <StepTwo
                    onClick={async values => {
                      const dataToSend = { ...values, ...step1Data, source };
                      const { data, error } = await post(
                        getFlexipayUrl("/subscription"),
                        dataToSend
                      );

                      if (data) {
                        setResult(data);
                        setPage(2);
                      } else {
                        notifyError(error);
                      }
                    }}
                    onBackButtonClick={() => setPage(0)}
                  />
                </Grid>
                <Grid
                  item
                  xs={4}
                  style={{ alignSelf: "center", textAlign: "center" }}
                >
                  <img
                    style={{ width: "300px", margin: "auto" }}
                    src={calculatorImage}
                  />
                </Grid>
              </>
            )}
            {page === 2 && (
              <Grid item xs={12}>
                <StepThree
                  data={result}
                  onRestart={data => setPage(0)}
                  onRegButtonClick={() =>
                    window.open(
                      "https://www.szamlazz.hu/szamla/?action=directlogin&targetpage=szamla"
                    )
                  }
                />
              </Grid>
            )}
          </Grid>
        </div>
      </div>
    </MUIThemeProvider>
  );
};

export default SubscriptionPage;
