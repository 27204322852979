import React, { useEffect, useState } from "react";
import FlexiDialog from "@bit/flexibill.flexi.flexi-dialog";
import helloBillImage from "../../images/bill/helloBill.svg";
import Typography from "@material-ui/core/Typography";
import { useMutator, useProfileData } from "../../hooks/useApi";
import { getUrlWithCompanyId } from "../../utils/urlHelpers";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { ROLE_FLEXIPAY_CUSTOMER } from "../../roles";

const GreetingMessageDialog = () => {
  const { mainCompany } = useProfileData();
  const [open, setOpen] = useState(false);
  const [post] = useMutator("POST");

  useEffect(
    () => {
      if (
        !mainCompany?.roles.includes(ROLE_FLEXIPAY_CUSTOMER) &&
        mainCompany?.company?.showFlexipayWelcomeMessage === true
      ) {
        setOpen(true);
      }
    },
    [mainCompany]
  );

  const onClose = () => {
    post(getUrlWithCompanyId("/welcome-message-shown")).then(() => {
      setOpen(false);
    });
  };

  return (
    <FlexiDialog
      title={"Üdvözlünk a Flexibill rendszerében!"}
      imgSrc={helloBillImage}
      imgWidth={100}
      open={open}
      onClose={onClose}
      dialogActions={
        <Box width={"100%"} textAlign={"center"}>
          <Button style={{ padding: "20px 50px" }} onClick={onClose}>
            Kérem a kalkulációt!
          </Button>
        </Box>
      }
    >
      <Typography>
        Gratulálunk a döntésedhez, a legjobb helyen vagy! <br />
        <br /> Billnek hívnak és azért vagyunk itt, hogy gyorsan és egyszerűen
        pénzre válthasd számláidat!
      </Typography>
    </FlexiDialog>
  );
};

export default GreetingMessageDialog;
