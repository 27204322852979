import Paper from "@material-ui/core/Paper";
import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const SimpleCard = ({ title, description, img }) => {
  return (
    <Paper style={{ padding: "24px 48px" }}>
      <Grid container spacing={2} wrap={"nowrap"}>
        <Grid item xs={"auto"}>
          <img src={img} style={{ width: "100px" }} alt="" />
        </Grid>
        <Grid item xs={"auto"}>
          <Typography variant={"h3"}>{title}</Typography>
          <Typography>{description}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SimpleCard;
