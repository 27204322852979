export const makeCurrencyValueFormatter = intl => ({ value, data }) => {
  const currency = data.currency || "HUF";
  const isHuf = currency === "HUF";

  let strippedNumber;
  if (value !== undefined && value !== null) {
    strippedNumber = Number(value.toString().replace(/(,|\s)/g, ""));
  }

  if (isNaN(strippedNumber)) {
    return "-";
  }

  return intl.formatNumber(strippedNumber, {
    style: "currency",
    currency,
    minimumFractionDigits: isHuf ? 0 : 2,
    maximumFractionDigits: isHuf ? 0 : 2
  });
};
