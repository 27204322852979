import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import questionMark from "./_.svg";
import brainSvg from "./Page-1.svg";
import {useProfileData} from "../../hooks/useApi";

const tips = [
  "Az egyéni és csoportos feltöltés mellett, már a NAV-ból is szinkronizálhatod számláidat a FlexiBill segítségével!"
];

const szamlazzHuTips = [
  "A munkatársak menüben lehetőséged van több felhasználót is meghívni a fiókod kezelésére!",
  "Számláid státuszának alakulását nyomon tudod követni folyósításig, illetve a maradvány utalását is!"
];
const FlexiTipp = ({}) => {
  const szamlazzHu = useProfileData()
  const randomTipp = szamlazzHu ? szamlazzHuTips[Math.floor(Math.random() * szamlazzHuTips.length)] : tips[Math.floor(Math.random() * tips.length)];
  return (
    <Paper style={{ padding: 0 }}>
      <Grid container direction={"row"} justify={"space-between"}>
        <Grid item xs={7} style={{ paddingTop: "32px", paddingLeft: "40px" }}>
          <Grid container>
            <Grid item style={{ paddingRight: "14px" }}>
              <img src={brainSvg} />
            </Grid>
            <Grid item>
              <Typography variant={"body1"}>FlexiTipp</Typography>
              <Typography variant={"h4"}>Tudtad?</Typography>
            </Grid>
          </Grid>
          <Grid item style={{ paddingTop: "26px" }}>
            <Typography variant={"body2"}>{randomTipp}</Typography>
          </Grid>
        </Grid>
        <Grid item style={{ overflow: "hidden", maxHeight: "305px" }}>
          <img src={questionMark} />
        </Grid>
      </Grid>
    </Paper>
  );
};
export default FlexiTipp;
