import { Switch, Route } from "react-router-dom";
import React from "react";
import SubscriptionPage from "../../pages/SubscriptionPage";
import IframeRegister from "../../pages/IFrameRegistration";

const WidgetsApplication = () => {
  return (
    <Switch>
      <Route
        exact
        path={"/widgets/iframe-registration"}
        component={props => (
          <IframeRegister
            {...props}
            successRedirectUrl={"https://flexibill.hu/sikeres-regisztracio/"}
          />
        )}
      />
      <Route
        path={"/widgets/iframe-registration/:source/:successRedirectUrl?"}
        component={IframeRegister}
      />
      <Route path={"/widgets/subscription"} component={SubscriptionPage} />
    </Switch>
  );
};

export default WidgetsApplication;
