import { createHook } from "react-sweet-state";
import popupStore from "../store/popup";
import React from "react";

const usePopupStore = createHook(popupStore);

const usePopupState = (popupName, defaultOpen) => {
  const [popupState, popupActions] = usePopupStore();
  const currentState = popupState.popups[popupName];

  const open = Boolean(currentState && currentState.open);
  const showPopup = () => popupActions.showPopup(popupName);
  const hidePopup = () => popupActions.hidePopup(popupName);
  const togglePopup = () => popupActions.togglePopup(popupName);

  React.useEffect(() => {

    if(defaultOpen){
      showPopup();
    }

    return () => {
      hidePopup();
    };
  }, []);

  return [open, showPopup, hidePopup, togglePopup];
};

export default usePopupState;
