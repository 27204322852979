import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import FlexiDialog from "@bit/flexibill.flexi.flexi-dialog";
import { Button, LinearProgress, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import newImgStep2 from "../../images/guide/new/uj_szerzigeny1.jpg";
import newImgStep3 from "../../images/guide/new/uj_szerzigeny2.jpg";
import newImgStep4 from "../../images/guide/new/uj_szerzigeny3.jpg";
import newImgStep5 from "../../images/guide/new/uj_dokfeltoltes1.jpg";
import newImgStep6 from "../../images/guide/new/uj_dokfeltoltes2.jpg";
import newImgStep7 from "../../images/guide/new/uj_dokfeltoltes3.jpg";
import newImgStep10 from "../../images/guide/new/uj_szerzkot1.jpg";
import newImgStep11 from "../../images/guide/new/uj_szerzkot2.jpg";
import newImgStep12 from "../../images/guide/new/uj_szerzkot3.jpg";
import newImgStep13 from "../../images/guide/new/uj_munkatars1.jpg";
import newImgStep14 from "../../images/guide/new/uj_munkatars2.jpg";
import newImgStep17 from "../../images/guide/new/uj_profil.jpg";

const steps = [
  {
    //1
    title: "Új ügyfeleknek - Szerződés igények",
    subTitle: "Hogyan köthetek új szerződést?",
    desc: (
      <div>
        <ol>
          <li>
            Amennyiben már létrehozta az értékesítő munkatársunk az ajánlatodat,
            a Szerződés igények menüpontban megtekintheted és elfogadhatod. Ha
            még nem rendelkezel ajánlattal, kattints az Új igény gombra és add
            meg a szükséges adatokat. A Kalkulálok gombra kattintva
            megtekintheted és elfogadhatod az előkalkulációt.
          </li>
          <li>Töltsd fel a szükséges dokumentumokat.</li>
          <li>Kattints a jobb felső sarokban található Tovább gombra.</li>
          <li>
            Ezután megkezdjük az adatok feldolgozását és ellenőrizzük a
            dokumentumokat. Amint a benyújtott igény elbírálásra került,
            e-mailben tájékoztatunk.
          </li>
        </ol>
      </div>
    )
  },
  {
    //2
    title: "Új ügyfeleknek - Szerződés igények",
    subTitle: "Hogyan köthetek új szerződést?",
    desc: (
      <div>
        <ol>
          <li>
            A Szerződés igények menüpont Státusz oszlopában látod, hogy melyik
            ajánlatod hol tart a folyamatban.
          </li>
          <li>
            Négy típussal találkozhatsz:
            <ul>
              <li>
                Feladatod van vele - a következő lépést neked kell elvégezned
                ahhoz, hogy folytatódhasson a folyamat.
              </li>
              <li>
                Dokumentum ellenőrzés alatt - a feltöltött dokumentumok meglétét
                és helyességét munkatársunk ellenőrzi.
              </li>
              <li>
                Folyamatban van - a szerződési folyamaton egy munkatársunk
                dolgozik.
              </li>
              <li>
                A folyamat lezárult - a szerződéskötés sikeres, hamarosan
                elérheted a folyósító modul funkcióit is.
              </li>
            </ul>
            <li>
              A részletekhez kattints az adott igényhez tartozó Megtekintés
              gombra.
            </li>
          </li>
        </ol>
        <img style={{ width: "100%" }} src={newImgStep2} />
      </div>
    )
  },
  {
    //3
    title: "Új ügyfeleknek - Szerződés igények",
    subTitle: "Hogyan tekinthetek meg egy meglévő ajánlatot?",
    desc: (
      <div>
        <ol>
          <li value={4}>
            Az elkészült kalkulációt le is töltheted, vagy elküldheted
            e-mailben. Amennyiben megfelelő az ajánlatunk, elfogadhatod a Tovább
            gombra kattintva. Ha szeretnél tájékozódni az ajánlattal
            kapcsolatban, kattints a Visszahívást kérek gombra és munkatársunk
            keresni fog.
          </li>
        </ol>
        <img style={{ width: "100%" }} src={newImgStep3} />
      </div>
    )
  },
  {
    //4
    title: "Új ügyfeleknek - Szerződés igények",
    subTitle: "Hogyan kaphatok ajánlatot?",
    desc: (
      <div>
        <img style={{ width: "100%", marginTop: "1em" }} src={newImgStep4} />
        <ol>
          <li>A Szerződés igények menüpontban kattints az Új igény gombra.</li>
          <li>
            A Vevő neve mezőnél kezdd el gépelni a vállalkozás nevét és válaszd
            ki a céget a{" "}
            <a href={"https://www.dnb.com/hu-hu/"}>Dun & Bradstreet</a>-ből
            betöltött találatok közül. Töltsd ki a többi adatot, majd kattints a
            Kalkulálok! gombra. A jobb oldalon látni fogod a FlexiBill által
            generált kalkulációt
            <ol type={"a"}>
              <li>
                Amennyiben kérdésed merül fel az ajánlattal kapcsolatban,
                kattints a Visszahívást kérek gombra és munkatársunk felveszi
                veled a kapcsolatot
              </li>
              <li>
                Amennyiben megfelelő számodra az ajánlat, kattints a Tovább
                gombra.
              </li>
            </ol>
          </li>
          <li>
            Ezután töltsd fel a faktorszerződéshez szükséges Dokumentumokat.
          </li>
        </ol>
      </div>
    )
  },
  {
    //5
    title: "Új ügyfeleknek - Dokumentum feltöltése",
    subTitle: "Hogyan vonhatom be munkatársamat a Dokumentum feltöltésbe?",
    desc: (
      <div>
        <img style={{ width: "100%", marginTop: "1em" }} src={newImgStep5} />
        <ol>
          <li>
            A Dokumentumok feltöltése résznél kattints a Hozzáférés küldése
            gombra.
          </li>
          <li>Add meg a hozzáadni kívánt munkatársad e-mail címét.</li>
          <li>Kattints a Küldés gombra.</li>
          <li>
            FONTOS: Munkatársad “Megtekintő és dokumentum feltöltő”
            jogosultságot kap. Hozzáférését a Munkatársak menüpontban bármikor
            módosíthatod!
          </li>
        </ol>
      </div>
    )
  },
  {
    //6
    title: "Új ügyfeleknek - Dokumentum feltöltése",
    subTitle: "Hogyan tölthetem fel a dokumentumokat?",
    desc: (
      <div>
        <ol>
          <li>
            A Dokumentum feltöltése lépésnél, a kiválasztott dokumentum
            dobozában kattints a Feltöltés gombra.
          </li>
        </ol>
        <img style={{ width: "100%" }} src={newImgStep6} />
        <ol>
          <li value={2}>Kattints a Fájl hozzáadása gombra.</li>
        </ol>
      </div>
    )
  },
  {
    //7
    title: "Új ügyfeleknek - Dokumentum feltöltése",
    subTitle: "Hogyan tölthetem fel a dokumentumokat?",
    desc: (
      <div>
        <ol>
          <li value={3}>
            Válaszd ki a megfelelő fájlt a gépedről (egyszerre több fájlt is
            feltölthetsz).
          </li>
        </ol>
        <img style={{ width: "100%" }} src={newImgStep7} />
        <ol>
          <li value={4}>Kattints a Mentés gombra.</li>
        </ol>
        Ha minden dokumentumot feltöltöttél, kattints a Tovább gombra.
      </div>
    )
  },
  {
    //8
    title: "Új ügyfeleknek - Dokumentum feltöltése",
    subTitle: "Milyen dokumentumokat kell feltöltenem?",
    desc: (
      <div>
        <Typography variant={"h6"} style={{ marginTop: "1em" }}>
          Online adatlap
        </Typography>
        Az online adatlap egészen rövid, első részében a Te adataidat kérjük
        (pl. hova kéred az utalást?). A további lapon vagy lapokon (több vevő
        esetén) már a vevőd adatait kérjük, akinek számlázol, erre kérlek
        figyelj!inden dokumentumot feltöltöttél, kattints a Tovább gombra.
        <Typography variant={"h6"} style={{ marginTop: "24px" }}>
          Vállalkozási szerződés
        </Typography>
        Kérjük csatold a faktorálni kívánt vevőkkel kötött aktuális szerződés
        vagy megrendelés másolatát. Ha több szerződés van, lehetőség szerint
        mindet kérjük.
        <Typography variant={"h6"} style={{ marginTop: "24px" }}>
          NAV adófolyószámla
        </Typography>
        Te, mint ügyfél (= szállító) cég, 30 napnál nem régebbi dátumra lekért
        adófolyószámla kivonatának másolatot kérjük.
        <Typography variant={"h6"} style={{ marginTop: "24px" }}>
          Főkönyv
        </Typography>
        Az igény beadásától számított 3 hónapnál nem régebbi dátumra szóló
        főkönyvi kivonat másolatát kérjük. Január, február és március hónapban
        beadott igény esetén, kérjük csatold az előző év december 31-re szóló
        főkönyvi kivonat másolatot is.
      </div>
    )
  },
  {
    //9
    title: "Új ügyfeleknek - Dokumentum feltöltése",
    subTitle: "Milyen dokumentumokat kell feltöltenem?",
    desc: (
      <div>
        <Typography variant={"h6"} style={{ marginTop: "1em" }}>
          Vevő analitika
        </Typography>
        Faktorálni kívánt vevőkre vonatkozóan, az igénylés időpontjától
        visszamenőlegesen 1 éves időszakra kérjük, ahol látszik a már kiállított
        számlák száma, összege, eredeti és tényleges fizetési határideje. Új
        vevőkapcsolat esetén annak az időszaknak az analitikáját/számláit
        kérjük, ami rendelkezésre áll.
      </div>
    )
  },
  {
    //10
    title: "Új ügyfeleknek - Szerződéskötés",
    subTitle: "Hogyan írhatom alá a szerződést?",
    desc: (
      <div>
        <img
          style={{ width: "100%", marginTop: "1em", marginBottom: "1em" }}
          src={newImgStep10}
        />
        Az elkészült szerződésed a FlexiBill felületén bármikor aláírhatod
        digitálisan vagy manuálisan is.
      </div>
    )
  },
  {
    //11
    title: "Új ügyfeleknek - Szerződéskötés",
    subTitle: "Aláírás digitálisan",
    desc: (
      <div style={{ marginTop: "1em" }}>
        A dokumentumot hitelesítheted Digitális aláírás funkciónk segítségével.
        Így nem kell a kinyomtatott és aláírt formátumot feltöltened, mégis
        ugyanúgy hiteles, mintha kézzel írtad volna alá! A felületen
        feltöltheted a Netlock-kal vagy Microsec-kel aláírt szerződést. További
        példányt nem kell eljuttatnod hozzánk.
        <img style={{ width: "100%", marginTop: "1em" }} src={newImgStep11} />
      </div>
    )
  },
  {
    //12
    title: "Új ügyfeleknek - Szerződéskötés",
    subTitle: "Aláírt fájl feltöltése",
    desc: (
      <div style={{ marginTop: "1em" }}>
        A FlexiBill felületén feltöltheted a letöltött, beszkennelt és általad
        aláírt szerződést. A feltöltés mellett kérjük, juttass el hozzánk két
        példányt személyesen vagy postán (1066 Budapest, Mozsár utca 16.).
        <img style={{ width: "100%", marginTop: "1em" }} src={newImgStep12} />
      </div>
    )
  },
  {
    //13
    title: "Új ügyfeleknek - Munkatársak",
    subTitle: "Új munkatárs meghívása",
    desc: (
      <div>
        <ol>
          <li>Kattints a Munkatársak menüpontra.</li>
          <li>Jobb oldalon kattints a Meghívás gombra.</li>
        </ol>
        <img style={{ width: "100%" }} src={newImgStep13} />
        <ol>
          <li value={3}>
            Add meg a választott munkatárs e-mail címét, amivel beléphet a
            fiókodba.
          </li>
        </ol>
      </div>
    )
  },
  {
    //14
    title: "Új ügyfeleknek - Munkatársak",
    subTitle: "Új munkatárs meghívása",
    desc: (
      <div>
        <ol>
          <li value={4}>
            Jelöld ki, hogy milyen jogosultsághoz szeretnél hozzáférést adni.
          </li>
        </ol>
        <img style={{ width: "100%" }} src={newImgStep14} />
        <ol>
          <li value={5}>
            A Küldés gombra kattintva e-mailben értesítjük a munkatársadat és
            tájékoztatjuk a tennivalókról.
          </li>
        </ol>
      </div>
    )
  },
  {
    //15
    title: "Új ügyfeleknek - Munkatársak",
    subTitle: "Munkatársak jogosultságai",
    desc: (
      <div>
        <Typography variant={"h6"} style={{ marginTop: "1em" }}>
          Dokumentum feltöltő
        </Typography>
        Megtekintheti az adatokat és feltöltheti a szerződéskötéshez szükséges
        dokumentumokat.
        <Typography variant={"h6"} style={{ marginTop: "24px" }}>
          Szamlakezelő
        </Typography>
        Megtekintheti, rögzítheti és faktorálásra előkészítheti a számlákat.
        <Typography variant={"h6"} style={{ marginTop: "24px" }}>
          Engedményezés végrehajtó
        </Typography>
        Benyújthatja a számlákat faktorálásra és aláírhatja a dokumentumokat.
      </div>
    )
  },
  {
    //16
    title: "Új ügyfeleknek - Munkatársak",
    subTitle: "Munkatárs deaktiválása",
    desc: (
      <div>
        Ha átmenetileg nem akarod, hogy hozzáférjen a munkatársad a fiókodhoz,
        akkor deaktiválhatod. Amennyiben szeretnéd, később visszaállíthatod a
        munkatársad fiókját.
        <br />
        <br />
        <b>
          <i>Lépések: </i>
        </b>
        <ol style={{ marginTop: 0 }}>
          <li>Kattints a Munkatársak menüpontra.</li>
          <li>
            Válaszd ki azt a munkatársat, akinek deaktiválni kívánod a fiókját.
          </li>
          <li>
            A Státusz oszlopban lévő csúszkára kattintva deaktiválhatod a
            munkatársad.
          </li>
          <li>
            E-mailben értesítjük a munkatársadat és tájékoztatjuk a
            tennivalókról.
          </li>
        </ol>
      </div>
    )
  },
  {
    //17
    title: "Új ügyfeleknek - Profil",
    subTitle: "Mit változtathatok meg a profilomban?",
    desc: (
      <div style={{ marginTop: "1em" }}>
        A Profil menüpontban lehetőséged van megváltoztatni a felhasználónevedet
        (vezetéknév, keresztnév), illetve a jelszavadat. A Feliratkozom a
        hírlevélre jelölőnégyzet bepipálásával értesülhetsz aktuális híreinkről.
        <img style={{ width: "100%", marginTop: "1em" }} src={newImgStep17} />
      </div>
    )
  }
];

const NewUserGuideDialog = ({ open, onClose }) => {
  const [stepIndex, setStepIndex] = useState(0);
  const step = steps[stepIndex];
  return (
    <FlexiDialog
      open={open}
      onClose={onClose}
      size={"medium"}
      title={step.title}
      dialogActions={
        <Grid
          container
          direction={"row"}
          style={{ alignItems: "center", justify: "space-between" }}
        >
          <Grid item>
            <Button
              variant={"text"}
              disabled={stepIndex === 0}
              onClick={() => setStepIndex(stepIndex - 1)}
            >
              <ChevronLeft />
              Vissza
            </Button>
          </Grid>
          <Grid item style={{ flexGrow: 1, padding: "0 15px" }}>
            <LinearProgress
              style={{
                width: "100%"
              }}
              variant="determinate"
              value={((stepIndex + 1) / steps.length) * 100}
            />
          </Grid>
          <Grid item>
            <Button
              variant={"text"}
              disabled={stepIndex + 1 === steps.length}
              onClick={() => setStepIndex(stepIndex + 1)}
            >
              Következő
              <ChevronRight />
            </Button>
          </Grid>
        </Grid>
      }
    >
      <Grid container direction={"column"}>
        <Grid item xs={12}>
          <Typography variant={"h6"}>{step.subTitle}</Typography>
        </Grid>
        <Grid item xs={12} style={{ height: "100px", minHeight: "60px" }}>
          <Typography>{step.desc}</Typography>
        </Grid>
      </Grid>
    </FlexiDialog>
  );
};
export default NewUserGuideDialog;

NewUserGuideDialog.propTypes = {
  open: PropTypes.any.isRequired,
  onClose: PropTypes.any.isRequired
};
