import React, { useState } from "react";
import ForgottenPasswordForm from "./ForgottenPasswordForm";
import Grid from "@material-ui/core/Grid";
import { useMutator } from "../../hooks/useApi";
import { selectErrorNotificationMessage } from "../../utils/selectErrorMessage";
import { useErrorOccurredNotification } from "../../hooks/useNotificationState";
import Footer from "../../components/Footer";
import { isWidthDown } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { getFlexipayUrl } from "../../utils/urlHelpers";
import DesktopWelcomeHeader from "../../components/WelcomeHeader/desktop";
import FlexiLinearProgress from "../../components/FlexiLinearProgress";
import MobileWelcomeHeader from "../../components/WelcomeHeader/mobile";
import withWidth from "@material-ui/core/withWidth";
import lockImage from "../../images/redesignImages/Ikon.svg";

const ForgottenPasswordPage = props => {
  const [post, postLoading] = useMutator("POST");
  const [email, setEmail] = useState(null);
  const [success, setSuccess] = useState(false);
  const notifyError = useErrorOccurredNotification();

  return (
    <Grid container>
      {isWidthDown("sm", props.width) ? (
        <Grid container>
          <Grid item xs={12} style={{ paddingBottom: "55px" }}>
            <MobileWelcomeHeader />
          </Grid>
          {!success ? (
            <Grid item xs={12}>
              {postLoading && <FlexiLinearProgress />}
              <Grid
                container
                align={"center"}
                style={{ padding: "0 1rem 1rem" }}
              >
                <Grid item xs={12}>
                  <ForgottenPasswordForm
                    onSubmit={async values => {
                      const { data, error } = await post(
                        getFlexipayUrl("/password/reset/request"),
                        values
                      );

                      if (error) {
                        notifyError(selectErrorNotificationMessage(error));
                      }

                      if (data) {
                        setEmail(values.email);
                        setSuccess(true);
                      }
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ position: "fixed", bottom: "27px" }}
                >
                  <Footer />
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              direction={"column"}
              style={{
                maxWidth: "520px",
                margin: "0 auto",
                padding: "0 1rem 1rem"
              }}
            >
              <Grid item xs={12}>
                <Typography
                  variant={"h5"}
                  align={"center"}
                  style={{ marginBottom: 30 }}
                >
                  Jelszó visszaállítása
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" align="center">
                  A jelszó helyreállításához szükséges linket elküldtük az
                  alábbi e-mail címre: {email}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  align="center"
                  variant="body2"
                  style={{ marginTop: "20px" }}
                >
                  <Link
                    style={{
                      color: "#3f51b5",
                      textDecoration: "none",
                      fontWeight: "bold"
                    }}
                    href="/login"
                  >
                    Vissza a bejelentkezéshez
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ position: "fixed", bottom: "27px" }}>
                <Footer />
              </Grid>
            </Grid>
          )}
        </Grid>
      ) : (
        <Grid container direction={"row"}>
          <Grid item xs={6}>
                <DesktopWelcomeHeader />
          </Grid>
          <Grid item xs>
            <Grid container alignItems={"center"} justify={"center"}>
              <Grid item>
                {!success ? (
                  <Grid item xs>
                    {postLoading && <FlexiLinearProgress />}
                    <Grid
                      container
                      justify={"center"}
                      style={{ padding: "0 1rem 1rem", width: "410px", margin: "auto",
                        textAlign: "center" }}
                    >
                      <Grid item xs={12} style={{ paddingTop: "160px" }}>
                        <img alt={"lockImage"} src={lockImage} />                        <ForgottenPasswordForm
                          onSubmit={async values => {
                            const { data, error } = await post(
                              getFlexipayUrl("/password/reset/request"),
                              values
                            );

                            if (error) {
                              notifyError(
                                selectErrorNotificationMessage(error)
                              );
                            }

                            if (data) {
                              setEmail(values.email);
                              setSuccess(true);
                            }
                          }}
                        />
                      </Grid>

                    <Grid
                      item
                      xs={12}
                      style={{ position: "fixed", bottom: 18 }}
                    >
                      <Footer />
                    </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid
                    container
                    direction={"column"}
                    style={{ padding: "0 1rem 1rem", width: "410px" }}
                  >
                    <Grid
                      item
                      xs={12}
                      style={{ paddingTop: "310px", paddingBottom: 32 }}
                    >
                      <Typography variant={"h5"} align={"center"}>
                        Jelszó visszaállítása
                      </Typography>
                    </Grid>
                    <Grid item style={{paddingBottom: 32}}>
                      <Typography variant="body2" align="center">
                        A jelszó helyreállításához szükséges linket elküldtük az
                        alábbi e-mail címre: {email}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        align="center"
                        variant="body2"
                      >
                        <Link
                          style={{
                            color: "#2227AC",
                            textDecoration: "none",
                            fontWeight: "bold"
                          }}
                          href="/login"
                        >
                          Vissza a bejelentkezéshez
                        </Link>
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                style={{ position: "fixed", bottom: 18 }}
              >
                <Footer />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default withWidth()(ForgottenPasswordPage);
