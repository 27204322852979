import { defineMessages } from "react-intl.macro";
import React, { useEffect, useMemo, useState } from "react";
import { useInvoiceGroup } from "../../hooks/useApi";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import PageLayout from "../../components/PageLayout";
import InvoiceGroupList from "./InvoiceGroupList";
import closedSvg from "../../images/redesignImages/Rejected.svg"
import FlexiDialog from "@bit/flexibill.flexi.flexi-dialog";
import DocumentsCarousel from "../../components/DocViewer";
import { getUrlWithCompanyId } from "../../utils/urlHelpers";
import { useIntl } from "react-intl";
import Button from "@material-ui/core/Button";
import NotificationsIcon from "@material-ui/icons/Notifications";
import FlexiLink from "@bit/flexibill.flexi.flexi-link";
import { isWidthDown, withWidth } from "@material-ui/core";
import LoadingDialog from "@bit/flexibill.flexi.flexi-loader";
import FlexiInfoBar from "@bit/flexibill.flexi.flexi-info-bar";
import {
  InvoiceStatusIcon,
  statusMessages
} from "../InvoicePage/InvoiceStatusIcons";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Box from "@material-ui/core/Box";
import {useCallback} from "react";
import waitingForCustomerConfirmation from "../../images/redesignImages/WaitingForCustomerConfirmation.svg";

const messages = defineMessages({
  pageTitle: {
    id: "pageTitle.invoiceGroupPage",
    defaultMessage: "Számlák megtekintése"
  },
  toFactoringContractsButtonText: {
    id: "toFactoringContractsButtonText",
    defaultMessage: "Faktorálási értesítő"
  }
});

const useStyles = makeStyles({
  green: {
    background: "#8BC34A !important",
    "&:hover": { background: "#6a923c !important" }
  }
});

const Header = withWidth()(
  ({
    customerName,
    faktoriId,
    id,
    width,
    isFinanceNotificationRequired,
    status,
  }) => {
    const { data } = useInvoiceGroup(id);
    const intl = useIntl();
    return (
      <Grid container direction="column">
        <Grid item xs={12}>
          {isWidthDown("md", width) ? (
            <Paper style={{ padding: "12px 0px" }}>
              <Box ml={2} mr={2}>
                <Grid
                  container
                  direction={"column"}
                  style={{ alignItems: "flex-start" }}
                  spacing={2}
                  justify={"space-between"}
                >
                  {faktoriId && (
                    <Grid
                      item
                      style={{
                        display: "inline-flex",
                        alignItems: "center"
                      }}
                    >
                      <Typography
                        variant={"body1"}
                        style={{
                          fontSize: "12px",
                          color: "#9B9B9B",
                          lineHeight: "15px",
                          paddingRight: "10px"
                        }}
                      >
                        Számlacsoport:&nbsp;
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "14px",
                          color: "#4a4a4a",
                          lineHeight: "15px"
                        }}
                        variant={"h3"}
                      >
                        {faktoriId}
                      </Typography>
                    </Grid>
                  )}
                  {customerName && (
                    <Grid
                      item
                      style={{
                        display: "inline-flex",
                        alignItems: "center"
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: "12px",
                          color: "#9B9B9B",
                          lineHeight: "15px",
                          paddingRight: "10px"
                        }}
                        variant={"body1"}
                      >
                        Vevő:{" "}
                      </Typography>
                      <Typography
                        variant={"h3"}
                        style={{
                          fontSize: "14px",
                          color: "#4a4a4a",
                          lineHeight: "15px"
                        }}
                      >
                        {customerName}
                      </Typography>
                    </Grid>
                  )}
                  {status && (
                    <Grid item>
                      <Grid container alignItems={"center"}>
                        <Typography
                          variant={"body1"}
                          style={{
                            fontSize: "12px",
                            color: "#9B9B9B",
                            lineHeight: "15px",
                            paddingRight: "10px"
                          }}
                        >
                          Státusz:&nbsp;
                        </Typography>
                        <InvoiceStatusIcon
                          style={{ paddingRight: "10px" }}
                          status={status}
                          chip
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Paper>
          ) : (
            <Paper style={{ maxHeight: "42px" }}>
              <Box ml={2} mr={2}>
                <Grid
                  container
                  spacing={2}
                  alignItems={"center"}
                  justify={"space-between"}
                  style={{ maxHeight: "42px", alignItems: "center" }}
                >
                  {faktoriId && (
                    <Grid
                      item
                      style={{
                        display: "inline-flex",
                        alignItems: "center"
                      }}
                    >
                      <Typography
                        variant={"body1"}
                        style={{
                          fontSize: "12px",
                          color: "#9B9B9B",
                          lineHeight: "15px",
                          paddingRight: "10px"
                        }}
                      >
                        Számlacsoport:&nbsp;
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "14px",
                          color: "#4a4a4a",
                          lineHeight: "15px"
                        }}
                        variant={"h3"}
                      >
                        {faktoriId}
                      </Typography>
                    </Grid>
                  )}
                  {customerName && (
                    <Grid
                      item
                      style={{
                        display: "inline-flex",
                        alignItems: "center"
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: "12px",
                          color: "#9B9B9B",
                          lineHeight: "15px",
                          paddingRight: "10px"
                        }}
                        variant={"body1"}
                      >
                        Vevő:{" "}
                      </Typography>
                      <Typography
                        variant={"h3"}
                        style={{
                          fontSize: "14px",
                          color: "#4a4a4a",
                          lineHeight: "15px"
                        }}
                      >
                        {customerName}
                      </Typography>
                    </Grid>
                  )}
                  {status && !(data?.closed) && (
                    <Grid item>
                      <Grid container alignItems={"center"}>
                        <Typography
                          variant={"body1"}
                          style={{
                            fontSize: "12px",
                            color: "#9B9B9B",
                            lineHeight: "15px",
                            paddingRight: "10px"
                          }}
                        >
                          Státusz:&nbsp;
                        </Typography>
                        <InvoiceStatusIcon withText status={status} chip />
                      </Grid>
                    </Grid>
                  )}
                  {data?.closed && (
                    <Grid item>
                      <Grid container alignItems={"center"}>
                        <Typography
                          variant={"body1"}
                          style={{
                            fontSize: "12px",
                            color: "#9B9B9B",
                            lineHeight: "15px",
                            paddingRight: "10px"
                          }}
                        >
                          Státusz:&nbsp;
                        </Typography>
                        <img src={closedSvg} />
                          <Typography style={{paddingLeft: "10px"}} variant={"h6"}>
                           Lezárt
                          </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Paper>
          )}
        </Grid>
        {isFinanceNotificationRequired && (
          <Grid item xs style={{ marginTop: "30px" }}>
            <FlexiLink
              to={`/app/invoice-group/${id}/factor-notification`}
              style={{ textDecoration: "none" }}
            >
              <Button fullWidth={isWidthDown("sm", width)}>
                <NotificationsIcon style={{ paddingRight: "12px" }} />
                {intl.formatMessage(messages.toFactoringContractsButtonText)}
              </Button>
            </FlexiLink>
          </Grid>
        )}
      </Grid>
    );
  }
);
function getAllDocs(data) {
  const docs = [];
  data.invoices.forEach(invoice =>
    invoice.documents.forEach(docItem =>
      docs.push({ ...docItem, invoiceId: invoice.invoiceId })
    )
  );
  return docs;
}

function findItem(data, id) {
  return data.findIndex(item => item.id === id);
}

const InvoiceGroupPage = ({ match, backButton = true, history }) => {
  const id = match?.params?.id;
  const { data, error } = useInvoiceGroup(id);
  const [invoiceLine, setInvoiceLine] = useState(null);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [allDocs, setAllDocs] = useState([]);
  const [openInvoiceGroupDocs, setOpenInvoiceGroupDocs] = useState(null);
  const [allGroupDocs, setAllGroupDocs] = useState([]);
  const [loadingDialog, setLoadingDialog] = useState(true);
  const goBack = useCallback(() => history.push("/app/invoice"), []);

  useEffect(() => {
    if (data) {
      setAllGroupDocs(data.documents);
      setAllDocs(getAllDocs(data));
      setLoadingDialog(false);
    }
  }, [data]);

  const hasRejectedInvoice = useMemo(
    () =>
      data?.invoices?.reduce(
        (hasRejected, item) =>
          hasRejected ||
          (item.customerConfirmationStatus === "REJECTED" &&
            item.customerDeclineReason === "INVALID_INVOICE_DATA"),
        false
      ),
    [data]
  );

  return (
    <PageLayout pay insidePage titleMessage={messages.pageTitle} backButton={backButton} onBackButtonClick={goBack}>
      <LoadingDialog loading={loadingDialog} />
      <Grid container spacing={4} style={{ paddingTop: "6px" }}>
        <Grid item xs={12}>
          <Header
            id={id}
            disabledAttachment={!allGroupDocs.length}
            onAttachmentClick={item => setOpenInvoiceGroupDocs(item)}
            customerName={data?.agreement?.customerName}
            faktoriId={data?.faktoriId}
            isFinanceNotificationRequired={data?.isFinanceNotificationRequired}
            status={data?.status}
          />
        </Grid>
        <Grid item xs={12}>
          {hasRejectedInvoice && (
            <FlexiInfoBar warning>
              A számlacsoportban vevő által elutasított számla van! A
              Szerkesztés gombra kattintva lehetőséged van javítani a hibásan
              rögzített számla adatokat!
            </FlexiInfoBar>
          )}
        </Grid>
        {/*<Grid item xs={12}>*/}
        {/*  <FlexiInfoBar info>*/}
        {/*    Ha a visszaigazolás folyamatban van, szerkesztheted a számlákat. A*/}
        {/*    szerkesztett számlák visszakerülnek a Fakroálható számla listába.*/}
        {/*    Onnan indíthatod el újra a faktorálás folyamatát.*/}
        {/*  </FlexiInfoBar>*/}
        {/*</Grid>*/}
        <Grid item xs={12}>
          <InvoiceGroupList
            invoiceGroup={data}
            onAttachmentClick={item => {
              const docs = getAllDocs(data);
              if (docs) {
                const i = findItem(docs, item.documents[0].id);
                setCarouselIndex(i);
              }
              setInvoiceLine(item);
            }}
          />
        </Grid>
      </Grid>
      <FlexiDialog
        open={Boolean(invoiceLine)}
        onClose={() => setInvoiceLine(null)}
        size={"large"}
      >
        <DocumentsCarousel
          index={carouselIndex}
          showTitle
          documents={allDocs}
          getUrl={docId =>
            allDocs && getUrlWithCompanyId(`/invoice/document/${docId}`)
          }
        />
      </FlexiDialog>
      <FlexiDialog
        open={Boolean(openInvoiceGroupDocs)}
        onClose={() => setOpenInvoiceGroupDocs(null)}
        size={"large"}
      >
        <DocumentsCarousel
          index={0}
          showTitle
          documents={allGroupDocs}
          getUrl={docId =>
            allGroupDocs && getUrlWithCompanyId(`/invoice/document/${docId}`)
          }
        />
      </FlexiDialog>
    </PageLayout>
  );
};

export default withWidth()(InvoiceGroupPage);
