import React from "react";
import BillSection from "../BillSection";
import { Button, Typography } from "@material-ui/core";
import billHappy from "../../images/bill/new/bill-happy.svg";
import { useHistory } from "react-router-dom";
import { useProfileData } from "../../hooks/useApi";

const DocumentsPreparationSuccessPaper = () => {
  const { push: redirect } = useHistory();
  const { szamlazzHu } = useProfileData();

  return (
    <BillSection img={billHappy} title={"Egyelőre készen vagy!"}>
      <Typography>
        {szamlazzHu
          ? "A kérvényed feldolgozása folyamatban van. A szerződésedet a felületen tekintheted majd meg. Amint elkészül, értesíteni fogunk e-mailben!"
          : "A kérvényed feldolgozása folyamatban van, az elbírálás eredményéről e-mailben tájékoztatunk! Addig is, nyújts be egy újabb igényt!"}
      </Typography>
      <Button
        onClick={() => {
          redirect(`/app`);
          setTimeout(() => {
            redirect(`/app/agreement-claims/new-contract/new`);
          });
        }}
      >
        Új igény
      </Button>
    </BillSection>
  );
};

export default DocumentsPreparationSuccessPaper;
