import React from "react";
import { Button, Divider, FormHelperText, Typography } from "@material-ui/core";
import { Fields } from "@bit/flexibill.flexi.flexi-form";
import Grid from "@material-ui/core/Grid";
import FlexiForm from "@bit/flexibill.flexi.flexi-form";

const StepOne = ({ onClick }) => {
  const calculationFields = [
    [
      {
        fieldType: "text",
        name: `monthlyInvoicesTotal`,
        label: "Számlák bruttó összege",
        type: "number",
        tooltipText:
          "Adj meg egy közelítő értéket arra, hogy összesen milyen értékű számlát szeretnél faktorálni. Alapul veheted a korábban kiállított számláidat, de akár egy tetszőleges, a jövőre vonatkozó összeget is megadhatsz.",
        required: true
      },
      {
        fieldType: "text",
        name: `invoiceTerms`,
        label: "Fizetési határidő (nap)",
        type: "number",
        tooltipText:
          "Ha többféle fizetési határidővel is számlázol a partnernek, a leggyakoribbat add meg!",
        required: true
      }
    ]
  ];
  const calculationRadioFields = [
    [
      {
        fieldType: "radio",
        name: `currency`,
        label: "HUF",
        value: "HUF",
        gridSize: 4
      },
      {
        fieldType: "radio",
        name: `currency`,
        label: "EUR",
        value: "EUR",
        gridSize: 4
      },
      {
        fieldType: "radio",
        name: `currency`,
        label: "USD",
        value: "USD",
        gridSize: 4
      }
    ]
  ];
  return (
    <FlexiForm
      FinalFormFormProps={{
        onSubmit: onClick,
        initialValues: { currency: "HUF" }
      }}
      id={"subscriptionCalculation"}
      render={() => {
        return (
          <div>
            <Typography style={{ paddingBottom: "23px" }} variant={"h3"}>
              Készíts kalkulációt vevődre!
            </Typography>
            <Typography style={{ paddingBottom: "47px" }}>
              Add meg, hogy jellemzően mennyi le nem járt kintlévőséged van
              partnered felé, valamint az érintett számlák lejárati idejét, hogy
              elkészíthessük a kalkulációt! Ha több vevődre is szeretnél
              faktoring szerződést kötni, külön kalkulációkat érdemes
              készítened.
            </Typography>
            <Divider />
            <Grid style={{ paddingTop: "47px" }} container>
              <Grid item xs={12} md={10}>
                <Fields config={calculationFields} />
                <FormHelperText style={{ paddingTop: "37px" }}>
                  Kiállított számlák pénzneme*:{" "}
                </FormHelperText>
                <Fields
                  config={calculationRadioFields}
                  ContainerGridProps={{ style: { flexWrap: "nowrap" } }}
                />
                <Button type={"submit"} style={{ marginTop: "27px" }}>
                  Kalkulálok
                </Button>
              </Grid>
            </Grid>
          </div>
        );
      }}
    />
  );
};

export default StepOne;
