import PropTypes from "prop-types";
import React from "react";
import { Grid, Paper } from "@material-ui/core";
import FlexiDocumentViewer from "@bit/flexibill.flexi.flexi-document-preview";


const FactorNotificationViewer = props => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container justify={"center"}>
          <Grid item>
            <Paper style={{ overflowY: "scroll",
              overflowX: "hidden",
              position: "relative"}}>
              {(!props.signedNotificationDocument?.image ||
                !props.signedNotificationDocument?.mimeType) &&
                props.originalNotificationBase64Image && (
                  <FlexiDocumentViewer
                    base64={{
                      mimeType: "application/pdf",
                      image: props.originalNotificationBase64Image
                    }}
                  />
                )}
              {props.signedNotificationDocument?.image &&
                props.signedNotificationDocument?.mimeType && (
                  <FlexiDocumentViewer
                    base64={{
                      mimeType: props.signedNotificationDocument.mimeType,
                      image: props.signedNotificationDocument.image
                    }}
                  />
                )}
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FactorNotificationViewer;

FactorNotificationViewer.propTypes = {
  originalNotificationBase64Image: PropTypes.string.isRequired
};
