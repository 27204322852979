export const appendFilesWithTypesToFormData = (
  files,
  formData = new FormData(),
  fileKey = "document",
  typeKey = "document-type"
) => {
  if (files.length > 1) {
    files.forEach((file, fileIndex) => {
      formData.append(`documents[${fileIndex}]`, file.file);
      formData.append(`document-types[${fileIndex}]`, file.type);
    });
  } else {
    files.forEach(file => {
      formData.append(fileKey, file.file);
      formData.append(typeKey, file.type);
    });
  }

  return formData;
};
