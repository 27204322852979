import React, { useRef, useState } from "react";
import PageLayout from "../../components/PageLayout";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { defineMessages } from "react-intl.macro";
import CoworkersList from "./CoworkersList";
import { useCoworkerData, useProfileData } from "../../hooks/useApi";
import CoworkerInviteDialog from "./CoworkerInviteDialog";
import usePopupState from "../../hooks/usePopupState";
import {
  useErrorOccurredNotification,
  useSaveSuccessNotification
} from "../../hooks/useNotificationState";
import { selectErrorNotificationMessage } from "../../utils/selectErrorMessage";
import useRoleFilter from "../../hooks/useRoleFilter";
import {
  ROLE_FLEXIPAY_ASSIGNMENT_EXECUTIVE,
  ROLE_FLEXIPAY_CUSTOMER
} from "../../roles";
import selectFieldErrorsFromResponse from "../../utils/selectFieldErrorsFromResponse";
import FlexiInfoBar from "@bit/flexibill.flexi.flexi-info-bar";

const messages = defineMessages({
  pageTitle: { id: "pageTitle.coworkers", defaultMessage: "Munkatársak" }
});

const CoworkersPage = ({ isPolite }) => {
  const {
    sendInvite,
    sendInviteAgain,
    deleteCoworker,
    editCoworker,
    loading
  } = useCoworkerData();

  const { mainCompany } = useProfileData();
  const isCustomer = mainCompany.roles.includes(ROLE_FLEXIPAY_CUSTOMER);

  const [selectedCoworker, setSelectedCoworker] = useState(null);

  const [
    coworkerDialogShow,
    showCoworkerDialog,
    hideCoworkerDialog
  ] = usePopupState("CoworkerInviteDialog");

  const notifySuccessSave = useSaveSuccessNotification();
  const notifyError = useErrorOccurredNotification();
  const listRef = useRef();

  const handleResponse = (success, error, successMessage) => {
    if (success) {
      notifySuccessSave({ text: successMessage });
      refreshList();
    } else {
      notifyError(selectErrorNotificationMessage(error));
    }
  };

  const makeActiveChangeHandler = (active, successMessage) => async item => {
    const { data: successResponse, error } = await editCoworker({
      ...item,
      active
    });
    handleResponse(successResponse, error, successMessage);
  };

  const makeAsyncEventHandler = (fn, successMessage) => async item => {
    const { data: successResponse, error } = await fn(item);
    handleResponse(successResponse, error, successMessage);
  };

  const rightSideButtons = useRoleFilter([
    {
      roles: [ROLE_FLEXIPAY_ASSIGNMENT_EXECUTIVE],
      props: {
        onClick: () => {
          showCoworkerDialog();
        },
        children: "Meghívás"
      },
      mobile: {
        placement: "fab",
        tooltip: "Munkatárs",
        icon: <PersonAddIcon />
      }
    }
  ]);

  const refreshList = () => listRef.current?.reload();

  return (
    <PageLayout
      insidePage
      pay
      titleMessage={messages.pageTitle}
      rightSideComponents={isCustomer ? undefined : rightSideButtons}
      loading={loading}
    >
      <FlexiInfoBar info>
        Azonos szintű jogosultsággal rendelkező munkatárs nem módosítható.
        Kérjük, vedd fel a kapcsolatot ügyfélszolgálatunkkal.
      </FlexiInfoBar>
      <CoworkersList
        readOnly={isCustomer}
        listRef={listRef}
        onEditClick={item => {
          setSelectedCoworker(item);
          showCoworkerDialog();
        }}
        onDeleteClick={makeAsyncEventHandler(
          deleteCoworker,
          "Munkatárs törölve!"
        )}
        onResendEmailClick={makeAsyncEventHandler(
          sendInviteAgain,
          "E-mail újraküldve!"
        )}
        onActivateClick={makeActiveChangeHandler(true, "Munkatárs aktiválva!")}
        onDeactivationClick={makeActiveChangeHandler(
          false,
          "Munkatárs deaktiválva!"
        )}
      />

      <CoworkerInviteDialog
        selectedCoworker={selectedCoworker}
        open={coworkerDialogShow}
        onClose={() => {
          hideCoworkerDialog();
          setTimeout(() => {
            setSelectedCoworker(null);
          }, 150);
        }}
        onSubmit={async values => {
          const fn = Boolean(selectedCoworker)
            ? editCoworker({ ...selectedCoworker, ...values })
            : sendInvite(values);
          const { data: successResponse, error } = await fn;

          if (successResponse) {
            if (Boolean(selectedCoworker)) {
              notifySuccessSave({ text: "Sikeres szerkesztés!" });
            } else {
              notifySuccessSave({ text: "Meghívó elküldve!" });
            }
            hideCoworkerDialog();
            refreshList();
          } else {
            notifyError(selectErrorNotificationMessage(error));
            hideCoworkerDialog();
            return selectFieldErrorsFromResponse(error);
          }
        }}
      />
    </PageLayout>
  );
};

export default CoworkersPage;
