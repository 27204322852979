import LinearProgress from "@material-ui/core/LinearProgress";
import withStyles from "@material-ui/core/styles/withStyles";

const FlexiLinearProgress = withStyles(theme => {
  return {
    root: {
      position: "fixed",
      height: "5px",
      width: "100%",
      left: "0",
      top: "0",
      right: "0",
      zIndex: 999999
    }
  };
})(LinearProgress);


export default FlexiLinearProgress;

