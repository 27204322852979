import React, { useState } from "react";
import { useIntl } from "react-intl";
import moment from "moment";
import { defineMessages } from "react-intl.macro";
import acceptSvg from "../../images/redesignImages/acceptSvg.svg";
import cancelSvg from "../../images/redesignImages/cancelSvg.svg";
import pauseSvg from "../../images/redesignImages/pauseSvg.svg";
import modifySvg from "../../images/redesignImages/modifySvg.svg";
import InfoIcon from "@material-ui/icons/Info";
import Grid from "@material-ui/core/Grid";
import { makeCurrencyValueFormatter } from "../../utils/makeCurrencyValueFormatter";
import Chip from "@material-ui/core/Chip";
import Popover from "@material-ui/core/Popover";
import EditForm from "./EditForm";
import makeInvoiceIdRenderer from "../../cellRenderers/makeInvoiceIdRenderer";
import Tooltip from "@material-ui/core/Tooltip";
import { getRejectReasonText } from "./RejectReasonDialog";
import FlexiExpandableRemoteList from "@bit/flexibill.flexi.flexi-expandable-remote-list";
import withStyles from "@material-ui/core/styles/withStyles";

const columnMessages = defineMessages({
  id: {
    id: "listColumnHeader.invoices.id",
    defaultMessage: "Számla sorszáma"
  },
  partnerName: {
    id: "listColumnHeader.invoices.partnerName",
    defaultMessage: "Partner neve"
  },
  issueDate: {
    id: "listColumnHeader.invoices.issueDate",
    defaultMessage: "Kiállítás dátuma"
  },
  taxPointDate: {
    id: "listColumnHeader.invoices.taxPointDate",
    defaultMessage: "Teljesítés dátuma"
  },
  totalGrossAmount: {
    id: "listColumnHeader.invoices.totalGrossAmount",
    defaultMessage: "Bruttó összeg"
  },
  compensation: {
    id: "listColumnHeader.invoices.compensation",
    defaultMessage: "Beszámítás összege"
  },
  dueDate: {
    id: "listColumnHeader.invoices.dueDate",
    defaultMessage: "Fizetési határidő"
  },
  modifiedDueDate: {
    id: "listColumnHeader.invoices.modifiedDueDate",
    defaultMessage: "Módosított fizetési határidő"
  },
  responseLabel: {
    id: "listColumnHeader.invoices.responseLabel",
    defaultMessage: "Visszaigazolás"
  },
  approve: {
    id: "listColumnHeader.invoices.approve",
    defaultMessage: "Elfogadás"
  },
  hold: {
    id: "listColumnHeader.invoices.hold",
    defaultMessage: "Szüneteltetés"
  },
  reject: {
    id: "listColumnHeader.invoices.reject",
    defaultMessage: "Elutasítás"
  },
  edit: {
    id: "listColumnHeader.invoices.edit",
    defaultMessage: "Módosítás"
  }
});

const chipMessages = defineMessages({
  labelAPPROVED: {
    id: "chip.APPROVED",
    defaultMessage: "Elfogadva"
  },
  labelREJECTED: {
    id: "chip.REJECTED",
    defaultMessage: "Elutasítva"
  },
  labelON_HOLD: {
    id: "chip.ON_HOLD",
    defaultMessage: "Várakoztatott"
  },
  labelPROGRESSING: {
    id: "chip.PROGRESSING",
    defaultMessage: "Folyamatban"
  },
  labelPENDING: {
    id: "chip.PROGRPROGRESSINGESSING",
    defaultMessage: "Várakozik"
  },
  labelundefined: {
    id: "chip.PROCESSING",
    defaultMessage: "NEM SZÜKSÉGES"
  }
});

export const getChipTranslations = status =>
  chipMessages[`label${status}`] || {
    id: `label${status}-MISSING`,
    defaultMessage: status
  };

const dateValueFormatter = ({ value }) =>
  value && moment(value).format("YYYY.MM.DD");

export const FeedbackChip = withStyles(() => {
  return {
    root: {
      borderRadius: "3px",
      minWidth: "121px",
      justifyContent: "space-between",
      backgroundColor: props => {
        switch (props.status) {
          case "APPROVED":
            return "#CDF6E5";
          case "REJECTED":
            return "#EDD6D9";
          case "ON_HOLD":
            return "#D8D8D8";
          default:
            return "#D6DAF7";
        }
      },
      color: props => {
        switch (props.status) {
          case "APPROVED":
            return "#02BE6F";
          case "REJECTED":
            return "#D3302F";
          case "ON_HOLD":
            return "#858585";
          default:
            return "#394AB8";
        }
      },
      "&:focus": {
        backgroundColor: props => {
          switch (props.status) {
            case "APPROVED":
              return "#E0EBD3";
            case "REJECTED":
              return "#EDD6D9";
            case "ON_HOLD":
              return "#D8D8D8";
            default:
              return "#D6DAF7";
          }
        },
        color: props => {
          switch (props.status) {
            case "APPROVED":
              return "#8BC34A";
            case "REJECTED":
              return "#D0021B";
            case "ON_HOLD":
              return "#9B9B9B";
            default:
              return "#394AB8";
          }
        }
      }
    }  };
})(Chip);

const getColumns = (
  intl,
  onApproveClick,
  onHoldClick,
  onRejectClick,
  editInvoice,
  onShowInvoiceDocumentsClick,
  onUndoClick,
  readOnly,
  params
) => {
  const items = [
    {
      headerName: intl.formatMessage(columnMessages.id),
      field: "invoiceId",
      sortable: true,
      autoHeight: true,
      resizable: true,
      mobileCellXs: 12,
      cellClass: "cell-wrap-text",
      cellRendererFramework: makeInvoiceIdRenderer(onShowInvoiceDocumentsClick)
    },
    {
      headerName: intl.formatMessage(columnMessages.issueDate),
      field: "issueDate",
      sortable: true,
      autoHeight: true,
      resizable: true,
      cellClass: "cell-wrap-text",
      valueFormatter: dateValueFormatter
    },
    {
      headerName: intl.formatMessage(columnMessages.taxPointDate),
      field: "taxPointDate",
      sortable: true,
      autoHeight: true,
      resizable: true,
      cellClass: "cell-wrap-text",
      valueFormatter: dateValueFormatter
    },
    {
      headerName: intl.formatMessage(columnMessages.dueDate),
      field: "dueDate",
      sortable: true,
      autoHeight: true,
      resizable: true,
      cellClass: "cell-wrap-text",
      valueFormatter: dateValueFormatter
    },
    {
      headerName: intl.formatMessage(columnMessages.modifiedDueDate),
      field: "modifiedDueDate",
      sortable: true,
      autoHeight: true,
      resizable: true,
      cellClass: "cell-wrap-text",
      valueFormatter: ({ value }) => {
        if (value) {
          return moment(value).format("YYYY.MM.DD");
        } else {
          return "-";
        }
      }
    },
    {
      headerName: intl.formatMessage(columnMessages.compensation),
      field: "compensation",
      sortable: true,
      autoHeight: true,
      resizable: true,
      cellClass: "cell-wrap-text",
      valueFormatter: ({ value, data }) =>
        makeCurrencyValueFormatter(intl)({
          value,
          data: { ...data, currency: data.currency }
        }),
      valueGetter: ({ data }) => data.compensation || data.initialCompensation
    },
    {
      headerName: intl.formatMessage(columnMessages.totalGrossAmount),
      field: "totalGrossAmount",
      sortable: true,
      autoHeight: true,
      resizable: true,
      cellClass: "cell-wrap-text",
      valueFormatter: ({ value, data }) =>
        makeCurrencyValueFormatter(intl)({
          value,
          data: { ...data, currency: data.currency }
        })
    },
    {
      headerName: intl.formatMessage(columnMessages.responseLabel),
      field: "status",
      sortable: true,
      autoHeight: true,
      cellClass: "cell-wrap-text",
      width: 250,
      cellRendererFramework: feedBackWrapper(
        intl,
        onApproveClick,
        onHoldClick,
        onRejectClick,
        onUndoClick,
        readOnly
      )
    }
  ];

  if (!readOnly) {
    items.push({
      headerName: "",
      field: "id",
      menu: true,
      cellRendererFramework: params => {
        return (
          <Tooltip title={"Módosítás"}>
            <img
              style={{
                cursor: "pointer"
              }}
              src={modifySvg}
              onClick={e => {
                editInvoice(e, params.data);
              }}
            />
          </Tooltip>
        );
      }
    });
  }

  return items;
};

const feedBackWrapper = (
  intl,
  onApproveClick,
  onHoldClick,
  onRejectClick,
  onUndoClick,
  readOnly
) => params => {
  if (
    params.data.customerConfirmationStatus === "APPROVED" ||
    params.data.customerConfirmationStatus === "ON_HOLD" ||
    params.data.customerConfirmationStatus === "REJECTED"
  ) {
    const declineReasonText = getRejectReasonText(
      params.data.customerDeclineReason
    );
    return (
      <Grid container wrap={"nowrap"} alignItems={"center"} >
        <FeedbackChip
          style={{justifyContent: readOnly ? "center" : undefined}}
          size="small"
          onDelete={readOnly ? undefined : () => onUndoClick(params.data)}
          label={intl.formatMessage(
            getChipTranslations(params.data.customerConfirmationStatus)
          )}
          status={params.data.customerConfirmationStatus}
        />
        {declineReasonText && (
          <Tooltip
            title={`${declineReasonText} ${
              params.data.customerDeclineComment
                ? ` - ${params.data.customerDeclineComment}`
                : ""
            }`}
          >
            <div>
              <InfoIcon style={{ fontSize: "1rem", marginTop: 4 }} />
            </div>
          </Tooltip>
        )}
      </Grid>
    );
  }

  return (
    <Grid container spacing={1} direction={"row"}>
      <Grid item>
        <Tooltip title={intl.formatMessage(columnMessages.approve)}>
          <img
            style={{ cursor: "pointer" }}
            src={acceptSvg}
            onClick={() => onApproveClick(params.data)}
          />
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip title={intl.formatMessage(columnMessages.hold)}>
          <img
            style={{ cursor: "pointer" }}
            src={pauseSvg}
            onClick={() => onHoldClick(params.data)}
          />
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip title={intl.formatMessage(columnMessages.reject)}>
          <img
            style={{ cursor: "pointer" }}
            src={cancelSvg}
            onClick={() => onRejectClick(params.data)}
          />
        </Tooltip>
      </Grid>
    </Grid>
  );
};

const CustomerConfirmationList = ({
  onModifyAndApproveClick,
  onApproveClick,
  onHoldClick,
  onRejectClick,
  onShowInvoiceDocumentsClick,
  onUndoClick,
  invoices,
  readOnly
}) => {
  const intl = useIntl();
  const [invoiceToEdit, setInvoiceToEdit] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const editInvoice = (e, invoice) => {
    setAnchorEl(e.currentTarget);
    setInvoiceToEdit({
      modifiedDueDate: invoice.dueDate,
      compensation: invoice.compensation !== 0 ? invoice.compensation : "",
      id: invoice.id,
      invoiceId: invoice.invoiceId,
      maxCompensation: invoice.totalGrossAmount
    });
  };

  return (
    <div>
      <FlexiExpandableRemoteList
        columnDefs={getColumns(
          intl,
          onApproveClick,
          onHoldClick,
          onRejectClick,
          editInvoice,
          onShowInvoiceDocumentsClick,
          onUndoClick,
          readOnly
        )}
        localItems={invoices}
        suppressExpand
      />
      <Popover
        open={Boolean(invoiceToEdit)}
        onClose={() => setInvoiceToEdit(null)}
        anchorEl={anchorEl}
        PaperProps={{ style: { overflowX: "initial", overflowY: "initial" } }}
      >
        <div style={{ margin: "30px", maxWidth: 350 }}>
          <EditForm
            onSubmit={values => {
              onModifyAndApproveClick(values);
              setInvoiceToEdit(null);
            }}
            initialValues={invoiceToEdit}
          />
        </div>
      </Popover>
    </div>
  );
};

export default CustomerConfirmationList;
