import Grid from "@material-ui/core/Grid";
import React from "react";
import { TextField } from "final-form-material-ui";
import Typography from "@material-ui/core/Typography";
import FlexiLink from "@bit/flexibill.flexi.flexi-link";
import { Field, Form } from "react-final-form";
import { required } from "../../../utils/validators";
import Button from "@material-ui/core/Button";

const ResetPasswordForm = props => {
  return (
    <div style={{ maxWidth: "410px", margin: "0 auto"}}>
      <Typography variant="h5" align="center" style={{ marginBottom: "2rem", marginTop: "32px" }}>
        Adja meg új jelszavát
      </Typography>
      <Form
        validate={values => {
          const errors = {};
          if (values?.newPassword?.second !== values?.newPassword?.first) {
            errors.newPassword = {
              second: "A két jelszónak egyeznie kell !"
            };
          }

          return errors;
        }}
        onSubmit={props.onSubmit}
        render={({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Grid container style={{ marginBottom: "12px" }}>
                <Grid item style={{ width: "100%" }}>
                  <Field
                    type={"password"}
                    name={"plainPassword.first"}
                    label={"Új Jelszó"}
                    component={TextField}
                    validate={required}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item style={{ width: "100%" }}>
                  <Field
                    type={"password"}
                    name={"plainPassword.second"}
                    label={"Új Jelszó megerősítés"}
                    component={TextField}
                    validate={required}
                    fullWidth
                  />
                  <Grid container style={{ marginTop: "32px" }}>
                    <Grid item xs={12} style={{ textAlign: "center" }}>
                      <Button
                        style={{margin: "auto"}}
                        type="submit"
                      >
                        Küldés
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          );
        }}
      />
      <Typography align="center" variant="body2" style={{ marginTop: "32px" }}>
        <FlexiLink
          style={{
            color: "#2227AC",
            textDecoration: "none",
            fontWeight: "bold"
          }}
          to="/login"
        >
          Vissza a bejelentkezéshez
        </FlexiLink>
      </Typography>
    </div>
  );
};

export default ResetPasswordForm;
