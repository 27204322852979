import React from "react";
import Typography from "@material-ui/core/Typography";
import Tache from "../../images/redesignImages/Tache.svg";
import tableBill from "../../images/redesignImages/Group 63.svg";
import Grid from "@material-ui/core/Grid";

const CustomerWelcomeHeader = () => {
  return (
    <Grid container style={{ backgroundColor: "#116EF2", height: "160px" }}>
      <Grid item xs={5}>
        <Typography
          variant={"subtitle1"}
          style={{
            color: "#fff",
            paddingLeft: "110px",
            paddingTop: "40px",
            width: "370px"
          }}
        >
          Üdvözöljük a Digitális Faktoring világában!
        </Typography>
      </Grid>
      <Grid item xs={7} style={{ paddingTop: "34px" }}>
        <img src={Tache} style={{ opacity: 0.1 }} />
      </Grid>
      <img
        src={tableBill}
        style={{ position: "absolute", top: 100, right: 136 }}
      />
    </Grid>
  );
};

export default CustomerWelcomeHeader;
