import React from "react";
import PageLayout from "../../components/PageLayout";
import { getUrlWithCompanyId } from "../../utils/urlHelpers";
import FlexiFileUploader from "@bit/flexibill.flexi.flexi-file-uploader";
import { Claims } from "../NewContractPage/Claims";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import FlexiInfoBar from "@bit/flexibill.flexi.flexi-info-bar";
import { useLimitChangeClaims, useLimitChangeData } from "../../hooks/useApi";
import { useHistory, useParams } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import { SvgIcon } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { useSaveSuccessNotification } from "../../hooks/useNotificationState";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const typeHelper = {
  supplier_tax_account: {
    title: "NAV adófolyószámla",
    info: "30 napnál nem régebbi dokumentum feltöltése szükséges."
  },
  supplier_fresh_ledger: {
    title: "Főkönyvi kivonat",
    info: "3 hónapnál nem régebbi dokumentum feltöltése szükséges."
  }
};
const LimitChangeFileUploadPage = ({}) => {
  const { id } = useParams();

  const {
    limitChangeClaim,
    uploadDocuments,
    reloadLimitChangeClaim,
    loading
  } = useLimitChangeData({ id });
  const { push: redirect } = useHistory();

  const uploadedTypes = limitChangeClaim?.documents.map(
    document => document.type
  );

  const uniqueTypes = [...new Set(uploadedTypes)];
  const allUploaded =
    limitChangeClaim?.requiredDocuments.length === uniqueTypes.length;
  const notifySuccessSave = useSaveSuccessNotification();

  return (
    <PageLayout
      insidePage
      pay
      suppressTourButton
      title="Limitmódosítás"
      loading={loading}
      rightSideComponents={[
        {
          props: {
            onClick: () => {
              redirect("/app/limit-change-list");
              notifySuccessSave();
            },
            children: "Mentés",
            disabled: !allUploaded
          },
          mobile: {
            placement: "fab",
            tooltip: "Mentés",
            icon: <SaveIcon />
          }
        }
      ]}
    >
      <FlexiInfoBar info>
        A limitmódosítási kérelmed elbírálásához szükségesek az alábbi
        dokumentumok. Töltsd fel őket, majd kattints a Tovább gombra!
      </FlexiInfoBar>
      <Typography style={{marginTop: "24px", marginBottom: "24px"}} variant={"h5"}>Dokumentumok - {limitChangeClaim?.supplierName}</Typography>
      <Grid container>
      {limitChangeClaim?.requiredDocuments.map((docType, i) => {
        const info = (
          <Tooltip title={typeHelper[docType].info}>
            <SvgIcon style={{ alignSelf: "end" }}>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
                fill="#2227AC"
              />
              <path
                d="M9.35381 10.9804L9.24448 11.4274C8.91648 11.5568 8.65448 11.6554 8.45938 11.7231C8.26407 11.7911 8.03713 11.8249 7.77856 11.8249C7.38148 11.8249 7.07263 11.7276 6.85236 11.5344C6.63208 11.3404 6.52191 11.0946 6.52191 10.7964C6.52191 10.681 6.5299 10.5622 6.54652 10.4413C6.56328 10.3202 6.58993 10.1838 6.6264 10.0313L7.03631 8.58045C7.07277 8.44152 7.10377 8.30989 7.1286 8.18541C7.15377 8.0617 7.16591 7.94788 7.16591 7.84542C7.16591 7.66014 7.12755 7.53054 7.05117 7.45767C6.9748 7.38502 6.82907 7.34813 6.61272 7.34813C6.50676 7.34813 6.39785 7.3651 6.28683 7.39792C6.17532 7.43088 6.08002 7.46272 6 7.49232L6.10961 7.04503C6.37814 6.9357 6.63481 6.84208 6.88034 6.76437C7.12586 6.68646 7.35785 6.64747 7.57729 6.64747C7.97163 6.64747 8.27592 6.74277 8.4896 6.93338C8.70329 7.12414 8.8101 7.37141 8.8101 7.67613C8.8101 7.73917 8.80308 7.85026 8.78801 8.00903C8.77328 8.16816 8.74586 8.31396 8.70588 8.44657L8.29773 9.8916C8.26428 10.0077 8.23419 10.1403 8.20803 10.2897C8.18103 10.438 8.16813 10.5513 8.16813 10.6273C8.16813 10.8192 8.21091 10.9502 8.29668 11.0198C8.38308 11.0895 8.53189 11.1241 8.74326 11.1241C8.8425 11.1241 8.95541 11.1065 9.08087 11.0719C9.20598 11.0374 9.29722 11.007 9.35381 10.9804ZM9.45732 4.91379C9.45732 5.16556 9.36244 5.38057 9.17176 5.55737C8.98156 5.7348 8.75238 5.82358 8.48427 5.82358C8.21533 5.82358 7.98558 5.7348 7.79322 5.55737C7.6012 5.3805 7.50498 5.16556 7.50498 4.91379C7.50498 4.66252 7.6012 4.44715 7.79322 4.26811C7.98523 4.08935 8.2154 4 8.48427 4C8.75231 4 8.98156 4.08956 9.17176 4.26811C9.36258 4.44715 9.45732 4.66259 9.45732 4.91379Z"
                fill="white"
              />
            </SvgIcon>
          </Tooltip>
        );

        return (
          <Grid xs={3} style={{marginRight: "20px"}}>
          <FlexiFileUploader
            index={i}
            immediateUpload
            getDownloadUrl={file =>
              getUrlWithCompanyId(`/document/${file.id}/download`)
            }
            onSubmit={async values => {
              await uploadDocuments({
                files: values.files.map(file => ({
                  file
                })),
                docType
              });
              await reloadLimitChangeClaim();
            }}
            title={typeHelper[docType].title}
            rightComponent={info}
            files={limitChangeClaim?.documents.filter(
              document => document.type === docType
            )}
          /></Grid>
        );
      })}
      </Grid>
    </PageLayout>
  );
};

export default LimitChangeFileUploadPage;
