import { useMutator } from "../../hooks/useApi";
import LinearProgress from "@material-ui/core/LinearProgress";
import Box from "@material-ui/core/Box";
import RegisterForm from "../RegisterPage/RegisterForm";
import { getFlexipayUrl } from "../../utils/urlHelpers";
import selectFieldErrorsFromResponse from "../../utils/selectFieldErrorsFromResponse";
import React, { useEffect, useState } from "react";
import { getClientId, gtagRegistration, initGtag } from "../../utils/gtag";

const style = `@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;900&display=swap");

* {
    font-family: "Poppins", sans-serif !important;
}

h5 {
    font-weight: 900 !important;
    font-size: 3rem !important;
}

.landing-button:hover {
    background-color: #4d558f !important;
    border-color: #4d558f;

}

.landing-button {
    cursor: pointer;

--page-title-display: block;
-webkit-font-smoothing: antialiased;
--e-global-color-primary: #6ec1e4;
--e-global-color-secondary: #54595f;
--e-global-color-text: #7a7a7a;
--e-global-color-accent: #61ce70;
--e-global-typography-primary-font-family: "Roboto";
--e-global-typography-primary-font-weight: 600;
--e-global-typography-secondary-font-family: "Roboto Slab";
--e-global-typography-secondary-font-weight: 400;
--e-global-typography-text-font-family: "Roboto";
--e-global-typography-text-font-weight: 400;
--e-global-typography-accent-font-family: "Roboto";
--e-global-typography-accent-font-weight: 500;
hyphens: manual;
vertical-align: baseline;
font-style: inherit;
outline: 0;
margin: 0;
border: 0;
box-sizing: border-box;
display: inline-block;
line-height: 1;
text-align: center;
transition: all 0.3s;
box-shadow: none;
text-decoration: none;
font-family: "Montserrat", Sans-serif;
font-size: 18px;
font-weight: 700;
fill: #ffffff;
color: #ffffff;
background-color: #000b55 !important;
border-style: solid;
border-width: 3px 3px 3px 3px;
border-color: #000b55;
border-radius: 5px 5px 5px 5px;
padding: 30px 60px 30px 60px;
text-transform: uppercase;}
        `;
const searchParams = new URLSearchParams(window.location.search);

const IframeRegister = ({ match, successRedirectUrl }) => {
  const token = match?.params?.token;
  const source = match?.params?.source;
  const successRedirectUrlFromParams = match?.params?.successRedirectUrl;
  const [post, postLoading] = useMutator("POST");
  const [gtmClientId, setGtmClientId] = useState(null);
  const salesEmail = searchParams.get("sales");
  const redirectUrl =
    (successRedirectUrlFromParams &&
      decodeURIComponent(successRedirectUrlFromParams)) ||
    successRedirectUrl;

  useEffect(() => {
    initGtag();
    getClientId()
      .then(clientId => setGtmClientId(clientId))
      .catch(console.log);
  }, []);
  return (
    <div>
      <style>{style}</style>
      {postLoading && (
        <LinearProgress
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%"
          }}
        />
      )}
      <Box padding={4}>
        <RegisterForm
          ButtonComponent={props => (
            <button {...props} className="landing-button" />
          )}
          onSubmit={async values => {
            const { data, error } = await post(getFlexipayUrl("/register"), {
              ...values,
              token,
              source,
              sales: salesEmail,
              gtmSessionId: gtmClientId
            });

            if (error) {
              return selectFieldErrorsFromResponse(error);
            }

            gtagRegistration();

            if (data && redirectUrl) {
              window.location.href = redirectUrl;
            }
          }}
        />
      </Box>
    </div>
  );
};

export default IframeRegister;
