import {
  getStoredCompanyId,
  getStoredToken,
  storeCompanyId,
  storeToken
} from "../utils/authentication";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { getConfig } from "../utils/config";
import { appendFilesWithTypesToFormData } from "../utils/appendFilesWithTypesToFormData";
import useSWR from "swr";
import { fetcher, getAuthHeader } from "../utils/fetcher";
import { useMemo, useState } from "react";
import { useEffect } from "react";
import download from "../utils/downloadBase64";
import dnload from "downloadjs";

import useFileToBase64 from "./useFileToBase64";
import { selectErrorNotificationMessage } from "../utils/selectErrorMessage";
import {
  getFlexipayUrl,
  getGuestUrl,
  getUrlWithCompanyId,
  getUrlWithCustomCompanyId
} from "../utils/urlHelpers";
import arrayEqualsIgnoreOrder from "../utils/arrayEqualsIgnoreOrder";
import { useUploadFiles } from "../components/FlexiFileUploadProvider";
import useFetch from "../hooks/useFetch";
import { useStoreData, useStoredData } from "./useDataState";
import useHasPermission from "../components/RoleProvider/useHasPermission";
import { ROLE_FLEXIPAY_CUSTOMER_PLUS } from "../roles";

// SWR

export const useSWRWithMainCompanyId = url => {
  const companyId = useMainCompanyId();

  return useSWR(() => companyId && getUrlWithCustomCompanyId(url, companyId));
};

export const useAgreementStat = () =>
  useSWRWithMainCompanyId("/agreement/stat");
export const useInvoiceStat = () =>
  useSWRWithMainCompanyId("/invoice/stat/expiring");
export const useInvoices = () => {
  const hasPermission = useHasPermission();
  const isScf = hasPermission([ROLE_FLEXIPAY_CUSTOMER_PLUS]);
  return useSWRWithMainCompanyId(isScf ? "/scfplus/invoice" : "/invoice");
};
export const useDocuments = () => {
  const hasPermission = useHasPermission();
  const isScf = hasPermission([ROLE_FLEXIPAY_CUSTOMER_PLUS]);
  return useSWRWithMainCompanyId(isScf ? "/scfplus/document" : "/document");
};
export const useAgreements = () => {
  const hasPermission = useHasPermission();
  const isScf = hasPermission([ROLE_FLEXIPAY_CUSTOMER_PLUS]);
  return useSWRWithMainCompanyId(isScf ? "/scfplus/agreement" : "/agreement");
};
export const useLimitChangeClaims = () =>
  useSWRWithMainCompanyId("/limitChangeClaim");
export const useCustomerInvoiceGroups = () =>
  useSWRWithMainCompanyId("/customer/invoice-group/");
export const useInvoice = id => {
  const hasPermission = useHasPermission();
  const isScf = hasPermission([ROLE_FLEXIPAY_CUSTOMER_PLUS]);
  return useSWRWithMainCompanyId(
    isScf ? `/scfplus/invoice/${id}` : `/invoice/${id}`
  );
};
export const useInvoiceGroup = id =>
  useSWRWithMainCompanyId(`/invoice-group/${id}`);
export const useFinanceNotifications = () =>
  useSWRWithMainCompanyId("/finance-notification");
export const useProfile = () =>
  useSWR(`${getConfig("API_URL")}/flexipay/profile`, fetcher);
export const useCoworkers = () => useSWRWithMainCompanyId(`/coworker`);
export const useContractAgreementClaims = () =>
  useSWRWithMainCompanyId(`/contractAgreementClaim`);
export const useInvoiceStats = () =>
  useSWRWithMainCompanyId("/invoice/stat/invoices");
export const useSzamlazzhuCustomers = szamlazzHu =>
  szamlazzHu
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      useSWRWithMainCompanyId("/contractAgreementClaim/szamlazzhuCustomers")
    : { data: [] };
export const useCountries = () => {
  const { data, error } = useSWR(`${getConfig("API_URL")}/countries`);

  if (data) {
    const formatted = Object.keys(data).reduce((acc, value) => {
      acc.push({ text: data[value], value });
      return acc;
    }, []);

    return { data: formatted };
  }

  return { data, error };
};
export const useInvoiceCount = () => {
  const hasPermission = useHasPermission();
  const isScf = hasPermission([ROLE_FLEXIPAY_CUSTOMER_PLUS]);
  const { data, isValidating: loading } = useSWRWithMainCompanyId(
    isScf ? `/scfplus/invoice/count` : `/invoice/count`
  );
  return { loading, count: data?.total };
};

export const useTokenForRegistration = (token, fromSubscribe) => {
  const apiURl = getConfig("API_URL");
  const url = fromSubscribe
    ? `${apiURl}/flexipay/subscription/${token}`
    : `${apiURl}/flexipay/register/${token}`;
  return useSWR(url, fetcher);
};

export const useTokenForExternalSalesRegistration = token => {
  const apiURl = getConfig("API_URL");
  const url = `${apiURl}/flexipay/external-sales/register/${token}`;
  return useSWR(url, fetcher);
};

export const useExternalSalesCompanyRegistration = () => {
  const [post, loading] = useMutator("POST");

  const register = ({ invite, ...data }) => {
    const regData = {
      companyName: data.party.partyName,
      surname: data.surname,
      firstname: data.firstname,
      email: data.email,
      phoneNumber: data.phoneNumber,
      taxNumber: data.party.taxNumber
    };

    return post(
      getFlexipayUrl(
        "/external-sales/create/company" + (invite ? "?invite=1" : "")
      ),
      regData
    );
  };

  return [register, loading];
};

export const useExternalSalesAddCustomer = () => {
  const [post, loading] = useMutator("POST");

  const addCustomer = data => {
    return post(
      getFlexipayUrl(
        `/external-sales/company/${data.companyId}/contractAgreementClaim/${data.id}/customer`
      ),
      data.companies.map(item => {
        return {
          ...item,
          calculation: {
            ...item.calculation,
            needInsurance: item.calculation.needInsurance === "true"
          },
          localBusiness: item.localBusiness === "true"
        };
      })
    );
  };

  return [addCustomer, loading];
};

export const useExternalSalesAgreementClaim = ({ companyId, id }) =>
  useSWR(
    companyId &&
      id &&
      getFlexipayUrl(
        `/external-sales/company/${companyId}/contractAgreementClaim/${id}`
      )
  );

export const useExternalSalesCreateAgreementClaim = () => {
  const [post, loading] = useMutator("POST");

  return [
    ({ companyId }) =>
      post(
        getFlexipayUrl(
          `/external-sales/company/${companyId}/contractAgreementClaim`
        )
      ),
    loading
  ];
};
export const useExternalSalesSendAgreementClaim = () => {
  const [post, loading] = useMutator("POST");

  return [
    ({ companyId, id }) =>
      post(
        getFlexipayUrl(
          `/external-sales/company/${companyId}/contractAgreementClaim/${id}/send`
        )
      ),
    loading
  ];
};

export const useProfileData = () => {
  const { data: profile, isValidating } = useProfile();
  const storeData = useStoreData();
  const szamlazzHu = useStoredData("szamlazzHu");
  const kulcsSoft = useStoredData("kulcsSoft");
  let mainCompany;

  if (profile?.user?.companies?.length) {
    const storedCompanyId = getStoredCompanyId();

    if (storedCompanyId) {
      mainCompany = profile.user.companies.find(
        company => company.company.id === storedCompanyId
      );
    }

    // if (!mainCompany) {
    //   mainCompany = profile.user.companies.find(
    //     company => company.company.isFaktoriAccount
    //   );
    // }

    if (!mainCompany) {
      mainCompany = profile.user.companies[0];
    }
  }

  const selectedCompanyId = mainCompany?.company.id;

  const {
    data: companyData,
    isValidating: companyLoading,
    revalidate
  } = useSWR(
    selectedCompanyId && getFlexipayUrl(`/company/${selectedCompanyId}`),
    fetcher
  );

  if (
    companyData?.registrationSource &&
    companyData?.registrationSource === "SZAMLAZZHU" &&
    !szamlazzHu
  ) {
    storeData("szamlazzHu", true);
  } else if (
    companyData?.registrationSource &&
    companyData?.registrationSource !== "SZAMLAZZHU" &&
    szamlazzHu
  ) {
    storeData("szamlazzHu", false);
  } else if (
    companyData?.registrationSource &&
    companyData?.registrationSource === "KULCSSOFT" &&
    !kulcsSoft
  ) {
    storeData("kulcsSoft", true);
  } else if (
    companyData?.registrationSource &&
    companyData?.registrationSource !== "KULCSSOFT" &&
    kulcsSoft
  ) {
    storeData("kulcsSoft", false);
  }
  const retValue = {
    user: profile?.user,
    mainCompany: companyData && { ...companyData, roles: mainCompany?.roles },
    companies: [],
    profileId: profile?.user?.id,
    loading: isValidating || companyLoading,
    szamlazzHu,
    kulcsSoft,
    reloadCompany: revalidate
  };

  if (retValue.mainCompany) {
    storeCompanyId(retValue.mainCompany.id);
    retValue.user = {
      id: profile?.user.id,
      email: profile?.user.email,
      firstname: profile?.user.firstname,
      surname: profile?.user.surname,
      phoneNumber: profile?.user.phoneNumber
    };
    retValue.companies = profile.user.companies
      .filter(company => {
        return company.company.id !== retValue.mainCompany.id;
      })
      .map(company => {
        const data = {
          name: company.company.party.partyName,
          id: company.company.id
        };

        if (company.company?.profilePicture?.path) {
          data.logoPath = company.company.profilePicture.path;
        }
        return data;
      });
  }

  return useMemo(() => retValue, [
    profile,
    companyData,
    isValidating,
    companyLoading
  ]);
};

export const useMainCompanyId = () => {
  const { mainCompany } = useProfileData();

  return mainCompany?.id;
};

export const useMutator = (method, options = {}) => {
  const [loading, setLoading] = useState(false);

  const mutate = useCallback(async (url, body, _options) => {
    let data;
    let error;
    setLoading(true);
    try {
      data = await (options.fetcher || fetcher)(url, {
        ...options,
        ..._options,
        method,
        body: body instanceof FormData ? body : JSON.stringify(body)
      });
    } catch (e) {
      error = e;
    }
    setLoading(false);
    return { data, error };
  }, []);

  return useMemo(() => [mutate, loading], [mutate, loading]);
};

export const useFileUploadWithTypes = ({ fileKey, typeKey } = {}) => {
  const [post, loading] = useMutator("POST");

  const upload = useCallback(async (url, files, appendToFormData) => {
    if (files.length) {
      const uploadPromises = [];

      files.forEach(file => {
        const formData = appendFilesWithTypesToFormData(
          [file],
          undefined,
          fileKey,
          typeKey
        );

        if (appendToFormData) {
          appendToFormData(formData, file);
        }

        uploadPromises.push(post(url, formData));
      });

      return Promise.all(uploadPromises).then(data => {
        if (data?.length) {
          return data.reduce(
            (acc, item) => {
              if (item.data) {
                acc.data.push(item.data);
              } else {
                acc.error = item.error;
              }

              return acc;
            },
            { data: [], error: undefined }
          );
        }

        return data;
      });
    } else {
      const formData = new FormData();

      if (appendToFormData) {
        appendToFormData(formData);
      }

      return post(url, formData);
    }
  }, []);

  return useMemo(() => [upload, loading], [upload, loading]);
};

export const useDashboardPageData = () => {
  const {
    data: agreementStat,
    isValidating: agreementStatLoading,
    revalidate: reloadAgreementStat
  } = useAgreementStat();
  const {
    data: invoiceStat,
    isValidating: invoiceStatLoading
  } = useInvoiceStat();
  const { data: invoices, isValidating: invoicesLoading } = useInvoices();

  return {
    agreementStat,
    invoiceStat,
    invoices,
    loading: invoiceStatLoading || agreementStatLoading || invoicesLoading,
    reloadAgreementStat
  };
};

export const useUploadFileWithTypeToDocuments = () => {
  const [mutate, loading] = useMutator("POST");

  const upload = useCallback(async fileWithType => {
    const formData = new FormData();
    formData.append("file", fileWithType.file);
    formData.append("type", fileWithType.type);

    return mutate(getUrlWithCompanyId("/document"), formData);
  }, []);

  return useMemo(() => [upload, loading], [loading, upload]);
};

export const useDocumentsPageData = () => {
  const {
    data: documents,
    revalidate: revalidateDocuments,
    isValidating: documentsLoading
  } = useDocuments();
  const [uploadFileWithType, uploading] = useUploadFileWithTypeToDocuments();
  const [download, downloading] = useFileDownloader();
  const downloadDocument = useCallback(document => {
    download(`/document/${document.id}/download`, document.originalName);
  }, []);

  return useMemo(
    () => ({
      documents,
      reloadDocuments: revalidateDocuments,
      uploadFileWithType,
      downloadDocument,
      loading: uploading || documentsLoading || downloading,
      uploading
    }),
    [
      documents,
      revalidateDocuments,
      uploadFileWithType,
      uploading,
      documentsLoading,
      downloadDocument,
      downloading
    ]
  );
};

export const useFileDownloader = () => {
  const [url, setUrl] = useState(false);
  const [fileName, setFileName] = useState(false);
  const { data, isValidating: loading } = useSWR(() => {
    if (url) {
      if (url.indexOf("//") === 0 || url.indexOf("http") === 0) {
        return url;
      }

      return getUrlWithCompanyId(url);
    }
  });

  useEffect(() => {
    const base64Content = data?.image || data?.pdf;
    if (fileName && base64Content) {
      download(fileName, base64Content);
      setUrl(null);
      setFileName(null);
    }
  }, [data, fileName]);

  const downloadFn = useCallback((url, fileName) => {
    setUrl(url);
    setFileName(fileName);
  }, []);

  return useMemo(() => [downloadFn, loading], [url, loading]);
};

export const useInvoiceDocumentDownload = () => {
  const [download, downloading] = useFileDownloader();

  const downloadDocument = useCallback(document => {
    download(`/invoice/document/${document.id}`, document.originalName);
  }, []);

  return useMemo(() => [downloadDocument, downloading], [downloading]);
};

export const useInvoiceDocumentsPageData = id => {
  const { data: invoice, isValidating: invoiceLoading } = useInvoice(id);
  const [downloadDocument, downloading] = useInvoiceDocumentDownload();

  return useMemo(
    () => ({
      invoice,
      downloadDocument,
      loading: invoiceLoading || downloading
    }),
    [invoice, downloadDocument, downloading]
  );
};

export const useInvoiceMutator = newInvoice => {
  const [mutator, mutatorLoading] = useMutator(newInvoice ? "POST" : "PUT");
  const uploadFiles = useUploadFiles();
  const hasPermission = useHasPermission();
  const isScf = hasPermission([ROLE_FLEXIPAY_CUSTOMER_PLUS]);

  const modify = useCallback(async (invoice, files) => {
    const { data: invoiceResponse, error: invoiceError } = await mutator(
      getUrlWithCompanyId(
        isScf
          ? newInvoice
            ? "/scfplus/invoice"
            : `/scfplus/invoice/${invoice.id}`
          : newInvoice
          ? "/invoice"
          : `/invoice/${invoice.id}`
      ),
      invoice
    );

    if (invoiceResponse && invoiceResponse.id && files && files.length) {
      uploadFiles({
        files,
        url: getUrlWithCompanyId(
          isScf
            ? "/scfplus/invoice/upload-document"
            : "/invoice/upload-document"
        ),
        getBody: file => {
          const formData = new FormData();
          formData.append("invoices[0]", invoiceResponse.id);
          formData.append("document", file.file);
          formData.append("document-type", file.type);

          if (file.customDocumentType) {
            formData.append("custom-document-type", file.customDocumentType);
          }

          return formData;
        }
      });
    }

    return { data: invoiceResponse, error: invoiceError };
  }, []);

  return useMemo(() => [modify, mutatorLoading], [modify, mutatorLoading]);
};

export const useInvoiceEditPageData = id => {
  const [modify, putLoading] = useInvoiceMutator(false);
  const [deleteRequest, deleteLoading] = useMutator("DELETE");
  const { data: agreements, isValidating: agreementsLoading } = useAgreements();
  const {
    data: invoice,
    isValidating: invoiceLoading,
    revalidate: reloadInvoice
  } = useInvoice(id);

  const deleteAttachment = useCallback(
    documentId =>
      deleteRequest(
        getUrlWithCompanyId(`/invoice/${id}/document/${documentId}`)
      ),
    []
  );

  return useMemo(
    () => ({
      agreements,
      invoice,
      reloadInvoice,
      modify,
      deleteAttachment,
      loading:
        putLoading || agreementsLoading || invoiceLoading || deleteLoading
    }),
    [
      agreements,
      invoice,
      reloadInvoice,
      modify,
      putLoading,
      agreementsLoading,
      invoiceLoading,
      deleteAttachment,
      deleteLoading
    ]
  );
};

export const useInvoiceFormPageData = () => {
  const [create, postLoading] = useInvoiceMutator(true);
  const { data: agreements, isValidating: agreementsLoading } = useAgreements();

  return useMemo(
    () => ({
      agreements,
      create,
      loading: postLoading || agreementsLoading
    }),
    [agreements, create, postLoading, agreementsLoading]
  );
};

export const useInvoiceGrouping = () => {
  const [post, postLoading] = useMutator("POST");
  const uploadFiles = useUploadFiles();

  const prepareGroup = invoices => {
    const invoicesFormData = invoices.reduce((formData, invoice, index) => {
      formData.append(`invoices[${index}]`, invoice.id);
      return formData;
    }, new FormData());

    return post(getUrlWithCompanyId("/invoice-group"), invoicesFormData);
  }; //Elokesziti a groupot

  const submitGroup = groupId => {
    return post(getUrlWithCompanyId(`/invoice-group/${groupId}/submit`));
  }; //Submit

  const attachFiles = (files, groupId) => {
    return uploadFiles({
      files,
      url: getUrlWithCompanyId(`/invoice-group/${groupId}/upload-document`),
      getFileName: file => file.file.name,
      getBody: file => {
        const formData = new FormData();
        formData.append("document", file.file);
        formData.append("document-type", file.type);

        if (file.customDocumentType) {
          formData.append("custom-document-type", file.customDocumentType);
        }

        return formData;
      }
    });
  }; //Hozza csatol file-okat

  const startGrouping = useCallback(
    async ({
      invoices = [],
      files = [],
      existingInvoiceGroup,
      onPrepareSuccess
    }) => {
      let prepareResult;
      if (existingInvoiceGroup) {
        prepareResult = { data: existingInvoiceGroup };
      } else {
        prepareResult = await prepareGroup(invoices);
        onPrepareSuccess(prepareResult.data);
      }
      const { data: preparedGroup } = prepareResult;

      if (preparedGroup) {
        if (files?.length) {
          try {
            await attachFiles(files, preparedGroup.id);
            return submitGroup(preparedGroup.id);
          } catch (e) {
            console.error("Nem sikerült a feltöltés", e);
          }
        } else {
          return submitGroup(preparedGroup.id);
        }
      }

      return prepareResult;
    },
    []
  );

  return useMemo(
    () => ({
      startGrouping,
      loading: postLoading
    }),
    [startGrouping, postLoading]
  );
};

export const useInvoicePageData = () => {
  const {
    data: invoices,
    revalidate: reloadInvoices,
    isValidating: invoicesLoading
  } = useInvoices();
  const [massFilePost, uploadLoading] = useMutator("POST");
  const [invoiceDelete, deleteLoading] = useMutator("DELETE");
  const { startGrouping, loading: groupLoading } = useInvoiceGrouping();
  const hasPermission = useHasPermission();
  const isScf = hasPermission([ROLE_FLEXIPAY_CUSTOMER_PLUS]);
  const uploadMassInvoice = useCallback(async file => {
    const formData = new FormData();
    formData.append("file", file);
    if (isScf) {
      return massFilePost(
        getUrlWithCompanyId("/scfplus/invoice/mass-upload"),
        formData
      );
    } else {
      return massFilePost(
        getUrlWithCompanyId("/invoice/mass-upload"),
        formData
      );
    }
  }, []);

  const deleteInvoice = useCallback(
    id => invoiceDelete(getUrlWithCompanyId(`/invoice/${id}`)),
    []
  );

  return useMemo(
    () => ({
      uploadMassInvoice,
      invoices,
      reloadInvoices,
      startGrouping,
      deleteInvoice,
      loading:
        uploadLoading || invoicesLoading || groupLoading || deleteLoading,
      uploading: uploadLoading || groupLoading
    }),
    [
      uploadMassInvoice,
      invoices,
      reloadInvoices,
      uploadLoading,
      invoicesLoading,
      startGrouping,
      groupLoading,
      deleteInvoice,
      deleteLoading
    ]
  );
};

// const useDocumentGenerated = ({ id, getUrl, fetcher }) => {
//   const url = getUrl(id);
//   const { data, error } = useSWR(url, fetcher);
//
//   if (data?.generated) {
//     return true;
//   }
//
//   return false;
// };
//
// const useRemoteDocument = ({ fetcher, getUrl, documentId }) => {
//   const url = getUrl(documentId);
//
//   const { data, error } = useSWR(url, fetcher);
// };

export const useSignFactoringNotification = (invoiceGroup, options) => {
  const getDownloadUrl =
    options.getDownloadUrl ||
    function(id) {
      return id && getUrlWithCompanyId(`/document/${id}/download`);
    };
  const isCustomer = options.type === "customer";
  const {
    data: originalNotificationImageForSupplier,
    isValidating: originalDocumentLoadingBySupplier,
    error: error1
  } = useSWR(
    () =>
      getDownloadUrl(
        invoiceGroup.financeNotification.documentGeneratedForSupplier.id || null
      ),
    options.fetcher
  );

  const {
    data: signedNotificationImageBySupplier,
    isValidating: signedDocumentLoadingBySupplier,
    error: error2
  } = useSWR(
    () =>
      getDownloadUrl(
        invoiceGroup?.financeNotification?.documentSignedBySupplier?.id
      ),
    options.fetcher
  );

  const {
    data: originalNotificationImageForCustomer,
    isValidating: originalDocumentLoadingByCustomer,
    error: error3
  } = useSWR(
    () =>
      getDownloadUrl(
        invoiceGroup.financeNotification.documentGeneratedForCustomer.id || null
      ),
    options.fetcher
  );
  const {
    data: signedNotificationImageByCustomer,
    isValidating: signedDocumentLoadingByCustomer,
    error: error4
  } = useSWR(
    () =>
      getDownloadUrl(
        invoiceGroup?.financeNotification?.documentSignedByCustomer?.id
      ),
    options.fetcher
  );

  const [post, postLoading] = useMutator("POST", {
    fetcher: options.fetcher
  });
  const [signedNotification, setSignedNotification] = useState(null);
  const [signedNotificationBase64] = useFileToBase64(signedNotification);
  const [downloadFile, downloading] = useFileDownloader();

  const uploadSignedDocument = useCallback(
    async file => {
      const formData = new FormData();
      formData.append("file", file || signedNotification);

      const uploadUrl = options.getUploadUrl
        ? options.getUploadUrl(invoiceGroup)
        : getUrlWithCompanyId(
            `/invoice-group/${invoiceGroup &&
              invoiceGroup.id}/upload-signed/supplier`
          );

      return post(uploadUrl, formData);
    },
    [signedNotification, invoiceGroup]
  );

  const createDigitalSignSecretLink = useCallback(() => {
    const getUrl = isCustomer ? getGuestUrl : getUrlWithCompanyId;

    const url = isCustomer
      ? getUrl("/customer/invoice-group/e-sign/customer")
      : getUrl(`/invoice-group/${invoiceGroup.id}/e-sign/supplier`);

    return post(url);
  }, [invoiceGroup]);

  const setSignedDocument = useCallback(
    file => setSignedNotification(file),
    []
  );

  const downloadDocument = useCallback(document => {
    downloadFile(getDownloadUrl(document.id), `${document.originalName}`);
  }, []);

  return useMemo(
    () => ({
      originalNotificationBase64: isCustomer
        ? originalNotificationImageForCustomer &&
          originalNotificationImageForCustomer.image &&
          `data:application/pdf;base64,${originalNotificationImageForCustomer.image}`
        : originalNotificationImageForSupplier &&
          originalNotificationImageForSupplier.image &&
          `data:application/pdf;base64,${originalNotificationImageForSupplier.image}`,
      originalNotificationBase64Image: isCustomer
        ? originalNotificationImageForCustomer &&
          originalNotificationImageForCustomer.image
        : originalNotificationImageForSupplier &&
          originalNotificationImageForSupplier.image,
      uploadSignedDocument,
      signedNotificationBase64,
      signedNotificationType: signedNotification && signedNotification.type,
      setSignedDocument,
      downloadDocument,
      signedNotificationDocument: {
        image: isCustomer
          ? signedNotificationImageByCustomer?.image
          : signedNotificationImageBySupplier?.image,
        mimeType: isCustomer
          ? invoiceGroup?.financeNotification?.documentSignedByCustomer
              ?.mimeType
          : invoiceGroup?.financeNotification?.documentSignedBySupplier
              ?.mimeType
      },
      loading:
        originalDocumentLoadingBySupplier ||
        postLoading ||
        downloading ||
        signedDocumentLoadingBySupplier,
      uploading: postLoading,
      createDigitalSignSecretLink,
      error: error1 || error2 || error3 || error4
    }),
    [
      originalNotificationImageForSupplier &&
        originalNotificationImageForSupplier.image,
      signedNotification && signedNotification.type,
      uploadSignedDocument,
      setSignedDocument,
      signedNotificationBase64,
      downloadDocument,
      originalDocumentLoadingBySupplier,
      postLoading,
      downloading,
      signedDocumentLoadingBySupplier,
      signedNotificationImageBySupplier,
      invoiceGroup?.financeNotification?.documentSignedBySupplier?.mimeType,
      createDigitalSignSecretLink,
      signedDocumentLoadingByCustomer,
      originalDocumentLoadingByCustomer,
      error1,
      error2,
      error3,
      error4
    ]
  );
};

export const useFactorNotificationsData = selectedDocument => {
  const signFactorNotification = useSignFactoringNotification(
    selectedDocument,
    {}
  );
  const {
    data: financeNotifications,
    revalidate: reloadList,
    isValidating: notificationsLoading
  } = useFinanceNotifications();

  return useMemo(
    () => ({
      signFactorNotification,
      financeNotifications,
      reloadList,
      loading: signFactorNotification.loading || notificationsLoading
    }),
    [
      signFactorNotification,
      notificationsLoading,
      financeNotifications,
      reloadList
    ]
  );
};

export const useLoginPageData = () => {
  const [login, loginLoading] = useMutator("POST");
  const storeData = useStoreData();

  const doLogin = useCallback(async (values, headerName, url) => {
    let loginResponse;
    let error;
    if (typeof values === "object") {
      //sima login
      const { data, error: e } = await login(
        `${getConfig("API_URL")}/flexipay/login`,
        values
      );
      loginResponse = data;
      error = e;
      storeData("szamlazzHu", false);
    } else if (typeof values === "string") {
      // sso szamlazzhu
      const { data, error: e } = await login(
        `${getConfig("API_URL")}${url || "/szamlazzhu/sso/login"}`,
        undefined,
        {
          headers: { Authorization: `${headerName} ${values}` }
        }
      );
      loginResponse = data;
      error = e;
    }

    if (loginResponse?.token) {
      storeToken(loginResponse.token);
    }

    return { data: loginResponse, error };
  }, []);

  return useMemo(() => [doLogin, loginLoading], [doLogin, loginLoading]);
};

export const useSaveMassInvoicePageData = () => {
  const [saveMass, massLoading] = useMutator("POST");
  const [upload, uploadLoading] = useFileUploadWithTypes();
  const hasPermission = useHasPermission();
  const isScf = hasPermission([ROLE_FLEXIPAY_CUSTOMER_PLUS]);
  const saveMassInvoice = useCallback(
    async (selectedInvoices, files, agreementId) => {
      const { data: saveResult, error: saveError } = await saveMass(
        getUrlWithCompanyId(
          isScf ? "/scfplus/invoice/mass-create" : "/invoice/mass-create"
        ),
        {
          invoices: selectedInvoices,
          agreementId
        }
      );

      if (saveResult && files && files.length) {
        return upload(
          getUrlWithCompanyId("/invoice/upload-document"),
          files,
          formData =>
            saveResult.forEach((invoice, i) =>
              formData.append(`invoices[${i}]`, invoice.id)
            )
        );
      }

      return { data: saveResult, error: saveError };
    },
    []
  );

  return useMemo(
    () => ({
      saveMassInvoice,
      loading: massLoading || uploadLoading,
      uploading: uploadLoading
    }),
    [saveMassInvoice, massLoading, uploadLoading]
  );
};

export const useCoworkerData = () => {
  const [post, postLoading] = useMutator("POST");
  const [put, putLoading] = useMutator("PUT");
  const [deleteRequest, deleteLoading] = useMutator("DELETE");

  const editCoworker = useCallback(async coworkerData => {
    const { id, user, ...data } = coworkerData;
    return put(
      `${getConfig(
        "API_URL"
      )}/flexipay/company/${getStoredCompanyId()}/coworker/${id}`,
      data
    );
  }, []);

  const deleteCoworker = useCallback(async coworkerData => {
    const { id } = coworkerData;
    return deleteRequest(
      `${getConfig(
        "API_URL"
      )}/flexipay/company/${getStoredCompanyId()}/coworker/${id}`
    );
  }, []);

  const sendInviteAgain = useCallback(async coworkerData => {
    const { id } = coworkerData;
    return put(
      `${getConfig(
        "API_URL"
      )}/flexipay/company/${getStoredCompanyId()}/coworker/${id}/invitation/refresh`
    );
  }, []);

  const sendInvite = useCallback(async coworkerData => {
    return post(
      `${getConfig(
        "API_URL"
      )}/flexipay/company/${getStoredCompanyId()}/coworker`,
      coworkerData
    );
  }, []);

  return useMemo(
    () => ({
      sendInvite,
      sendInviteAgain,
      deleteCoworker,
      editCoworker,
      loading: postLoading || putLoading || deleteLoading
    }),
    [
      sendInvite,
      sendInviteAgain,
      deleteCoworker,
      editCoworker,
      postLoading,
      putLoading,
      deleteLoading
    ]
  );
};

export const useRemoteDocumentViewer = ({ getUrl, fetcher }) => {
  const [docId, setDocId] = useState(false);
  const { data, error, isValidating } = useSWR(getUrl(docId), fetcher);

  return useMemo(
    () => ({
      data,
      error,
      viewDocument: id => setDocId(id),
      loading: isValidating
    }),
    [data, error, docId, isValidating]
  );
};

export const useCustomerConfirmationPageData = token => {
  const authHeaderOption = {
    headers: {
      Authorization: token
    }
  };
  const invoiceGroupUrl = getGuestUrl("/customer/invoice-group");
  const guestFetch = useCallback(
    (url, opt = { method: "GET" }) =>
      fetch(url, {
        ...authHeaderOption,
        method: opt.method,
        body: opt.body
      }).then(async resp => {
        const json = await resp.json();

        if (!resp.ok) {
          throw new Error(selectErrorNotificationMessage(json).text);
        }

        return json;
      }),
    []
  );
  const getGroupDocumentUrl = useCallback(
    id => id && getGuestUrl(`/customer/invoice-group/document-download/${id}`),
    []
  );

  const {
    data: invoiceGroup,
    isValidating: invoiceGroupLoading,
    revalidate: reloadInvoiceGroup,
    error: invoiceGroupError
  } = useSWR(invoiceGroupUrl, guestFetch, {
    revalidateOnFocus: false
  });
  const [post, postLoading] = useMutator("POST", {
    ...authHeaderOption,
    fetcher: guestFetch
  });
  const signFactorNotificationOptions = useMemo(
    () => ({
      // Itt kell folytatni
      getDownloadUrl: id =>
        invoiceGroup?.status !== "WAITING_FOR_CUSTOMER_CONFIRMATION" &&
        getGuestUrl("/customer/invoice-group/finance-notification/download"),
      getUploadUrl: () =>
        getGuestUrl("/customer/invoice-group/finance-notification/upload"),
      fetcher: guestFetch,
      type: "customer"
    }),
    []
  );

  const approveInvoice = useCallback(
    invoice => {
      const { id, modifiedDueDate, compensation } = invoice;
      return post(getGuestUrl(`/customer/invoice-group/${id}/approve`), {
        compensation,
        modifiedDueDate
      });
    },
    [post]
  );

  const modifyAndApproveInvoice = useCallback(
    invoice => {
      const { id, ...values } = invoice;
      return post(getGuestUrl(`/customer/invoice-group/${id}/approve`), values);
    },
    [post]
  );

  const rejectInvoice = useCallback(
    ({ id, rejectInfo }) => {
      return post(
        getGuestUrl(`/customer/invoice-group/${id}/reject`),
        rejectInfo
      );
    },
    [post]
  );

  const holdInvoice = useCallback(
    ({ id }) => {
      return post(getGuestUrl(`/customer/invoice-group/${id}/hold`));
    },
    [post]
  );

  const createLimitChangeClaim = useCallback(
    ({ id, companyId, body }) => {
      return post(
        getFlexipayUrl(`/flexipay/company/${companyId}/limitChangeClaim/${id}`),
        body
      );
    },
    [post]
  );
  const neutralizeInvoice = useCallback(
    ({ id }) => {
      return post(getGuestUrl(`/customer/invoice-group/${id}/neutralize`));
    },
    [post]
  );

  const commit = useCallback(() => {
    return post(getGuestUrl("/customer/invoice-group/commit"));
  }, [post]);

  const getInvoiceGroupAttachment = useCallback(id => {
    return guestFetch(
      getGuestUrl(`/customer/invoice-group/document-download/${id}`)
    );
  }, []);

  return useMemo(
    () => ({
      loading: invoiceGroupLoading || postLoading,
      invoiceGroup,
      invoiceGroupError,
      reloadInvoiceGroup,
      guestFetch,
      getGroupDocumentUrl,
      invoiceGroupUrl,
      signFactorNotificationOptions,
      actions: {
        approve: approveInvoice,
        reject: rejectInvoice,
        hold: holdInvoice,
        modifyAndApprove: modifyAndApproveInvoice,
        commit,
        undo: neutralizeInvoice
      }
    }),
    [
      invoiceGroupLoading,
      postLoading,
      invoiceGroup,
      reloadInvoiceGroup,
      approveInvoice,
      rejectInvoice,
      holdInvoice,
      commit,
      getInvoiceGroupAttachment,
      getGroupDocumentUrl,
      guestFetch,
      invoiceGroupError,
      modifyAndApproveInvoice,
      neutralizeInvoice,
      invoiceGroupUrl,
      signFactorNotificationOptions,
      createLimitChangeClaim
    ]
  );
};

export const useCustomerInvoiceGroupPageData = invoiceGroupId => {
  const invoiceGroupUrl = getUrlWithCompanyId(
    `/customer/invoice-group/${invoiceGroupId}`
  );

  const getGroupDocumentUrl = useCallback(
    id =>
      id &&
      getUrlWithCompanyId(
        `/customer/invoice-group/${invoiceGroupId}/document-download/${id}`
      ),
    []
  );

  const {
    data: invoiceGroup,
    isValidating: invoiceGroupLoading,
    revalidate: reloadInvoiceGroup,
    error: invoiceGroupError
  } = useSWR(invoiceGroupUrl, undefined, {
    revalidateOnFocus: false
  });
  const [post, postLoading] = useMutator("POST");
  const signFactorNotificationOptions = useMemo(
    () => ({
      getDownloadUrl: id => {
        if (invoiceGroup?.status !== "WAITING_FOR_CUSTOMER_CONFIRMATION") {
          if (
            id &&
            invoiceGroup.financeNotification.documentSignedByCustomer?.id === id
          ) {
            return getUrlWithCompanyId(
              `/customer/invoice-group/${invoiceGroupId}/finance-notification/signed-by-customer/download`
            );
          }

          return getUrlWithCompanyId(
            `/customer/invoice-group/${invoiceGroupId}/finance-notification/download`
          );
        }
      },
      getUploadUrl: () =>
        getUrlWithCompanyId(
          `/customer/invoice-group/${invoiceGroupId}/finance-notification/upload`
        ),
      type: "customer"
    }),
    [invoiceGroup]
  );

  const approveInvoice = useCallback(
    invoice => {
      const { id, modifiedDueDate, compensation } = invoice;
      return post(
        getUrlWithCompanyId(
          `/customer/invoice-group/${invoiceGroupId}/invoice/${id}/approve`
        ),
        {
          compensation,
          modifiedDueDate
        }
      );
    },
    [post]
  );

  const approveAllInvoice = useCallback(() => {
    return post(
      getUrlWithCompanyId(
        `/customer/invoice-group/${invoiceGroupId}/approve/all`
      )
    );
  }, [post]);

  const modifyAndApproveInvoice = useCallback(
    invoice => {
      const { id, ...values } = invoice;
      return post(
        getUrlWithCompanyId(
          `/customer/invoice-group/${invoiceGroupId}/invoice/${id}/approve`
        ),
        values
      );
    },
    [post]
  );

  const rejectInvoice = useCallback(
    ({ id, rejectInfo }) => {
      return post(
        getUrlWithCompanyId(
          `/customer/invoice-group/${invoiceGroupId}/invoice/${id}/reject`
        ),
        rejectInfo
      );
    },
    [post]
  );

  const holdInvoice = useCallback(
    ({ id }) => {
      return post(
        getUrlWithCompanyId(
          `/customer/invoice-group/${invoiceGroupId}/invoice/${id}/hold`
        )
      );
    },
    [post]
  );

  const holdAllInvoice = useCallback(() => {
    return post(
      getUrlWithCompanyId(`/customer/invoice-group/${invoiceGroupId}/hold/all`)
    );
  }, [post]);

  const neutralizeInvoice = useCallback(
    ({ id }) => {
      return post(
        getUrlWithCompanyId(
          `/customer/invoice-group/${invoiceGroupId}/invoice/${id}/neutralize`
        )
      );
    },
    [post]
  );

  const commit = useCallback(() => {
    return post(
      getUrlWithCompanyId(`/customer/invoice-group/${invoiceGroupId}/commit`)
    );
  }, [post]);

  const getInvoiceGroupAttachment = useCallback(id => {
    return fetcher(
      getUrlWithCompanyId(
        `/customer/invoice-group/${invoiceGroupId}/document-download/${id}`
      )
    );
  }, []);

  return useMemo(
    () => ({
      loading: invoiceGroupLoading || postLoading,
      invoiceGroup,
      invoiceGroupError,
      reloadInvoiceGroup,
      getGroupDocumentUrl,
      invoiceGroupUrl,
      signFactorNotificationOptions,
      actions: {
        approve: approveInvoice,
        reject: rejectInvoice,
        hold: holdInvoice,
        modifyAndApprove: modifyAndApproveInvoice,
        commit,
        undo: neutralizeInvoice,
        approveAllInvoice,
        holdAllInvoice
      }
    }),
    [
      invoiceGroupLoading,
      postLoading,
      invoiceGroup,
      reloadInvoiceGroup,
      approveInvoice,
      rejectInvoice,
      holdInvoice,
      commit,
      getInvoiceGroupAttachment,
      getGroupDocumentUrl,
      invoiceGroupError,
      modifyAndApproveInvoice,
      neutralizeInvoice,
      invoiceGroupUrl,
      signFactorNotificationOptions,
      approveAllInvoice,
      holdAllInvoice
    ]
  );
};

export const useNewContractAgreementClaimPost = () => {
  const { push: redirect } = useHistory();

  const run = async () => {
    redirect(`/app/agreement-claims/new-contract/new`);
  };

  return [run];
};

export const useLimitChangeData = ({ id }) => {
  const uploadFiles = useUploadFiles();
  const {
    data: limitChangeClaim,
    isValidating: limitChangeClaimLoading,
    revalidate: reloadLimitChangeClaim
  } = useSWR(id && getUrlWithCompanyId(`/limitChangeClaim/${id}`));
  const uploadDocuments = useCallback(
    values => {
      const files = values.files
        .filter(doc => !doc.file.id)
        .map(doc => ({ ...doc, type: values.docType }));

      return uploadFiles({
        files,
        url: getUrlWithCompanyId(`/limitChangeClaim/${id}/document`),
        getBody: file => {
          const formData = new FormData();
          formData.append("file", file.file);
          formData.append("type", file.type);

          return formData;
        }
      });
    },
    [id]
  );
  return {
    uploadDocuments,
    limitChangeClaim,
    reloadLimitChangeClaim,
    loading: limitChangeClaimLoading
  };
};

export const useNewContractPageData = ({ isGuest, token, id }) => {
  const [post, postLoading] = useMutator("POST");
  const [deleteRequest, deleteLoading] = useMutator("DELETE");
  const [getRequest, getLoading] = useMutator("GET");
  const fileFetch = useFetch({ token: getStoredToken(), blob: true });

  const authHeaderOption = {
    headers: {
      Authorization: token
    }
  };
  const [downloadHook, downloading] = useFileDownloader();
  const uploadFiles = useUploadFiles();

  const sendCalculation = useCallback(
    async calculation => {
      return getRequest(
        `${getConfig(
          "API_URL"
        )}/flexipay/company/${getStoredCompanyId()}/contractAgreementClaim/${id}/send/calculation`
      );
    },
    [getRequest, id]
  );
  const guestFetch = useCallback(
    (url, opt = { method: "GET" }) =>
      fetch(url, {
        ...authHeaderOption,
        method: opt.method,
        body: opt.body
      }).then(async resp => {
        const json = await resp.json();

        if (!resp.ok) {
          throw new Error(selectErrorNotificationMessage(json).text);
        }

        return json;
      }),
    []
  );

  const getUrl = isGuest ? getGuestUrl : getUrlWithCompanyId;
  const fetcher = isGuest ? guestFetch : undefined;
  const swrOptions = fetcher && { fetcher };

  const {
    data: agreementClaim,
    isValidating: agreementClaimLoading,
    revalidate: reloadAgreementClaim
  } = useSWR(id && getUrl(`/contractAgreementClaim/${id}`), swrOptions);

  const { data: documentTypes, isValidating: documentTypesLoading } = useSWR(
    getUrl("/contractAgreementClaim/document/details"),
    swrOptions
  );

  const transformDocumentTypes = docTypes => {
    return (
      docTypes &&
      Object.keys(docTypes).reduce((acc, key) => {
        const docType = docTypes[key];
        acc.push({
          type: key,
          customerType: docType.type,
          title: docType.title,
          info: docType.description,
          sampleFile: docType.sample
        });

        return acc;
      }, [])
    );
  };

  const uploadDocuments = useCallback(
    (values, type) => {
      const files = values.documents
        .filter(doc => !doc.file.id)
        .map(doc => ({ ...doc, type: values.docType }));

      return uploadFiles({
        files,
        url: getUrlWithCompanyId(
          `/contractAgreementClaim/${id}/${type}/${values.id}/document`
        ),
        getBody: file => {
          const formData = new FormData();
          formData.append("file", file.file);
          formData.append("type", file.type);

          return formData;
        }
      });
    },
    [id]
  );

  const uploadSignedDocuments = useCallback(
    values => {
      const files = values.files.map(file => ({
        file: file,
        type: "signed_contract_supplier_document"
      }));

      return uploadFiles({
        files,
        url: getUrlWithCompanyId(
          `/contractAgreementClaim/${agreementClaim.id}/supplier/${agreementClaim.supplier.id}/contract/document/sign`
        ),
        getBody: file => {
          const formData = new FormData();
          formData.append("file", file.file);
          formData.append("type", file.type);

          return formData;
        }
      });
    },
    [agreementClaim]
  );

  const uploadDocumentCopy = useCallback(
    values => {
      const files = values.files.map(file => ({
        file: file,
        type: "document_copy"
      }));

      return uploadFiles({
        files,
        url: getUrlWithCompanyId(
          `/contractAgreementClaim/${agreementClaim.id}/supplier/${agreementClaim.supplier.id}/document-copy`
        ),
        getBody: file => {
          const formData = new FormData();
          formData.append("file", file.file);
          formData.append("type", file.type);

          return formData;
        }
      });
    },
    [agreementClaim]
  );

  // todo rename
  const deleteDocument = useCallback(({ customerId, documentId }, type) => {
    setTimeout(() => reloadAgreementClaim(), 100);
    return getUrlWithCompanyId(
      `/contractAgreementClaim/${id}/${type}/${customerId}/document/${documentId}`
    );
    return deleteRequest(
      getUrlWithCompanyId(
        `/contractAgreementClaim/${id}/${type}/${customerId}/document/${documentId}`
      )
    );
  }, []);

  const downloadDocument = useCallback(document => {
    downloadHook(`/document/${document.id}/download`, document.originalName);
  }, []);

  const downloadContract = useCallback(document => {
    fileFetch(
      getUrlWithCompanyId(`/contractAgreementClaim/${id}/contract/document`)
    ).then(response => dnload(response, "szerzodes.zip"));
  }, []);

  const downloadCalculation = useCallback(
    document => {
      downloadHook(
        getUrlWithCompanyId(
          `/contractAgreementClaim/${id}/download/calculation`
        ),
        "kalkuláció.pdf"
      );
    },
    [id]
  );

  const recall = useCallback(() => {
    return post(
      getUrlWithCompanyId(`/contractAgreementClaim/${id}/requestRecall`)
    );
  }, [id]);

  const finalAccept = useCallback(() => {
    return post(
      getUrlWithCompanyId(
        `/contractAgreementClaim/${id}/calculation/finalAccept`
      )
    );
  }, []);

  const withRefresh = useCallback(
    fn => async (...args) => {
      let retValue;
      if (fn) {
        retValue = await fn(...args);
      }
      await reloadAgreementClaim();
      return retValue;
    },
    [reloadAgreementClaim]
  );

  const loading =
    postLoading ||
    deleteLoading ||
    downloading ||
    agreementClaimLoading ||
    documentTypesLoading;

  const primaryDocumentTypes = transformDocumentTypes(documentTypes?.primary);
  const secondaryDocumentTypes = transformDocumentTypes(
    documentTypes?.secondary
  );
  const customerAgreementClaims = agreementClaim?.customers || [];
  const supplierAgreementClaims = agreementClaim && [agreementClaim.supplier];

  let allFilesUploaded = false;

  if (
    primaryDocumentTypes &&
    secondaryDocumentTypes &&
    customerAgreementClaims &&
    supplierAgreementClaims
  ) {
    const [supplierDocs, customerDocs] = [
      supplierAgreementClaims,
      customerAgreementClaims
    ].map(item =>
      item.reduce((acc, item) => {
        acc[item.id] = item.documents.reduce((acc, doc) => {
          if (!acc.includes(doc.type)) {
            acc.push(doc.type);
          }

          return acc;
        }, []);

        return acc;
      }, {})
    );

    const supplierDocumentsUploaded = Object.values(supplierDocs)
      .map(item => {
        return arrayEqualsIgnoreOrder(
          item,
          primaryDocumentTypes.map(item => item.type)
        );
      })
      .every(done => done === true);

    const customerDocumentsUploaded = Object.values(customerDocs)
      .map(item => {
        return arrayEqualsIgnoreOrder(
          item,
          secondaryDocumentTypes.map(item => item.type)
        );
      })
      .every(done => done === true);

    allFilesUploaded = supplierDocumentsUploaded && customerDocumentsUploaded;
  }
  return useMemo(
    () => ({
      agreementClaim,
      reloadAgreementClaim: withRefresh(reloadAgreementClaim),
      uploadDocuments: withRefresh(uploadDocuments),
      uploadSignedDocuments: withRefresh(uploadSignedDocuments),
      uploadDocumentCopy: withRefresh(uploadDocumentCopy),
      deleteDocument: deleteDocument,
      recall: withRefresh(recall),
      finalAccept: withRefresh(finalAccept),
      downloadDocument,
      downloadContract,
      downloadCalculation,
      sendCalculation,
      customerAgreementClaims,
      supplierAgreementClaims,
      primaryDocumentTypes,
      secondaryDocumentTypes,
      loading,
      allFilesUploaded
    }),
    [
      agreementClaim,
      reloadAgreementClaim,
      documentTypes,
      uploadDocuments,
      uploadSignedDocuments,
      sendCalculation,
      downloadCalculation,
      loading
    ]
  );
};

export const useExternalSalesNewContractPageData = ({
  token,
  id,
  companyId
}) => {
  const [post, postLoading] = useMutator("POST");
  const [deleteRequest, deleteLoading] = useMutator("DELETE");
  const fileFetch = useFetch({ token: getStoredToken(), blob: true });

  const authHeaderOption = {
    headers: {
      Authorization: token
    }
  };
  const [downloadHook, downloading] = useFileDownloader();
  const uploadFiles = useUploadFiles();

  const guestFetch = useCallback(
    (url, opt = { method: "GET" }) =>
      fetch(url, {
        ...authHeaderOption,
        method: opt.method,
        body: opt.body
      }).then(async resp => {
        const json = await resp.json();

        if (!resp.ok) {
          throw new Error(selectErrorNotificationMessage(json).text);
        }

        return json;
      }),
    []
  );

  const getUrl = getFlexipayUrl;
  const fetcher = undefined;
  const swrOptions = fetcher && { fetcher };

  const {
    data: agreementClaim,
    isValidating: agreementClaimLoading,
    revalidate: reloadAgreementClaim
  } = useSWR(
    id &&
      getUrl(
        `/external-sales/company/${companyId}/contractAgreementClaim/${id}`
      ),
    swrOptions
  );

  const { data: documentTypes, isValidating: documentTypesLoading } = useSWR(
    getUrl(`/company/${companyId}/contractAgreementClaim/document/details`),
    swrOptions
  );

  const transformDocumentTypes = docTypes => {
    return (
      docTypes &&
      Object.keys(docTypes).reduce((acc, key) => {
        const docType = docTypes[key];
        acc.push({
          type: key,
          title: docType.title,
          info: docType.description,
          sampleFile: docType.sample
        });

        return acc;
      }, [])
    );
  };

  const uploadDocuments = useCallback(
    (values, type) => {
      const files = values.documents
        .filter(doc => !doc.file.id)
        .map(doc => ({ ...doc, type: values.docType }));

      return uploadFiles({
        files,
        url: getUrl(
          `/company/${companyId}/contractAgreementClaim/${id}/${type}/${values.id}/document`
        ),
        getBody: file => {
          const formData = new FormData();
          formData.append("file", file.file);
          formData.append("type", file.type);

          return formData;
        }
      });
    },
    [id]
  );

  // todo rename
  const deleteDocument = useCallback(({ customerId, documentId }, type) => {
    setTimeout(() => reloadAgreementClaim(), 100);
    return getUrl(
      `/company/${companyId}/contractAgreementClaim/${id}/${type}/${customerId}/document/${documentId}`
    );
    return deleteRequest(
      getUrl(
        `/company/${companyId}/external-sales/contractAgreementClaim/${id}/${type}/${customerId}/document/${documentId}`
      )
    );
  }, []);

  const downloadDocument = useCallback(document => {
    downloadHook(
      `/company/${companyId}/document/${document.id}/download`,
      document.contentUrl
    );
  }, []);

  const downloadContract = useCallback(document => {
    fileFetch(
      getUrl(
        `/company/${companyId}/contractAgreementClaim/${id}/contract/document`
      )
    ).then(response => dnload(response, "szerzodes.zip"));
  }, []);

  const recall = useCallback(() => {
    return post(
      getUrl(`/external-sales/contractAgreementClaim/${id}/requestRecall`)
    );
  }, []);

  const finalAccept = useCallback(() => {
    return post(
      getUrl(
        `/external-sales/contractAgreementClaim/${id}/calculation/finalAccept`
      )
    );
  }, []);

  const withRefresh = useCallback(
    fn => async (...args) => {
      let retValue;
      if (fn) {
        retValue = await fn(...args);
      }
      await reloadAgreementClaim();
      return retValue;
    },
    [reloadAgreementClaim]
  );

  const loading =
    postLoading ||
    deleteLoading ||
    downloading ||
    agreementClaimLoading ||
    documentTypesLoading;

  const primaryDocumentTypes = transformDocumentTypes(documentTypes?.supplier);
  const secondaryDocumentTypes = transformDocumentTypes(
    documentTypes?.customer
  );
  const customerAgreementClaims =
    agreementClaim?.customers?.length && agreementClaim.customers;
  const supplierAgreementClaims = agreementClaim && [agreementClaim.supplier];

  let allFilesUploaded = false;

  if (
    primaryDocumentTypes &&
    secondaryDocumentTypes &&
    customerAgreementClaims &&
    supplierAgreementClaims
  ) {
    const [supplierDocs, customerDocs] = [
      supplierAgreementClaims,
      customerAgreementClaims
    ].map(item =>
      item.reduce((acc, item) => {
        acc[item.id] = item.documents.reduce((acc, doc) => {
          if (!acc.includes(doc.type)) {
            acc.push(doc.type);
          }

          return acc;
        }, []);

        return acc;
      }, {})
    );

    const supplierDocumentsUploaded = Object.values(supplierDocs)
      .map(item => {
        return arrayEqualsIgnoreOrder(
          item,
          primaryDocumentTypes.map(item => item.type)
        );
      })
      .every(done => done === true);

    const customerDocumentsUploaded = Object.values(customerDocs)
      .map(item => {
        return arrayEqualsIgnoreOrder(
          item,
          secondaryDocumentTypes.map(item => item.type)
        );
      })
      .every(done => done === true);

    allFilesUploaded = supplierDocumentsUploaded && customerDocumentsUploaded;
  }

  return useMemo(
    () => ({
      agreementClaim,
      reloadAgreementClaim: withRefresh(reloadAgreementClaim),
      uploadDocuments: withRefresh(uploadDocuments),
      deleteDocument: deleteDocument,
      recall: withRefresh(recall),
      finalAccept: withRefresh(finalAccept),
      downloadDocument,
      downloadContract,
      customerAgreementClaims,
      supplierAgreementClaims,
      primaryDocumentTypes,
      secondaryDocumentTypes,
      loading,
      allFilesUploaded,
      agreementClaimLoading
    }),
    [
      agreementClaim,
      reloadAgreementClaim,
      documentTypes,
      uploadDocuments,
      loading,
      agreementClaimLoading
    ]
  );
};
