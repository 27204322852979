import { useConfirmDialog } from "../../hooks/useConfirmation";
import FlexiDialog from "@bit/flexibill.flexi.flexi-dialog";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import React from "react";
import Grid from "@material-ui/core/Grid";

const ConfirmDialog = () => {
  const [confirmState, confirmActions] = useConfirmDialog();

  return (
    <FlexiDialog
      open={Boolean(confirmState.fn)}
      onClose={confirmActions.hideConfirmDialog}
      title={confirmState.dialogTitle}
      dialogActions={
        <Grid container justify={"space-between"} spacing={2}>
          <Grid item>
            <Button onClick={confirmActions.hideConfirmDialog} outlined>
              Nem
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => {
                confirmState.fn(...confirmState.args);
                confirmActions.hideConfirmDialog();
              }}
            >
              Igen
            </Button>
          </Grid>
        </Grid>
      }
    >
      <Typography>{confirmState.dialogDescription}</Typography>
    </FlexiDialog>
  );
};

export default ConfirmDialog;
