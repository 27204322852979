import FlexiDialog from "@bit/flexibill.flexi.flexi-dialog";
import React, { useContext, useState } from "react";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import Grid from "@material-ui/core/Grid";
import { fetcher } from "../../utils/fetcher";

function forEachAsync(arr, fn) {
  return arr.reduce(function(promise, el, i) {
    return promise.then(function() {
      return fn(el, i);
    });
  }, Promise.resolve());
}

const FileUploaderContext = React.createContext({});

const FileUploadDialog = ({
  open,
  currentFile,
  uploadingIndex,
  filesLength
}) => {
  return (
    <FlexiDialog open={open} title={"Fájl feltöltés"}>
      <Typography>
        Kérlek, ne hagyd el az oldalt, mert a feltöltés megszakad!
        <br />
        <br />
        <b>{filesLength} fájl feltöltése</b>
        <br />
      </Typography>
      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        style={{ gap: 8 }}
      >
        <Grid item style={{ flexGrow: 1 }}>
          <LinearProgress
            variant="determinate"
            value={(uploadingIndex / filesLength) * 100}
            style={{ height: 10 }}
          />
        </Grid>
        <Grid item>
          <Typography>
            {Math.round((uploadingIndex / filesLength) * 100)} %
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography align={"center"}>{currentFile}</Typography>
        </Grid>
      </Grid>
    </FlexiDialog>
  );
};

const FlexiFileUploaderProvider = ({ children, token }) => {
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [uploadingIndex, setUploadingIndex] = useState(null);

  const uploadingFile = filesToUpload[uploadingIndex];
  const uploading = Boolean(uploadingFile);

  const uploadFile = ({ file, url, getBody }) => {
    const body = getBody(file);
    return fetcher(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`
      },
      body
    });
  };

  const uploadFiles = ({ files, url, getBody }) => {
    setFilesToUpload(files);

    return forEachAsync(files, async (file, i) => {
      setUploadingIndex(i);
      return await uploadFile({ file, url, getBody });
    }).then(data => {
      setUploadingIndex(null);
    });
  };

  return (
    <FileUploaderContext.Provider value={{ uploadFiles }}>
      {children}
      <FileUploadDialog
        open={uploading}
        currentFile={uploadingFile?.file.name}
        uploadingIndex={uploadingIndex}
        filesLength={filesToUpload.length}
      />
    </FileUploaderContext.Provider>
  );
};

export const useUploadFiles = () => {
  const ctx = useContext(FileUploaderContext);

  return ctx.uploadFiles;
};

export default FlexiFileUploaderProvider;
