import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  isWidthUp,
  makeStyles,
  Paper,
  Step,
  StepLabel,
  Stepper
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import stepperBill from "../../images/redesignImages/bill-desk-laptop.svg";
import FlexiStepper from "@bit/flexibill.flexi.flexi-stepper";
import React, { useMemo, useState } from "react";
import {
  useDashboardPageData,
  useInvoiceStats,
  useProfileData
} from "../../hooks/useApi";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";

const getInvoiceStatusCount = (invoices = [], status) => {
  let result = (invoices || []).reduce((sum, invoice) => {
    if (invoice.status === status) {
      sum++;
    }
    return sum;
  }, 0);
  return result;
};

const useStepperClasses = makeStyles({
  root: {
    "& .MuiStepLabel-label": {
      display: "none"
    }
  }
});

const DashboardStepper = ({ desktop }) => {
  const { szamlazzHu } = useProfileData();
  const { invoices } = useDashboardPageData();
  const [stepperOpen, setStepperOpen] = useState(false);
  const { push: redirect } = useHistory();
  const [activeStep, setActiveStep] = useState(0);
  const getStepContent = (step, data) => {
    if (data) {
      return data[step].description;
    }
    return "-";
  };
  const stepperClasses = useStepperClasses();

  const stepperdata = useMemo(
    () => [
      {
        step: "Számlák feltöltése",
        description: szamlazzHu
          ? "Faktorálható számláidat automatikusan áttöltjük a Számlázz.hu rendszeréből."
          : "A Számlák menüpontban feltöltheted a faktorálni kívánt számlákat. Amennyiben szükséges, a feltöltés során tudsz számlaképet és teljesítés igazolást csatolni a számlához.",
        url: "/app/invoice/new"
      },
      {
        step: "Jelölés faktorálásra",
        description:
          "A Számlák listában kijelölt számlákat a “Faktorálás” gombra kattintva tudod elküldeni faktorálásra. Itt még lehetőséged van csatolni az esetlegesen hiányzó dokumentumokat. A számlák benyújtása után elkészülnek a faktorálási értesítők, amiket alá kell írnod. Minden vevőhöz külön értesítő tartozik, kérlek bizonyosodj meg róla, hogy mindegyiket aláírtad!",
        url: "/app/invoice"
      },
      {
        step: "Számlaellenőrzés",
        description:
          "Sikeresen jelölted számláidat faktorálásra, jelenleg nincs teendőd! Ellenőrizzük, hogy megvannak-e a szükséges dokumentumok és elküldjük a faktorálási értesítőt a vevődnek visszaigazolásra. A Számlák menüpont alatti “Faktorálásra benyújtott” listában nyomon követheted, hol tart a folyamat.",
        url: "/app/invoice"
      },
      {
        step: "Folyósítás",
        description:
          "A vevői visszaigazolás megtörtént és mindent rendben találtunk. Néhány napon belül a számládra kerül a finanszírozott összeg!",
        url: "/app/invoice"
      }
    ],
    [szamlazzHu]
  );

  const data = stepperdata.map(item => ({
    ...item,
    count: getInvoiceStatusCount(invoices, item.status),
    onClick: () => redirect(`/app/invoice/${item.status}`)
  }));

  return (
    <Accordion
      style={{
        // backgroundImage: `url(${stepperBill})`,
        position: "relative"
      }}
      expanded={stepperOpen}
      onChange={(e, open) => setStepperOpen(open)}
    >
      <AccordionSummary
        style={{ position: "initial" }}
        expandIcon={<ExpandMoreIcon />}
      >
        <div>
          <Typography variant={"h5"}>
            Üdvözöllek a Digitális Faktoring világában!
          </Typography>
          <br />
          {!stepperOpen && (
            <Typography>
              Kattints és nyisd le ezt az ablakot, hadd vezesselek körbe!
            </Typography>
          )}
        </div>
        {desktop && (
          <img
            src={stepperBill}
            style={{
              position: "absolute",
              bottom: 0,
              right: "100px",
              transition: "width ease 0.3s",
              width: stepperOpen ? "350px" : "200px"
            }}
          />
        )}
      </AccordionSummary>
      <AccordionDetails>
        <Grid xs={12} sm={6}>
          <Paper elevation={0}>
            <Stepper
              alternativeLabel={true}
              activeStep={activeStep}
              classes={stepperClasses}
              // orientation={"vertical"}
            >
              {data.map(step => {
                const props = {};
                const labelProps = {};
                return (
                  <Step key={step.step} {...props}>
                    <StepLabel {...labelProps}>{step.step}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <div>
              {activeStep === data.length && (
                <Button
                  style={{ marginTop: "118px" }}
                  onClick={() => setActiveStep(0)}
                >
                  Újra
                </Button>
              )}
              <div>
                <Typography variant={"h5"}>{data[activeStep].step}</Typography>
                <Typography style={{ minHeight: "108px" }}>
                  {getStepContent(activeStep, data)}
                </Typography>
                <Box display={"flex"} style={{ gap: "0.5rem" }}>
                  <Button
                    style={{
                      marginRight: "20px",
                      marginTop: "20px"
                    }}
                    disabled={activeStep === 0}
                    onClick={() => setActiveStep(activeStep - 1)}
                  >
                    Vissza
                  </Button>
                  <Button
                    style={{
                      marginTop: "20px"
                    }}
                    onClick={() =>
                      setActiveStep(
                        activeStep === data.length - 1 ? 0 : activeStep + 1
                      )
                    }
                  >
                    {activeStep === data.length - 1 ? "Kész" : "Következő"}
                  </Button>
                </Box>
              </div>
            </div>
          </Paper>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default DashboardStepper;
