export const required = value =>
  value === 0 || value ? undefined : "A mező kitöltése kötelező!";

export const requiredWithCustomMessage = message => value =>
  value === 0 || value ? undefined : message;

export const email = value =>
  value
    ? /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value
      )
      ? undefined
      : "Helytelen email cím!"
    : undefined;

export const number = value =>
  value ? (/[0-9]/.test(value) ? undefined : "Nem szám!") : undefined;

export const minLength = length => value =>
  // eslint-disable-next-line no-nested-ternary
  value
    ? value.length >= length
      ? undefined
      : `Legalább ${length} karakter!`
    : undefined;

export const maxNum = max => value => {
  const maxNumber = parseFloat(max);
  const valueNumber = parseFloat(value);

  if (valueNumber > maxNumber) {
    return `Nem lehet nagyobb mint: ${max}`;
  } else {
    return undefined;
  }
};

export const taxNumber = value =>
  value
    ? /[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{4}[0-9]{7}([a-zA-Z0-9]?){0,16}/.test(
        value
      )
      ? undefined
      : "Hibás adószám!"
    : undefined;

export const composeValidators = (...validators) => value =>
  validators
    .filter(validator => validator)
    .reduce((error, validator) => error || validator(value), undefined);
