import { createStore } from "react-sweet-state";

const initialState = {
  popups: {}
};

const makeSetOpen = open => popupName => ({ getState, setState }) => {
  const state = getState();
  setState({
    ...state,
    popups: { ...state.popups, [popupName]: { open } }
  });
};

const popupStore = createStore({
  initialState,
  actions: {
    showPopup: makeSetOpen(true),
    hidePopup: makeSetOpen(false),
    togglePopup: popupName => ({ getState, setState }) => {
      const state = getState();
      const currentState = state.popups[popupName];
      const currentOpen = Boolean(currentState && currentState.open);
      const setOpen = makeSetOpen(!currentOpen);
      setOpen(popupName)({ getState, setState });
    }
  },
  name: "popup"
});

export default popupStore;
