const selectFieldErrorsFromResponse = ({ response }) => {
  let errors = null;

  if (response && response.errors && response.errors.fields) {
    errors = response.errors.fields;
  }

  if (response && response.errors && response.errors.children) {
    errors = Object.keys(response.errors.children).reduce((acc, fieldName) => {
      if (response.errors.children[fieldName].errors) {
        return {
          ...acc,
          [fieldName]: response.errors.children[fieldName].errors[0]
        };
      }

      return acc;
    }, {});
  }

  return errors;
};

export default selectFieldErrorsFromResponse;
