import PageLayout from "../../components/PageLayout";
import React from "react";
import ContractList from "./ContractList";
import { useAgreements, useMutator } from "../../hooks/useApi";
import { defineMessages } from "react-intl.macro";
import { getUrlWithCompanyId } from "../../utils/urlHelpers";
import Typography from "@material-ui/core/Typography";
import faktorSvg from "../../images/onboarding_faktszerz.svg";
import Grid from "@material-ui/core/Grid";
import { useIntl } from "react-intl";

const messages = defineMessages({
  pageTitle: {
    id: "pageTitle.factorContractPage",
    defaultMessage: "Szerződések"
  },
  noContractsTitle: {
    id: "noContractsTitle.factorContractPage",
    defaultMessage: "Jelenleg nem rendelkezik faktorszerződéssel"
  },
  noContractsText: {
    id: "noContractsTitle.factorContractPage",
    defaultMessage:
      "Itt találhatja meg vevőnként a felhasználható keretet, illetve kérheti a limitek módosítását."
  }
});

const FactorContractsPage = () => {
  const { data: agreements, isValidating: loading } = useAgreements();
  const intl = useIntl();
  const showNoData = !loading && !Boolean(agreements?.length);
  const showList = !loading && Boolean(agreements?.length);
  return (
    <PageLayout
      insidePage
      pay
      titleMessage={messages.pageTitle}
      loading={loading}
    >
      <ContractList />
    </PageLayout>
  );
};

export default FactorContractsPage;
