import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import FlexiDialog from "@bit/flexibill.flexi.flexi-dialog";
import { Button, LinearProgress, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import existingImgStep2 from "../../images/guide/existing/javitott_meglevo_szerzigeny1.jpg";
import existingImgStep3 from "../../images/guide/existing/javitott_meglevo_szerzigeny2.jpg";
import existingImgStep4 from "../../images/guide/existing/javitott_meglevo_szerzigeny3.jpg";
import existingImgStep5 from "../../images/guide/existing/javitott_meglevo_dokfeltoltes1.jpg";
import existingImgStep6 from "../../images/guide/existing/javitott_meglevo_dokfeltoltes2.jpg";
import existingImgStep7 from "../../images/guide/existing/javitott_meglevo_dokfeltoltes3.jpg";
import existingImgStep10 from "../../images/guide/existing/javitott_meglevo_szerzkot1.jpg";
import existingImgStep11 from "../../images/guide/existing/javitott_meglevo_szerzkot2.jpg";
import existingImgStep12 from "../../images/guide/existing/javitott_meglevo_szerzkot3.jpg";
import existingImgStep13 from "../../images/guide/existing/javitott_meglevo_fooldal1.jpg";
import existingImgStep14 from "../../images/guide/existing/fooldal2_meglevo.jpg";
import existingImgStep15 from "../../images/guide/existing/javitott_meglevo_fooldal3.jpg";
import existingImgStep16 from "../../images/guide/existing/javitott_meglevo_fooldal4.jpg";
import existingImgStep17 from "../../images/guide/existing/javitott_meglevo_fooldal5.jpg";
import existingImgStep18 from "../../images/guide/existing/javitott_meglevo_szamlak1.jpg";
import existingImgStep20 from "../../images/guide/existing/szamlak2.jpg";
import existingImgStep21 from "../../images/guide/existing/javitott_meglevo_szamlak3.jpg";
import existingImgStep22 from "../../images/guide/existing/javitott_meglevo_szamlak4.jpg";
import existingImgStep23 from "../../images/guide/existing/javitott_meglevo_szamlak5.jpg";
import existingImgStep24 from "../../images/guide/existing/javitott_meglevo_szamlak6.jpg";
import existingImgStep25 from "../../images/guide/existing/javitott_meglevo_szamlak7.jpg";
import existingImgStep27 from "../../images/guide/existing/javitott_meglevo_szamlak8.jpg";
import existingImgStep28 from "../../images/guide/existing/javitott_meglevo_szamlak9.jpg";
import existingImgStep29 from "../../images/guide/existing/javitott_meglevo_faktert.jpg";
import existingImgStep30 from "../../images/guide/existing/javitott_meglevo_szamlacsoport1.jpg";
import existingImgStep31 from "../../images/guide/existing/javitott_meglevo_szamlacsoport2.jpg";
import existingImgStep32 from "../../images/guide/existing/szerzodesek_meglevo.jpg";
import existingImgStep33 from "../../images/guide/existing/limitmodositas_meglevo.jpg";
import existingImgStep34 from "../../images/guide/existing/javitott_meglevo_munkatars1 (1) 1.jpg";
import existingImgStep35 from "../../images/guide/existing/javitott_meglevo_munkatars2.jpg";
import existingImgStep37 from "../../images/guide/existing/javitott_meglevo_nav.jpg";
import existingImgStep38 from "../../images/guide/existing/javitott_meglevo_profil.jpg";

const steps = [
  {
    //1
    title: "Meglévő ügyfeleknek - Szerződés igények",
    subTitle: "Hogyan köthetek új szerződést?",
    desc: (
      <div>
        <ol>
          <li>
            Amennyiben már létrehozta az értékesítő munkatársunk az ajánlatodat,
            a Szerződés igények menüpontban megtekintheted és elfogadhatod. Ha
            még nem rendelkezel ajánlattal, kattints az Új igény gombra és add
            meg a szükséges adatokat. A Kalkulálok gombra kattintva
            megtekintheted és elfogadhatod az előkalkulációt. 
          </li>
          <li>Töltsd fel a szükséges dokumentumokat.</li>
          <li>Kattints a jobb felső sarokban található Tovább gombra.</li>
          <li>
            Ezután megkezdjük az adatok feldolgozását és ellenőrizzük a
            dokumentumokat. Amint a benyújtott igény elbírálásra került,
            e-mailben tájékoztatunk.
          </li>
        </ol>
      </div>
    )
  },
  {
    //2
    title: "Meglévő ügyfeleknek - Szerződés igények",
    subTitle: "Hogyan tekinthetek meg egy meglévő ajánlatot?",
    desc: (
      <div>
        <ol>
          <li>
            A Szerződés igények menüpont Státusz oszlopában látod, hogy melyik
            ajánlatod hol tart a folyamatban.
          </li>
          <li>
            Négy típussal találkozhatsz:
            <ul>
              <li>
                Feladatod van vele - a következő lépést neked kell elvégezned
                ahhoz, hogy folytatódhasson a folyamat.
              </li>
              <li>
                Dokumentum ellenőrzés alatt - a feltöltött dokumentumok meglétét
                és helyességét munkatársunk ellenőrzi.
              </li>
              <li>
                Folyamatban van - a szerződési folyamaton egy munkatársunk
                dolgozik.
              </li>
              <li>
                A folyamat lezárult - a szerződéskötés sikeres, hamarosan
                elérheted a folyósító modul funkcióit is.
              </li>
            </ul>
            <li>
              A részletekhez kattints az adott igényhez tartozó Megtekintés
              gombra.
            </li>
          </li>
        </ol>
        <img style={{ width: "100%" }} src={existingImgStep2} />
      </div>
    )
  },
  {
    //3
    title: "Meglévő ügyfeleknek - Szerződés igények",
    subTitle: "Hogyan tekinthetek meg egy meglévő ajánlatot?",
    desc: (
      <div>
        <ol>
          <li value={4}>
            Az elkészült kalkulációt le is töltheted, vagy elküldheted
            e-mailben. Amennyiben megfelelő az ajánlatunk, elfogadhatod a Tovább
            gombra kattintva. Ha szeretnél tájékozódni az ajánlattal
            kapcsolatban, kattints a Visszahívást kérek gombra és munkatársunk
            keresni fog.
          </li>
        </ol>
        <img style={{ width: "100%" }} src={existingImgStep3} />
      </div>
    )
  },
  {
    //4
    title: "Meglévő ügyfeleknek - Szerződés igények",
    subTitle: "Hogyan kaphatok ajánlatot?",
    desc: (
      <div>
        <img
          style={{ width: "100%", marginTop: "1em" }}
          src={existingImgStep4}
        />
        <ol>
          <li>A Szerződés igények menüpontban kattints az Új igény gombra.</li>
          <li>
            A Vevő neve mezőnél kezdd el gépelni a vállalkozás nevét és válaszd
            ki a céget a{" "}
            <a href={"https://www.dnb.com/hu-hu/"}>Dun & Bradstreet</a>-ből
            betöltött találatok közül. Töltsd ki a többi adatot, majd kattints a
            Kalkulálok! gombra. A jobb oldalon látni fogod a FlexiBill által
            generált kalkulációt
            <ol type={"a"}>
              <li>
                Amennyiben kérdésed merül fel az ajánlattal kapcsolatban,
                kattints a Visszahívást kérek gombra és munkatársunk felveszi
                veled a kapcsolatot
              </li>
              <li>
                Amennyiben megfelelő számodra az ajánlat, kattints a Tovább
                gombra.
              </li>
            </ol>
          </li>
          <li>
            Ezután töltsd fel a faktorszerződéshez szükséges Dokumentumokat.
          </li>
        </ol>
      </div>
    )
  },
  {
    //5
    title: "Meglévő ügyfeleknek - Dokumentum feltöltése",
    subTitle: "Hogyan vonhatom be munkatársamat a Dokumentum feltöltésbe?",
    desc: (
      <div>
        <img
          style={{ width: "100%", marginTop: "1em" }}
          src={existingImgStep5}
        />
        <ol>
          <li>
            A Dokumentumok feltöltése résznél kattints a Hozzáférés küldése
            gombra.
          </li>
          <li>Add meg a hozzáadni kívánt munkatársad e-mail címét.</li>
          <li>Kattints a Küldés gombra.</li>
          <li>
            FONTOS: Munkatársad “Megtekintő és dokumentum feltöltő”
            jogosultságot kap. Hozzáférését a Munkatársak menüpontban bármikor
            módosíthatod!
          </li>
        </ol>
      </div>
    )
  },
  {
    //6
    title: "Meglévő ügyfeleknek - Dokumentum feltöltése",
    subTitle: "Hogyan tölthetem fel a dokumentumokat?",
    desc: (
      <div>
        <ol>
          <li>
            A Dokumentum feltöltése lépésnél, a kiválasztott dokumentum
            dobozában kattints a Feltöltés gombra.
          </li>
        </ol>
        <img style={{ width: "100%" }} src={existingImgStep6} />
        <ol>
          <li value={2}>Kattints a Fájl hozzáadása gombra.</li>
        </ol>
      </div>
    )
  },
  {
    //7
    title: "Meglévő ügyfeleknek - Dokumentum feltöltése",
    subTitle: "Hogyan tölthetem fel a dokumentumokat?",
    desc: (
      <div>
        <ol>
          <li value={3}>
            Válaszd ki a megfelelő fájlt a gépedről (egyszerre több fájlt is
            feltölthetsz).
          </li>
        </ol>
        <img style={{ width: "100%" }} src={existingImgStep7} />
        <ol>
          <li value={4}>Kattints a Mentés gombra.</li>
        </ol>
        Ha minden dokumentumot feltöltöttél, kattints a Tovább gombra.
      </div>
    )
  },
  {
    //8
    title: "Meglévő ügyfeleknek - Dokumentum feltöltése",
    subTitle: "Milyen dokumentumokat kell feltöltenem?",
    desc: (
      <div>
        <Typography variant={"h6"} style={{ marginTop: "1em" }}>
          Online adatlap
        </Typography>
        Az online adatlap egészen rövid, első részében a Te adataidat kérjük
        (pl. hova kéred az utalást?). A további lapon vagy lapokon (több vevő
        esetén) már a vevőd adatait kérjük, akinek számlázol, erre kérlek
        figyelj!inden dokumentumot feltöltöttél, kattints a Tovább gombra.
        <Typography variant={"h6"} style={{ marginTop: "24px" }}>
          Vállalkozási szerződés
        </Typography>
        Kérjük csatold a faktorálni kívánt vevőkkel kötött aktuális szerződés
        vagy megrendelés másolatát. Ha több szerződés van, lehetőség szerint
        mindet kérjük.
        <Typography variant={"h6"} style={{ marginTop: "24px" }}>
          NAV adófolyószámla
        </Typography>
        Te, mint ügyfél (= szállító) cég, 30 napnál nem régebbi dátumra lekért
        adófolyószámla kivonatának másolatot kérjük.
        <Typography variant={"h6"} style={{ marginTop: "24px" }}>
          Főkönyv
        </Typography>
        Az igény beadásától számított 3 hónapnál nem régebbi dátumra szóló
        főkönyvi kivonat másolatát kérjük. Január, február és március hónapban
        beadott igény esetén, kérjük csatold az előző év december 31-re szóló
        főkönyvi kivonat másolatot is.
      </div>
    )
  },
  {
    //9
    title: "Meglévő ügyfeleknek - Dokumentum feltöltése",
    subTitle: "Milyen dokumentumokat kell feltöltenem?",
    desc: (
      <div>
        <Typography variant={"h6"} style={{ marginTop: "1em" }}>
          Vevő analitika
        </Typography>
        Faktorálni kívánt vevőkre vonatkozóan, az igénylés időpontjától
        visszamenőlegesen 1 éves időszakra kérjük, ahol látszik a már kiállított
        számlák száma, összege, eredeti és tényleges fizetési határideje. Új
        vevőkapcsolat esetén annak az időszaknak az analitikáját/számláit
        kérjük, ami rendelkezésre áll.
      </div>
    )
  },
  {
    //10
    title: "Meglévő ügyfeleknek - Szerződéskötés",
    subTitle: "Hogyan írhatom alá a szerződést?",
    desc: (
      <div>
        <img
          style={{ width: "100%", marginTop: "1em", marginBottom: "1em" }}
          src={existingImgStep10}
        />
        Az elkészült szerződésed a FlexiBill felületén bármikor aláírhatod
        digitálisan vagy manuálisan is.
      </div>
    )
  },
  {
    //11
    title: "Meglévő ügyfeleknek - Szerződéskötés",
    subTitle: "Aláírás digitálisan",
    desc: (
      <div style={{ marginTop: "1em" }}>
        A dokumentumot hitelesítheted Digitális aláírás funkciónk segítségével.
        Így nem kell a kinyomtatott és aláírt formátumot feltöltened, mégis
        ugyanúgy hiteles, mintha kézzel írtad volna alá! A felületen
        feltöltheted a Netlock-kal vagy Microsec-kel aláírt szerződést. További
        példányt nem kell eljuttatnod hozzánk.
        <img
          style={{ width: "100%", marginTop: "1em" }}
          src={existingImgStep11}
        />
      </div>
    )
  },
  {
    //12
    title: "Meglévő ügyfeleknek - Szerződéskötés",
    subTitle: "Aláírt fájl feltöltése",
    desc: (
      <div style={{ marginTop: "1em" }}>
        A FlexiBill felületén feltöltheted a letöltött, beszkennelt és általad
        aláírt szerződést. A feltöltés mellett kérjük, juttass el hozzánk két
        példányt személyesen vagy postán (1066 Budapest, Mozsár utca 16.).
        <img
          style={{ width: "100%", marginTop: "1em" }}
          src={existingImgStep12}
        />
      </div>
    )
  },
  {
    //13
    title: "Meglévő ügyfeleknek - Főoldal",
    desc: (
      <div>
        A Főoldal menüpont a FlexiBill nyitóoldala. Felhasználóként a fiókodba
        belépve megtekintheted a számlákkal, keretkihasználtsággal kapcsolatos
        statisztikákat.
        <Typography
          variant={"h6"}
          style={{ marginTop: "1em", marginBottom: "1em" }}
        >
          Gyorsfunkciók
        </Typography>
        Az oldal tetején, a jobb oldalon összeszedtük a számodra fontos
        funkciókat, hogy gyorsabban tudj:
        <ul>
          <li>Számlát feltölteni</li>
          <li>Új vevőre faktorszerződési igényt benyújtani</li>
        </ul>
        <img style={{ width: "100%" }} src={existingImgStep13} />
      </div>
    )
  },
  {
    //14
    title: "Meglévő ügyfeleknek - Főoldal",
    subTitle: "Keretkihasználtság áttekintése",
    desc: (
      <div>
        <img
          style={{ width: "100%", marginTop: "1em", marginBottom: "1em" }}
          src={existingImgStep14}
        />
        A Keretkihasználtság áttekintése blokkban fektetett oszlopdiagramok
        tájékoztatnak, hogy mekkora az adott előleg összege az eddig benyújtott,
        faktorált számláknak és a beadott számlákra még mekkora előleg hívható
        le. A keretkihasználtság külön-külön vevőnként is látszik, így rögtön
        kapsz egy képet arról, melyik partner számláira hívhatsz le előleget.
      </div>
    )
  },
  {
    //15
    title: "Meglévő ügyfeleknek - Főoldal",
    subTitle: "Számla státuszok",
    desc: (
      <div>
        <img
          style={{ width: "100%", marginTop: "1em", marginBottom: "1em" }}
          src={existingImgStep15}
        />
        Itt láthatod a
        <ul>
          <li>Faktorálható számláid (db)</li>
          <li>Folyósítható számláid (db)</li>
          <li>Faktorált számláid (db)</li>
        </ul>
      </div>
    )
  },
  {
    //16
    title: "Meglévő ügyfeleknek - Főoldal",
    subTitle: "Nyitott számlák",
    desc: (
      <div style={{ marginTop: "1em" }}>
        A Nyitott számlák kördiagram reprezentálja a faktorált számlák közül
        azokat, amelyek lejártak vagy hamarosan lejárnak. Itt kapsz egy átfogó
        képet arról, hogy a faktorált számlák hány napon belül lesznek lejárt
        követelések.
        <img
          style={{ width: "100%", marginTop: "1em" }}
          src={existingImgStep16}
        />
      </div>
    )
  },
  {
    //16
    title: "Meglévő ügyfeleknek - Főoldal",
    subTitle: "Nyitott számlák",
    desc: (
      <div style={{ marginTop: "1em" }}>
        A Nyitott számlák kördiagram reprezentálja a faktorált számlák közül
        azokat, amelyek lejártak vagy hamarosan lejárnak. Itt kapsz egy átfogó
        képet arról, hogy a faktorált számlák hány napon belül lesznek lejárt
        követelések.
        <img
          style={{ width: "100%", marginTop: "1em" }}
          src={existingImgStep16}
        />
      </div>
    )
  },
  {
    //17
    title: "Meglévő ügyfeleknek - Főoldal",
    subTitle: "Folyamatban lévő szerződés igények",
    desc: (
      <div style={{ marginTop: "1em" }}>
        Áttekintést adunk a folyamatban lévő faktorálási folyamataidról, továbbá
        megjelenítjük az aktuális szerződés igények státuszát.
        <img
          style={{ width: "100%", marginTop: "1em" }}
          src={existingImgStep17}
        />
      </div>
    )
  },
  {
    //18
    title: "Meglévő ügyfeleknek - Számlák",
    desc: (
      <div>
        A Számlák menüpontban találhatóak a felhasználó számlái listákba
        rendezve, annak megfelelően, hogy éppen milyen státuszban vannak.
        <img
          style={{ width: "100%", marginTop: "1em" }}
          src={existingImgStep18}
        />
      </div>
    )
  },
  {
    //19
    title: "Meglévő ügyfeleknek - Számlák",
    desc: (
      <div>
        A számlákat három listába szedtük, hogy lásd, hol tartanak a
        folyamatban:
        <ul>
          <li>
            <b>
              <i>Faktorálható számlák</i>
            </b>{" "}
            - itt jelennek meg a feltöltött számlák. Jelöld ki a benyújtandó
            számláidat és kattints a Faktorálás gombra.
          </li>
          <li>
            <b>
              <i>Folyósításra váró számlák</i>
            </b>{" "}
            - itt jelennek meg azok a számlák, amiket már benyújtottál
            faktorálásra. Ezeknek a státusza lehet:
            <ul>
              <li>
                Szállító aláírására vár - legenerálódott a faktorálási értesítő
                és már csak az aláírásodra van szükség, hogy folytatódjon a
                folyamat.
              </li>
              <li>
                Vevő visszaigazolására vár - A faktorálandó számlák továbbításra
                kerültek a vevőd felé, visszaigazolására várnak.
              </li>
              <li>
                Vevő aláírására vár - a vevőnek kell aláírni a faktorálási
                értesítő tudomásul vételét. Amint ez megtörtént, a folyósítási
                folyamat elindul.
              </li>
              <li>
                Feldolgozás alatt - Munkatársaink dolgoznak az ügyleten,
                ellenőrzik a dokumentumok helyességét és meglétét.
              </li>
              <li>Faktorált - A folyósítás folyamatban van.</li>
              <li>
                Lezárt - Az ügyletet munkatársunk lezárta. Amennyiben kérdésed
                van, vedd fel velünk a kapcsolatot.
              </li>
            </ul>
          </li>
          <li>
            <b>
              <i>Faktorált számlák</i>
            </b>
            - Ide azok a számlák kerülnek, amelyeknél teljesült a folyósítás.
          </li>
        </ul>
      </div>
    )
  },
  {
    //20
    title: "Meglévő ügyfeleknek - Számla feltöltése",
    subTitle: "Hogyan tölthetek fel számlát?",
    desc: (
      <div>
        <ol>
          <li>Kattints a Számlák menüpontra.</li>
          <li>Jobb oldalon kattints az Új számlák feltöltése gombra.</li>
        </ol>
        <img style={{ width: "100%" }} src={existingImgStep20} />
      </div>
    )
  },
  {
    //21
    title: "Meglévő ügyfeleknek - Számla feltöltése",
    subTitle: "Hogyan tölthetek fel számlát?",
    desc: (
      <div>
        <ol>
          <li value={3}>Válaszd ki az Egyénileg/kézzel opciót.</li>
        </ol>
        <img style={{ width: "100%" }} src={existingImgStep21} />
      </div>
    )
  },
  {
    //22
    title: "Meglévő ügyfeleknek - Számla feltöltése",
    subTitle: "Hogyan tölthetek fel számlát?",
    desc: (
      <div>
        <ol>
          <li value={4}>
            Válaszd ki a vevő nevét és töltsd ki a számla adatait.
          </li>
        </ol>
        <img style={{ width: "100%" }} src={existingImgStep22} />
        <ol>
          <li value={5}>Töltsd fel a szükséges fájlokat. </li>
          <li value={6}>Kattints a Mentés gombra</li>
        </ol>
      </div>
    )
  },
  {
    //23
    title: "Meglévő ügyfeleknek - Számla feltöltése",
    subTitle:
      "Hogyan tölthetek fel egyszerre több számlát és hogyan csatolhatok hozzájuk fájlt a számlák rögzítésekor?",
    desc: (
      <div>
        <ol>
          <li>Kattints a Számlák menüpontra</li>{" "}
          <li>Jobb oldalon kattints az Új számlák feltöltése gombra.</li>{" "}
          <li>Válaszd ki a Csoportosan opciót.</li>
        </ol>
        <img style={{ width: "100%" }} src={existingImgStep23} />
      </div>
    )
  },
  {
    //24
    title: "Meglévő ügyfeleknek - Számla feltöltése",
    subTitle:
      "Hogyan tölthetek fel egyszerre több számlát és hogyan csatolhatok hozzájuk fájlt a számlák rögzítésekor?",
    desc: (
      <div>
        <ol start={4}>
          <li>
            Töltsd fel a fájlt (XLSX formátumban), ami tartalmazza számlák
            adatait.
          </li>
          <li>Válaszd ki a vevőd, akinek a számláit szeretnéd feltölteni.</li>
        </ol>
        <img style={{ width: "100%" }} src={existingImgStep24} />
        <ol start={6}>
          <li>
            Jelöld ki a számlákat és kattints a Fájl csatolása a számlákhoz
            gombra.
          </li>
          <li>
            Válaszd ki a számlákhoz tartozó fájlokat a Fájl hozzáadása gombra
            kattintva.
          </li>
          <li>Válaszd ki a legördülő listából a Dokumentum típusát.</li>
          <li>Kattints a Feltöltés gombra.</li>
        </ol>
      </div>
    )
  },
  {
    //25
    title: "Meglévő ügyfeleknek - Számla feltöltése",
    subTitle:
      "Hogyan tölthetek fel egyszerre több számlát és hogyan csatolhatok hozzájuk fájlt a számlák rögzítésekor?",
    desc: (
      <div style={{ marginTop: "1em" }}>
        A kijelölt számlákat a Kijelölt számlák feltöltése gombra kattintva
        tudod a rendszerbe feltölteni.
        <img
          style={{ width: "100%", marginTop: "1em" }}
          src={existingImgStep25}
        />
      </div>
    )
  },
  {
    //26
    title: "Meglévő ügyfeleknek - Fájl csatolása",
    subTitle: "Hogyan tudok fájlt csatolni a faktorálás indításakor?",
    desc: (
      <div>
        <ol>
          <li>Kattints a Számlák menüpontra.</li>
          <li>Válaszd ki a faktorálni kívánt számlákat.</li>
          <li>Kattints a Faktorálás gombra.</li>
          <li>
            A felugró Hiányzó dokumentumok ablakban válaszd ki a megfelelő
            dokumentum típust.
          </li>
          <li>
            A Fájl hozzáadása gombra kattintva válaszd ki a feltöltendő
            dokumentumot, majd kattints a Feltöltés gombra.
          </li>
          <li>
            A dokumentumok feltöltése után a számla faktorálásának folyamata
            automatikusan elindul.
          </li>
        </ol>
      </div>
    )
  },
  {
    //27
    title: "Meglévő ügyfeleknek - Számlák szerkesztése",
    subTitle: "Hogyan szerkeszthetem a már feltöltött számlámat?",
    desc: (
      <div>
        <ol>
          <li>Kattints a Számlák menüpontra.</li>
          <li>
            A Faktorálható számlák közül válaszd ki, hogy melyiket szeretnéd
            szerkeszteni.
          </li>
          <li>
            A sor jobb oldalán található gombra kattintva válaszd a
            Szerkesztést.
          </li>
        </ol>
        <img style={{ width: "100%" }} src={existingImgStep27} />
        <ol start={4}>
          <li>
            Szerkeszd a számla adatait (ha szükséges, töltsd fel a hiányzó
            fájlokat).
          </li>
          <li>Végezetül kattints a Mentés gombra.</li>
        </ol>
      </div>
    )
  },
  {
    //28
    title: "Meglévő ügyfeleknek - Számlák faktorálása",
    subTitle: "Hogyan tudom faktorálni a  számláimat?",
    desc: (
      <div>
        <ol>
          <li>
            Válaszd ki azokat a számlákat, amiket szeretnél faktorálásra
            benyújtani.
          </li>
          <li>Kattints a lista fölött lévő Faktorálás gombra.</li>
        </ol>
        <img style={{ width: "100%" }} src={existingImgStep28} />
        <ol start={3}>
          <li>
            Rendszerünk jelzi feléd, ha a faktorálashoz dokumentum feltöltése
            szükséges, ezt a felugró ablakban lévő feltöltés ikonra kattintva
            csatolhatod.
          </li>
        </ol>
      </div>
    )
  },
  {
    //29
    title: "Meglévő ügyfeleknek - Faktorálási értesítő",
    subTitle: "Hogy írhatom alá a faktorálási értesítőt?",
    desc: (
      <div>
        <ol>
          <li>Töltsd le az eredeti fájlt a Letöltés gombra kattintva.</li>
          <li>
            Nyomtasd ki, írd alá és scanneld/fotózd be az aláírt formátumot.
          </li>
          <li>
            Az Aláírt fájl feltöltése gombra kattintva tudod visszatölteni az
            oldalra.
          </li>
        </ol>
        <img style={{ width: "100%" }} src={existingImgStep29} />
      </div>
    )
  },
  {
    //30
    title: "Meglévő ügyfeleknek - Számlacsoportok",
    subTitle: "Mik a számlacsoportok?",
    desc: (
      <div style={{ marginTop: "1em" }}>
        A számlacsoportot azok a számlák alkotják, amiket egyszerre, egy
        gombnyomással nyújtottál be faktorálásra. Gondolhatsz rá úgy is, mint
        egy sorszámra, amivel a lehető legegyszerűbb az ügylet beazonosítása.
        Ezt az azonosítót rendszerünk automatikusan generálja.
        <img
          style={{ width: "100%", marginTop: "1em" }}
          src={existingImgStep30}
        />
      </div>
    )
  },
  {
    //31
    title: "Meglévő ügyfeleknek - Számlacsoportok",
    subTitle: "A számlákat továbbra is meg tudom tekinteni?",
    desc: (
      <div style={{ marginTop: "1em" }}>
        Természetesen! A listában lévő Megtekintés gombra kattintva továbbra is
        láthatod az általad rögzített számlák adatait. Ha a vevődnek is vissza
        kell igazolni a számlákat, azt is nyomon követheted, hogy ez
        megtörtént-e.
        <img
          style={{ width: "100%", marginTop: "1em" }}
          src={existingImgStep31}
        />
      </div>
    )
  },
  {
    //31
    title: "Meglévő ügyfeleknek - Faktorált számlák",
    desc: (
      <div>
        <img
          style={{ width: "100%", marginTop: "1em", marginBottom: "1em" }}
          src={existingImgStep31}
        />
        <b>
          <i>Vevő neve:</i>
        </b>{" "}
        faktorszerződés alapján, az ügylet kötelezettje.
        <br />
        <b>
          <i>Számlacsoport:</i>
        </b>{" "}
        vevőnként mutatja az egyszerre faktorálásra kijelölt számlákat.
        <br />
        <b>
          <i>Számlák összege:</i>
        </b>{" "}
        faktorált számlák bruttó összege.
        <br />
        <b>
          <i>Faktorált bruttó:</i>
        </b>{" "}
        beszámítás/levonás összegével korrigált számla összege.
        <br />
        <b>
          <i>Finanszírozott összeg:</i>
        </b>{" "}
        előfinanszírozási százalékon megelőlegezett összeg.
      </div>
    )
  },
  {
    //32
    title: "Meglévő ügyfeleknek - Szerződések",
    desc: (
      <div>
        <img
          style={{ width: "100%", marginTop: "1em", marginBottom: "1em" }}
          src={existingImgStep32}
        />
        A Szerződések menüpontban láthatod azoknak a vevőknek a listáját,
        amelyek számláit benyújthatod faktorálásra. A Limit összege a maximális
        keretet jelenti, a mellette lévő mutató pedig azt szemlélteti, hogy
        ennek a keretnek mekkora része lett kihasználva. A Felhasználható keret
        oszlopban láthatod a még felhasználható összeget.
      </div>
    )
  },
  {
    //33
    title: "Meglévő ügyfeleknek - Limitmódosítás",
    desc: (
      <div>
        <img
          style={{ width: "100%", marginTop: "1em", marginBottom: "1em" }}
          src={existingImgStep33}
        />
        Ha szeretnéd növelni, vagy csökkenteni a vevőhöz kapcsolódó limitedet,
        kattints a listában a vevő nevénél található limitmódosítás gombra.
        Munkatársunk kap egy e-mailt a kérelmedről és felveszi veled a
        kapcsolatot.
      </div>
    )
  },
  {
    //34
    title: "Meglévő ügyfeleknek - Munkatársak",
    subTitle: "Új munkatárs meghívása",
    desc: (
      <div>
        <ol>
          <li>Kattints a Munkatársak menüpontra.</li>
          <li>Jobb oldalon kattints a Meghívás gombra.</li>
        </ol>
        <img style={{ width: "100%" }} src={existingImgStep34} />
        <ol>
          <li value={3}>
            Add meg a választott munkatárs e-mail címét, amivel beléphet a
            fiókodba.
          </li>
        </ol>
      </div>
    )
  },
  {
    //35
    title: "Meglévő ügyfeleknek - Munkatársak",
    subTitle: "Új munkatárs meghívása",
    desc: (
      <div>
        <ol>
          <li value={4}>
            Jelöld ki, hogy milyen jogosultsághoz szeretnél hozzáférést adni.
          </li>
        </ol>
        <img style={{ width: "100%" }} src={existingImgStep35} />
        <ol>
          <li value={5}>
            A Küldés gombra kattintva e-mailben értesítjük a munkatársadat és
            tájékoztatjuk a tennivalókról.
          </li>
        </ol>
      </div>
    )
  },
  {
    //36
    title: "Meglévő ügyfeleknek - Munkatársak",
    subTitle: "Munkatársak jogosultságai",
    desc: (
      <div>
        <Typography variant={"h6"} style={{ marginTop: "1em" }}>
          Dokumentum feltöltő
        </Typography>
        Megtekintheti az adatokat és feltöltheti a szerződéskötéshez szükséges
        dokumentumokat.
        <Typography variant={"h6"} style={{ marginTop: "24px" }}>
          Szamlakezelő
        </Typography>
        Megtekintheti, rögzítheti és faktorálásra előkészítheti a számlákat.
        <Typography variant={"h6"} style={{ marginTop: "24px" }}>
          Engedményezés végrehajtó
        </Typography>
        Benyújthatja a számlákat faktorálásra és aláírhatja a dokumentumokat.
      </div>
    )
  },
  {
    //37
    title: "Meglévő ügyfeleknek - Munkatársak",
    subTitle: "Munkatárs deaktiválása",
    desc: (
      <div>
        Ha átmenetileg nem akarod, hogy hozzáférjen a munkatársad a fiókodhoz,
        akkor deaktiválhatod. Amennyiben szeretnéd, később visszaállíthatod a
        munkatársad fiókját.
        <br />
        <br />
        <b>
          <i>Lépések: </i>
        </b>
        <ol style={{ marginTop: 0 }}>
          <li>Kattints a Munkatársak menüpontra.</li>
          <li>
            Válaszd ki azt a munkatársat, akinek deaktiválni kívánod a fiókját.
          </li>
          <li>
            A Státusz oszlopban lévő csúszkára kattintva deaktiválhatod a
            munkatársad.
          </li>
          <li>
            E-mailben értesítjük a munkatársadat és tájékoztatjuk a
            tennivalókról.
          </li>
        </ol>
      </div>
    )
  },
  {
    //37
    title: "Meglévő ügyfeleknek - NAV adatkapcsolat",
    subTitle: "Flexibill fiók szinkronizálása a NAV rendszerével",
    desc: (
      <div style={{ marginTop: "1em" }}>
        Ha bejövő számláidat szeretnéd automatikusan betöltetni a NAV Online
        Számlából, nem kell mást tenned, mint létrehozni a kapcsolatot a fiókod
        és a NAV rendszere között a NAV adatkapcsolat menüpont segítségével. Az
        összekötés lépéseiről részletes útmutatót találsz a Használati segédben.
        <img
          style={{ width: "100%", marginTop: "1em" }}
          src={existingImgStep37}
        />
      </div>
    )
  },
  {
    //37
    title: "Meglévő ügyfeleknek - Profil",
    subTitle: "Mit változtathatok meg a profilomban?",
    desc: (
      <div style={{ marginTop: "1em" }}>
        A Profil menüpontban lehetőséged van megváltoztatni a felhasználónevedet
        (vezetéknév, keresztnév), illetve a jelszavadat. A Feliratkozom a
        hírlevélre jelölőnégyzet bepipálásával értesülhetsz aktuális híreinkről.
        <img
          style={{ width: "100%", marginTop: "1em" }}
          src={existingImgStep38}
        />
      </div>
    )
  }
];

const ExistingUserGuideDialog = ({ open, onClose }) => {
  const [stepIndex, setStepIndex] = useState(0);
  const step = steps[stepIndex];
  return (
    <FlexiDialog
      open={open}
      onClose={onClose}
      size={"medium"}
      title={step.title}
      dialogActions={
        <Grid
          container
          direction={"row"}
          style={{ alignItems: "center", justify: "space-between" }}
        >
          <Grid item>
            <Button
              variant={"text"}
              disabled={stepIndex === 0}
              onClick={() => setStepIndex(stepIndex - 1)}
            >
              <ChevronLeft />
              Vissza
            </Button>
          </Grid>
          <Grid item style={{ flexGrow: 1, padding: "0 15px" }}>
            <LinearProgress
              style={{
                width: "100%"
              }}
              variant="determinate"
              value={((stepIndex + 1) / steps.length) * 100}
            />
          </Grid>
          <Grid item>
            <Button
              variant={"text"}
              disabled={stepIndex + 1 === steps.length}
              onClick={() => setStepIndex(stepIndex + 1)}
            >
              Következő
              <ChevronRight />
            </Button>
          </Grid>
        </Grid>
      }
    >
      <Grid container direction={"column"}>
        <Grid item xs={12}>
          <Typography variant={"h6"}>{step.subTitle}</Typography>
        </Grid>
        <Grid item xs={12} style={{ height: "100px", minHeight: "60px" }}>
          <Typography>{step.desc}</Typography>
        </Grid>
      </Grid>
    </FlexiDialog>
  );
};
export default ExistingUserGuideDialog;

ExistingUserGuideDialog.propTypes = {
  open: PropTypes.any.isRequired,
  onClose: PropTypes.any.isRequired
};
