import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import { IconButton } from "@material-ui/core";
import AttachmentIcon from "@material-ui/icons/AttachFile";
import React from "react";

const makeInvoiceIdRenderer = onAttachmentClick => ({ value, data }) => {
  const hasAttachments = Boolean(
    data && data.documents && data.documents.length
  );

  return (
    <Grid container alignItems={"center"} wrap={"nowrap"}>
      <Tooltip arrow title={value} placement={"bottom"}>
        <span>
          <Typography>{value}</Typography>
        </span>
      </Tooltip>
      {hasAttachments && (
        <Tooltip title={"Csatolmánnyal rendelkezik"} placement={"top-center"}>
          <IconButton
            onClick={e => {
              e.stopPropagation();
              onAttachmentClick(data);
            }}
          >
            <AttachmentIcon fontSize={"small"} />
          </IconButton>
        </Tooltip>
      )}
    </Grid>
  );
};
export default makeInvoiceIdRenderer;
