import PropTypes from "prop-types";
import uploadDocumentPlaceholder from "../../images/upload-document-placeholder.svg";
import Typography from "@material-ui/core/Typography";
import React, { useRef } from "react";
import Button from "@material-ui/core/Button";
import FlexiDocumentPreview from "@bit/flexibill.flexi.flexi-document-preview";
import withStyles from "@material-ui/core/styles/withStyles";
import Box from "@material-ui/core/Box";

const PreviewWrap = withStyles(theme => {
  return {
    root: {
      [theme.breakpoints.down("sm")]: {
        minHeight: "200px"
      },
      padding: "1rem",
      backgroundColor: "#fafcff",
      border: "1px solid #979797",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      "& > img": {
        "& p": { marginBottom: "2rem" }
      },
      "& p": { textAlign: "center" }
    }
  };
})(Box);

const FilePreview = ({
  description = "Itt töltheted fel a rögzítendő számlához tartozó számlaképet! Ezt később is megteheted a számla listában lévő Számla adatai gombra kattintva.",
  browseButtonText = "Számlakép feltöltése",
  file,
  onFileChange
}) => {
  const fileInputRef = useRef();

  return (
    <PreviewWrap>
      {!file && (
        <>
          <img
            src={uploadDocumentPlaceholder}
            width={131}
            alt="document-placeholder"
          />
          <Typography variant="body1">{description}</Typography>
          <Button onClick={() => fileInputRef.current?.click()}>
            {browseButtonText}
          </Button>
          <input
            type={"file"}
            style={{ display: "none" }}
            ref={fileInputRef}
            onChange={e => {
              const file = e?.target?.files?.[0];

              if (file) {
                onFileChange(file);
              }
            }}
          />
        </>
      )}
      {file && <FlexiDocumentPreview file={file} />}
    </PreviewWrap>
  );
};

export default FilePreview;

FilePreview.propTypes = {
  browseButtonText: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  file: PropTypes.any,
  onFileChange: PropTypes.func.isRequired
};
