import { defineMessages } from "react-intl.macro";

const serverErrorMessages = defineMessages({
  "Bad credentials": {
    id: "serverError.Bad credentials",
    defaultMessage: "Helytelen email vagy jelszó, kérjük próbálja újra"
  },
  "Unexpected token < in JSON at position 0": {
    id: "serverError.Unexpected token < in JSON at position 0",
    defaultMessage: "Hiba történt az oldalon"
  },
  "Token is not valid anymore. Probably has been already used or deactivated.": {
    id:
      "serverError.Token is not valid anymore. Probably has been already used or deactivated.",
    defaultMessage: "A meghívó már nem érvényes."
  },
  "Invalid credentials.": {
    id: "serverError.Invalid credentials.",
    defaultMessage: "Nem megfelelő belépési adatok, próbálja újra"
  },
  "resetting.password_already_requested": {
    id: "serverError.resetting.password_already_requested",
    defaultMessage:
      "Az új jelszó igénylése már megtörtént"
  }
});

export default serverErrorMessages;
