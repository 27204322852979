import PropTypes from "prop-types";
import React from "react";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles({
  root: props => ({
    backgroundColor: props.backgroundColor,
    width: 30,
    height: 30,
    "& svg": {
      width: 18,
      height: 18
    }
  })
});

const FlexiStatusIcon = ({ backgroundColor, tooltipText, icon }) => {
  const classes = useStyles({ backgroundColor });

  const avatarEl = <Avatar classes={classes}>{icon}</Avatar>;

  if (tooltipText) {
    return (
      <Tooltip arrow title={tooltipText}>
        {avatarEl}
      </Tooltip>
    );
  }

  return avatarEl;
};

export default FlexiStatusIcon;

FlexiStatusIcon.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  icon: PropTypes.any.isRequired,
  tooltipText: PropTypes.string
};
