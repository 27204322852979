import React from "react";
import { Button, Grid, Paper, Typography, withStyles } from "@material-ui/core";
import ReplayIcon from "@material-ui/icons/Replay";
import { Row } from "../NewContractPage/ClaimsReview";
import { defineMessages } from "react-intl.macro";
import { FormattedHTMLMessage, useIntl } from "react-intl";

const NoBorderWrapper = withStyles({
  root: {
    "& div": {
      borderTop: 0
    }
  }
})(Paper);
const messages = defineMessages({
  calculationSent: {
    id: "calculationSent",
    defaultMessage: "A kalkulációt elküldtük a megadott</br>e-mail címre!"
  }
});
const StepThree = ({ onRegButtonClick, onRestart, data }) => {
  const intl = useIntl();

  return (
    <Grid
      container
      style={{ paddingRight: "90px", paddingBottom: "90px", height: "100%" }}
    >
      <Grid item xs={12} md={6}>
        <Button
          style={{ paddingBottom: "47px", color: "#2227AC" }}
          variant={"text"}
          onClick={onRestart}
        >
          <ReplayIcon />
          Új ajánlat
        </Button>
        <Typography style={{ paddingBottom: "23px" }} variant={"h3"}>
          <FormattedHTMLMessage {...messages.calculationSent} />{" "}
        </Typography>
        <Typography style={{ paddingBottom: "47px" }}>
          De itt is megtekintheted.
        </Typography>
        <Button onClick={onRegButtonClick}>Tetszik, kipróbálom</Button>
      </Grid>
      <Grid item xs={12} md={6}>
        <NoBorderWrapper
          style={{
            marginTop: "2rem",
            overflow: "hidden",
            padding: 0,
            borderRadius: "5px",
            boxShadow: "20px 24px 54px rgba(18, 94, 242, 0.12)"
          }}
        >
          {data.calculation?.basicCalculationViewData?.map(item => (
            <Row {...item} />
          ))}
        </NoBorderWrapper>
      </Grid>
    </Grid>
  );
};

export default StepThree;
