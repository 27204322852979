import useHasPermission from "../components/RoleProvider/useHasPermission";

const useRoleFilter = data => {
  const hasPermission = useHasPermission();

  if (Array.isArray(data)) {
    return data.filter(item => {
      if (item.roles) {
        return hasPermission(item.roles);
      }

      return item;
    });
  }

  return hasPermission(data.roles) ? data : undefined;
};

export default useRoleFilter;
