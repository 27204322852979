import React from "react";
import PageLayout from "../../../components/PageLayout";
import { defineMessages } from "react-intl.macro";
import ExternalSalesAgreementClaimsList from "./ExternalSalesAgreementClaimsList";
import SaveIcon from "@material-ui/icons/Save";
import { useNewContractAgreementClaimPost } from "../../../hooks/useApi";
import useHasPermission from "../../../components/RoleProvider/useHasPermission";
import {
  ROLE_FLEXIPAY_AGREEMENT_UPLOADER,
  ROLE_FLEXIPAY_INVOICE_MANAGER
} from "../../../roles";
import { useHistory } from "react-router-dom";

const messages = defineMessages({
  pageTitle: {
    id: "pageTitle.agreementClaims",
    defaultMessage: "Szerződés igények"
  }
});

const ExternalSalesAgreementClaims = () => {
  // const [
  //   onNewAgreementClaimClick,
  //   newAgreementLoading
  // ] = useNewContractAgreementClaimPost();
  const history = useHistory();
  const onNewAgreementClaimClick = () => {
    history.push("/external-sales/agreement-claims/new-contract");
  };
  const hasPermission = useHasPermission();

  return (
    <PageLayout
      insidePage
      pay
      titleMessage={messages.pageTitle}
      // loading={newAgreementLoading}
      rightSideComponents={
        !hasPermission([
          ROLE_FLEXIPAY_INVOICE_MANAGER,
          ROLE_FLEXIPAY_AGREEMENT_UPLOADER
        ])
          ? [
              {
                props: {
                  onClick: onNewAgreementClaimClick,
                  children: "+ Új igény"
                },
                mobile: {
                  placement: "fab",
                  tooltip: "Mentés",
                  icon: <SaveIcon />
                }
              }
            ]
          : []
      }
    >
      <ExternalSalesAgreementClaimsList
        onNewAgreementClaimClick={onNewAgreementClaimClick}
      />
    </PageLayout>
  );
};

export default ExternalSalesAgreementClaims;
