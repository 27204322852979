import FlexiDialog from "@bit/flexibill.flexi.flexi-dialog";
import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import FlexiForm, {
  makeExternalFormSubmit,
  Fields
} from "@bit/flexibill.flexi.flexi-form";
import Button from "@material-ui/core/Button";

const formId = "CustomerConfirmation-RejectReasonDialog-RejectReasonForm";
const submit = makeExternalFormSubmit(formId);

export const rejectReasonOptions = [
  { value: "INVALID_INVOICE_DATA", text: "Hibás számlaadat" },
  {
    value: "NO_FULFILLMENT",
    text: "Teljesítés nem történt meg"
  },
  { value: "INVALID_FULFILLMENT", text: "Hibás teljesítés" },
  {
    value: "NOT_CONTRIBUTE_TO_FACTORING",
    text: "Nem járulok hozzá a faktoráláshoz"
  },
  {
    value: "OTHER",
    text: "Egyéb"
  }
];

export const getRejectReasonText = reason => {
  const item = rejectReasonOptions.find(opt => opt.value === reason);

  return item && item.text;
};

const RejectReasonDialog = ({ open, onClose, onSubmit }) => {
  return (
    <FlexiDialog
      title={"Számla elutasítása"}
      size={"small"}
      onClose={onClose}
      open={open}
      dialogActions={
        <Box>
          <Button onClick={submit}>Mentés</Button>
        </Box>
      }
    >
      <Box marginBottom={"10px"}>
        <Typography>
          Kérem, válassza ki az elutasítás okát az alábbi listából! A megjegyzés
          mezőben bővebben kifejtheti, amit a szállító is lát majd a saját
          felületén
        </Typography>
      </Box>
      <Box>
        <FlexiForm
          id={formId}
          FinalFormFormProps={{
            onSubmit
          }}
          render={({ values }) => (
            <Fields
              config={[
                [
                  {
                    fieldType: "select",
                    options: rejectReasonOptions,
                    name: "customerRejectionReason",
                    label: "Elutasítás oka",
                    required: true
                  }
                ],
                [
                  {
                    fieldType: "text",
                    key:
                      values.customerRejectionReason === "OTHER"
                        ? "requried"
                        : "not-requred",
                    name: "customerRejectionComment",
                    label: "Megjegyzés",
                    required: values.customerRejectionReason === "OTHER"
                  }
                ]
              ]}
            />
          )}
        />
      </Box>
    </FlexiDialog>
  );
};

export default RejectReasonDialog;
