import { createHook } from "react-sweet-state";
import confirmDialogStore from "../store/confirmDialog";
import { useCallback } from "react";

export const useConfirmDialog = createHook(confirmDialogStore);

const useConfirmation = (
  fn,
  opts = { dialogTitle: "Megerősítés", dialogDescription: "Biztosan?" }
) => {
  const [, confirmActions] = useConfirmDialog();

  return useCallback(
    (...args) => {
      confirmActions.showConfirmDialog({ fn, ...opts, args });
    },
    [confirmActions, opts.dialogDescription, opts.dialogTitle]
  );
};

export const useConfirmationFn = () => {
  const [, confirmActions] = useConfirmDialog();

  return useCallback(
    ({ fn, dialogTitle = "Megerősítés", dialogDescription = "Biztosan?" }) => {
      confirmActions.showConfirmDialog({
        fn,
        dialogTitle,
        dialogDescription,
        args: []
      });
    },
    [confirmActions]
  );
};

export default useConfirmation;
