import { Chip, Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React from "react";
import docSVG from "../../images/redesignImages/Doksi.svg"
import deleteIcon from "../../images/redesignImages/Vector.svg"

const ChipList = props => {
  return (
    <Grid
      container
      alignItems={"center"}
      spacing={1}
      style={{ marginBottom: "1rem" }}
    >
      <Grid item>
        <Typography variant={"h5"}>
          {props.label}
        </Typography>
      </Grid>
      <Grid item>
        <Grid container spacing={1}>
          {props.chips.map(chip => {
            return (
              <Grid item>
                <Chip deleteIcon={<img style={{paddingLeft: "64px"}} src={deleteIcon}/>} icon={<img style={{paddingRight: "12px"}} src={docSVG}/>} style={{backgroundColor: "#DCF8EC", borderRadius: "5px", padding: "14px"}} label={chip.label} onDelete={chip.onDelete} />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ChipList;
