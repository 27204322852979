import { Select } from "final-form-material-ui";
import { required } from "../../utils/validators";
import { Chip, MenuItem } from "@material-ui/core";
import { Field } from "react-final-form";
import React from "react";
import { useAgreements } from "../../hooks/useApi";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";

export const StyledChip = withStyles(theme => {
  return {
    root: {
      height: "19px",
      borderRadius: "5px",
      marginLeft: "5px",
      backgroundColor: props => (props.label === "HUF" ? "#D6DAF7" : "#F6E5EC"),
      color: props => (props.label === "HUF" ? "#394AB8" : "#EA1E6F")
    }
  };
})(Chip);

const useEllipsisOverflowStyles = makeStyles({
  root: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  }
});

const AgreementSelectField = ({ disabled, onMenuItemClick, ...props }) => {
  const { data: agreements } = useAgreements();
  const ellipsisClasses = useEllipsisOverflowStyles();
  const initialValue =
    agreements?.length === 1 ? agreements[0].agreementId : undefined;
  return (
    <Field
      name={"agreementId"}
      component={Select}
      label={!props.removeLabel && "Vevő neve"}
      style={props.removeLabel && { marginTop: "0" }}
      formControlProps={{ style: { width: "100%" }, variant: "outlined" }}
      fullWidth
      disabled={disabled}
      validate={required}
      {...props}
    >
      {agreements
        .filter(contract => contract.status === "active")
        .map(contract => (
          <MenuItem
            value={contract.agreementId}
            key={contract.agreementId}
            disabled={contract.suspended}
            onClick={() => onMenuItemClick && onMenuItemClick(contract)}
          >
            <Grid container alignItems={"space-between"}>
              <Grid item xs classes={ellipsisClasses}>
                {contract.suspended && (
                  <Chip
                    style={{
                      height: "19px",
                      marginRight: "5px",
                      borderRadius: "5px",
                      backgroundColor: "#ebdbf8",
                      color: "#9013fe"
                    }}
                    label={"felfüggesztve"}
                  />
                )}
                {contract.customerName}
              </Grid>
              <Grid item xs={"auto"}>
                <StyledChip label={contract.currency} />
              </Grid>
            </Grid>
          </MenuItem>
        ))}
    </Field>
  );
};

export default AgreementSelectField;
