const LOCAL_STORAGE_TOKEN_KEY = "token";
const LOCAL_STORAGE_COMPANY_ID_KEY = "companyId";
const LOCAL_STORAGE_TARGET_URL = "targetUrl";
const SSO_TOKEN = "ssoToken";

export const storeToken = token =>
  localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token);
export const storeCompanyId = companyId =>
  localStorage.setItem(LOCAL_STORAGE_COMPANY_ID_KEY, companyId);
export const storeSsoToken = token => localStorage.setItem(SSO_TOKEN, token);
export const getStoredToken = () =>
  localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
export const getStoredCompanyId = () =>
  localStorage.getItem(LOCAL_STORAGE_COMPANY_ID_KEY);
export const getStoredSsoToken = () => localStorage.getItem(SSO_TOKEN);
export const resetToken = () => {
  localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
};
export const resetCompanyId = () =>
  localStorage.removeItem(LOCAL_STORAGE_COMPANY_ID_KEY);
export const resetSsoToken = () => localStorage.removeItem(SSO_TOKEN);
export const storeTargetUrl = url =>
  url !== "/app/logout" &&
  !url.includes("/widgets") &&
  localStorage.setItem(LOCAL_STORAGE_TARGET_URL, url);
export const resetTargetUrl = () =>
  localStorage.removeItem(LOCAL_STORAGE_TARGET_URL);
export const resetAuthData = () => {
  resetToken();
  resetCompanyId();
  resetSsoToken();
};
