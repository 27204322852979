import PageLayout from "../../components/PageLayout";
import React from "react";
import {defineMessages} from "react-intl.macro";
import DashboardLimitsChart from "../DashboardPage/DashboardLimitsChart";
import SalesLimitBox from "./SalesLimitBox";
import SalesLimitList from "./SalesLimitList";

const messages = defineMessages({
  pageTitle: {
    id: "pageTitle.limitPage",
    defaultMessage: "Keretkihasználtság"
  },
});

const LimitPage = () => {
  return (
    <PageLayout
      insidePage
      pay
      titleMessage={messages.pageTitle}
    >
      <div><SalesLimitList/></div>    </PageLayout>
  );
};
export default LimitPage;
