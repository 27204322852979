import { createHook } from "react-sweet-state";
import notificationStore from "../store/notification";
import { useIntl } from "react-intl";
import { useCallback } from "react";

const useNotificationState = createHook(notificationStore);

const makeSpecifiedNotifier = (defaultText, variant) => (
  opts = { text: defaultText }
) => {
  const [, notificationActions] = useNotificationState();
  const intl = useIntl();

  return useCallback((_opts = {}) => {
    const config = { ...opts, ..._opts, variant };

    if (config.translation) {
      config.text = intl.formatMessage(config.translation);
    }

    notificationActions.showNotification(config);
  }, []);
};

export default useNotificationState;

export const useSaveSuccessNotification = makeSpecifiedNotifier(
  "Sikeres mentés!",
  "success"
);
export const useEmailSentNotification = makeSpecifiedNotifier(
  "Limitváltoztatási kérelem elküldve!",
  "success"
);
export const useNavSyncNotification = makeSpecifiedNotifier(
  "Szinkronizálás folyamatban!",
  "success"
);
export const useErrorOccurredNotification = makeSpecifiedNotifier(
  "Hiba történt!",
  "error"
);
export const useWarningNotification = makeSpecifiedNotifier(
  "Hiba történt!",
  "warning"
);
