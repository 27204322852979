import { useIntl } from "react-intl";
import React from "react";
import moment from "moment";
import { makeCurrencyValueFormatter } from "../../utils/makeCurrencyValueFormatter";
import { defineMessages } from "react-intl.macro";
import { useAgreements } from "../../hooks/useApi";
import { useParams } from "react-router-dom";
import FlexiExpandableRemoteList from "@bit/flexibill.flexi.flexi-expandable-remote-list";

const columnMessages = defineMessages({
  issueDate: {
    id: "listColumnHeader.massInvoice.issueDate",
    defaultMessage: "Kiállítás dátuma"
  },
  taxPointDate: {
    id: "listColumnHeader.massInvoice.taxPointDate",
    defaultMessage: "Teljesítés dátuma"
  },
  dueDate: {
    id: "listColumnHeader.massInvoice.modifiedDueDate",
    defaultMessage: "Fizetési határidő"
  },
  totalGrossAmount: {
    id: "listColumnHeader.massInvoice.totalGrossAmount",
    defaultMessage: "Számla bruttó összege"
  },
  invoiceId: {
    id: "listColumnHeader.massInvoice.invoiceId",
    defaultMessage: "Számla sorszáma"
  }
});

const dateValueFormatter = ({ value }) =>
  value && moment(value).format("YYYY.MM.DD");

const getColumns = (intl, currency) => {
  return [
    {
      field: "invoiceId",
      headerName: intl.formatMessage(columnMessages.invoiceId),
      checkboxSelection: true
    },
    {
      field: "issueDate",
      headerName: intl.formatMessage(columnMessages.issueDate),
      valueFormatter: dateValueFormatter,
      autoHeight: true
    },
    {
      field: "taxPointDate",
      headerName: intl.formatMessage(columnMessages.taxPointDate),
      valueFormatter: dateValueFormatter,
      autoHeight: true
    },
    {
      field: "dueDate",
      headerName: intl.formatMessage(columnMessages.dueDate),
      valueFormatter: dateValueFormatter,
      autoHeight: true
    },
    {
      field: "totalGrossAmount",
      headerName: intl.formatMessage(columnMessages.totalGrossAmount),
      autoHeight: true,
      valueFormatter: ({ value, data }) =>
        makeCurrencyValueFormatter(intl)({
          value,
          data: { ...data, currency: currency }
        })
    }
  ];
};

const MassInvoiceList = props => {
  const intl = useIntl();
  const { data: agreements } = useAgreements();
  let { agreementId } = useParams();
  const currency = agreements?.find(
    x => x.agreementId === parseInt(agreementId, 10)
  ).currency;
  const columnDefs = getColumns(intl, currency);

  function isFirstColumn(params) {
    const displayedColumns = params.columnApi.getAllDisplayedColumns();
    return displayedColumns[0] === params.column;
  }

  return (
    <FlexiExpandableRemoteList
      columnDefs={columnDefs}
      localItems={props.invoices || []}
      gridOptions={{
        rowSelection: "multiple",
        suppressRowClickSelection: false,
        rowMultiSelectWithClick: true,
        defaultColDef: {
          headerCheckboxSelection: isFirstColumn,
          checkboxSelection: isFirstColumn
        },
        onSelectionChanged: ({ api }) => {
          props.onSelectedInvoicesChange(
            api.getSelectedNodes().map(node => node.data)
          );
        }
      }}
    />
  );
};

export default MassInvoiceList;
