import { createHook } from "react-sweet-state";
import dataStore from "../store/data";

const useDataState = createHook(dataStore);

export const useParsedMassInvoices = () => {
  const [data] = useDataState();

  return data.parsedMassInvoices;
};

export const useCompanies = () => {
  const [data] = useDataState();

  return data.companies || [];
};

export const useStoredData = key => {
  const [data] = useDataState();

  // if (key === "szamlazzHu") {
  //   return true;
  // }
  return data[key];
};

export const useStoreData = () => {
  const [, dataActions] = useDataState();

  return dataActions.set;
};

export default useDataState;
