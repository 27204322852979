import PageLayout from "../../components/PageLayout";
import React from "react";
import DocumentsByDate from "../../components/DocumentsByDate";
import ErrorBoundary from "../../components/ErrorBoundary";
import { useInvoiceDocumentsPageData } from "../../hooks/useApi";
import FlexiDialog from "@bit/flexibill.flexi.flexi-dialog";
import DocumentsCarousel from "../../components/DocViewer";
import { getUrlWithCompanyId } from "../../utils/urlHelpers";

const InvoiceDocumentPage = ({ match }) => {
  const { invoice, downloadDocument, loading } = useInvoiceDocumentsPageData(
    match.params.id
  );

  const [docPopOpen, setDocPopOpen] = React.useState(false);
  const [selectedDoc, setSelectedDoc] = React.useState([]);

  return (
    <PageLayout
      insidePage
      pay
      titleMessage={{
        id: "pageTitle.invoiceDocuments",
        defaultMessage: "Számlához tartozó dokumentumok"
      }}
      loading={loading}
      backButton
      backUrl={"/app/invoice"}
    >
      <ErrorBoundary>
        <DocumentsByDate
          data={invoice && invoice.documents}
          onDownloadClick={document => {
            downloadDocument(document);
          }}
          onShowClick={doc => {
            setSelectedDoc([doc]);
            setDocPopOpen(true);
          }}
        />
        <FlexiDialog
          open={docPopOpen}
          title={selectedDoc.originalName}
          onClose={() => setDocPopOpen(false)}
        >
          <DocumentsCarousel
            documents={selectedDoc}
            getUrl={() =>
              selectedDoc &&
              getUrlWithCompanyId(`/invoice/document/${selectedDoc[0].id}`)
            }
          />
        </FlexiDialog>
      </ErrorBoundary>
    </PageLayout>
  );
};

export default InvoiceDocumentPage;
