import React, { useCallback, useEffect, useRef } from "react";
import { Field } from "react-final-form";
import { Grid } from "@material-ui/core";
import DescriptionIcon from "@material-ui/icons/Description";
import DeleteIcon from "@material-ui/icons/Delete";
import GetAppIcon from "@material-ui/icons/GetApp";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import useConfirmation from "../../hooks/useConfirmation";

const RenderFileField = ({
  input: { value, onChange, ...input },
  onRemove,
  onDownload,
  browseImmediately,
  ...rest
}) => {
  const isUploaded = Boolean(value.id);
  const showBrowseText = !Boolean(value);
  const fileName = isUploaded ? value?.originalName : value?.name;

  const inputRef = useRef();
  const openBrowseWindow = useCallback(
    () => {
      if (inputRef.current && !isUploaded) {
        inputRef.current.click();
      }
    },
    [inputRef]
  );
  const removeWithConfirm = useConfirmation(onRemove, {
    dialogTitle: "Törlés megerősítése",
    dialogDescription: `Biztosan törölni szeretné a "${fileName}" fájlt?`
  });

  useEffect(
    () => {
      if (browseImmediately) {
        openBrowseWindow();
      }
    },
    [browseImmediately, openBrowseWindow]
  );

  const handleChange = ({ target }) => {
    onChange(target.files[0]); // instead of the default target.value
  };

  return (
    <Box p={0.5}>
      <Grid
        container
        justify={"space-between"}
        style={{
          borderRadius: 3,
          backgroundColor: isUploaded ? "#F1F9E8" : "#EFF3F8"
        }}
        spacing={1}
        alignItems={"center"}
      >
        <Grid item>
          <DescriptionIcon style={{ color: "#646464" }} />
        </Grid>
        <Grid item style={{ flexGrow: 1, flex: "1 1 0", overflow: "hidden" }}>
          {showBrowseText && (
            <Typography
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={openBrowseWindow}
            >
              Tallóz...
            </Typography>
          )}
          <Typography
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap"
            }}
          >
            {fileName}
          </Typography>
        </Grid>
        <Grid item>
          <Grid container>
            {onDownload &&
              isUploaded && (
                <Grid item>
                  <IconButton onClick={() => onDownload(value)}>
                    <GetAppIcon />
                  </IconButton>
                </Grid>
              )}
            {onRemove && (
              <Grid item>
                <IconButton
                  onClick={Boolean(value) ? removeWithConfirm : onRemove}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </Grid>

        <input
          {...input}
          type="file"
          onChange={handleChange}
          ref={inputRef}
          style={{ display: "none" }}
        />
      </Grid>
    </Box>
  );
};

const FileField = ({
  name,
  browseImmediately,
  onRemove,
  onDownload,
  ...props
}) => {
  return (
    <Field
      name={name}
      component={RenderFileField}
      onRemove={onRemove}
      onDownload={onDownload}
      browseImmediately={browseImmediately}
    />
  );
};

export default FileField;
