import { useErrorOccurredNotification } from "./useNotificationState";
import { useCallback } from "react";

const useFilterLargeFiles = (filesAll, limit = 20000000) => {
  const showError = useErrorOccurredNotification();

  const filterFiles = useCallback(
    files => {
      const passed = [];
      const large = [];

      const sums = filesAll
        .map(item => item.file.size)
        .reduce((a, b) => a + b, files[0].size);

      if (sums > limit) {
        const errorMsg = `A csatolmány(ok) mérete nagyobb mint 20 Mb!`;
        showError({ text: errorMsg });
        return passed
      }

      Array.from(files).forEach(file => {
        if (file.size < limit) {
          passed.push(file);
        } else {
          large.push(file);
        }
      });

      if (!large.length) {
        return passed;
      }

      const errorMsg = `A fájl mérete nagyobb mint 20 Mb:\n
      ${large.map(file => file.name).join(",\n ")}
    `;

      showError({ text: errorMsg });

      return passed;
    },
    [filesAll]
  );

  return filterFiles;
};

export default useFilterLargeFiles;
