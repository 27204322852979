import React from "react";
import { FormHelperText } from "@material-ui/core";

const withCheckboxErrorMessage = WrappedComponent => props => {
  const error = props?.meta?.error;
  const touched = props?.meta?.touched;

  return (
    <>
      <WrappedComponent {...props} error={error} />
      {touched &&
        error && (
          <FormHelperText
            error
            style={{
              position: 'absolute',
              bottom: '-12px',
              left: '42px',
            }}
          >
            {error}
          </FormHelperText>
        )}
    </>
  );
};
export default withCheckboxErrorMessage;
