import React from "react";
import DashboardIcon from "@material-ui/icons/Dashboard";
import InvoiceIcon from "@material-ui/icons/InsertDriveFile";
import FactorContractsIcon from "@material-ui/icons/Toc";
import PeopleIcon from "@material-ui/icons/People";
import AssessmentIcon from "@material-ui/icons/Assessment";
import DnsIcon from "@material-ui/icons/Dns";
import LibraryAddCheckIcon from "@material-ui/icons/LibraryAddCheck";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import DashboardPage from "../../pages/DashboardPage";
import InvoicePage from "../../pages/InvoicePage";
import InvoiceFormPage from "../../pages/InvoiceFormPage";
import FactorContractsPage from "../../pages/FactorContractsPage";
import CoworkersPage from "../../pages/CoworkersPage";
import LogoutPage from "../../pages/LogoutPage";
import SaveMassInvoicePage from "../../pages/SaveMassInvoicePage";
import InvoiceDocumentPage from "../../pages/InvoiceDocumentsPage";
import InvoiceEditPage from "../../pages/InvoiceEditPage";
import InvoiceGroupPage from "../../pages/InvoiceGroupPage";
import FactorNotificationPage from "../../pages/FactorNotificationPage";
import LoadableNewContractPage from "../../pages/NewContractPage/Loadable";
import PersonIcon from "@material-ui/icons/Person";
import DescriptionIcon from "@material-ui/icons/Description";
import NavDataPage from "../../pages/NavDataPage/NavDataPage";
import navMenuIcon from "../../images/nav_menu.svg";
import SalesAgreementClaims from "../../pages/ExternalSales/ExternalSalesAgreementClaims";
import AgreementClaims from "../../pages/AgreementClaims";
import CustomerInvoiceGroupsPage from "../../pages/CustomerInvoiceGroupsPage";
import CustomerInvoiceGroupPage from "../../pages/CustomerInvoiceGroupPage";
import ProfileDataPage from "../../pages/ProfileDataPage";
import LimitChangePage from "../../pages/LimitChangePage";
import LimitChangeListPage from "../../pages/LimitChangeListPage";
import LimitChangeFileUploadPage from "../../pages/LimitChangeFileUploadPage";
import limitMenuIcon from "../../images/Vector.svg";
import TasksPage from "../../pages/TasksPage";
import LimitPage from "../../pages/LimitPage";
import AgreementsInProgressPage from "../../pages/AgreementsInProgressPage";
import GuidePage from "../../pages/GuidePage";
import guideMenuIcon from "../../images/guide/hasznSeged_ikon.svg";

const privateRoutes = [
  {
    path: "",
    text: "Főoldal",
    icon: <DashboardIcon />,
    component: DashboardPage,
    exact: true,
    menuItem: true
  },
  {
    path: "/invoice/new",
    component: InvoiceFormPage,
    menuItem: false,
    showOnFlexipayCustomerPlus: true
  },
  {
    path: "/invoice/documents/:id",
    component: InvoiceDocumentPage,
    menuItem: false,
    showOnFlexipayCustomerPlus: true
  },
  {
    path: "/invoice/edit/:id",
    component: InvoiceEditPage,
    menuItem: false,
    showOnFlexipayCustomerPlus: true
  },
  {
    path: "/invoice/:tabName?",
    text: "Számlák",
    component: InvoicePage,
    menuItem: false
  },
  {
    path: "/invoice",
    text: "Számlák",
    icon: <InvoiceIcon />,
    component: InvoicePage,
    menuItem: true,
    showOnFlexipayCustomerPlus: true
  },
  {
    path: "/factor-contracts",
    text: "Szerződések",
    icon: <FactorContractsIcon />,
    component: FactorContractsPage,
    menuItem: true
  },
  // {
  //   path: "/limit",
  //   text: "Keretkihasználtság",
  //   icon: <AssessmentIcon />,
  //   component: LimitPage,
  //   menuItem: true,
  //   showOnFlexipaySales: true
  // },{
  //   path: "/agreements-in-progress",
  //   text: "Folyamatban lévő ügyek",
  //   icon: <DnsIcon />,
  //   component: AgreementsInProgressPage,
  //   menuItem: true,
  //   showOnFlexipaySales: true,
  //
  // },{
  //   path: "/tasks",
  //   text: "Feladatok",
  //   icon: <LibraryAddCheckIcon />,
  //   component: TasksPage,
  //   menuItem: true,
  //   showOnFlexipaySales: true
  //
  // },
  {
    path: "/agreement-claims/new-contract/:id?",
    component: LoadableNewContractPage,
    menuItem: false,
    showOnNewContract: true,
    showOnDocumentUploader: true,
    showOnFlexipayCustomerPlus: false
  },
  {
    path: "/agreement-claims",
    text: "Szerződés igények",
    icon: <DescriptionIcon />,
    component: AgreementClaims,
    menuItem: true,
    showOnNewContract: true,
    showOnDocumentUploader: true,
    showOnInvoiceManager: false,
    showOnFlexipaySales: false
  },
  // {
  //   path: "/sales-agreement-claims",
  //   text: "Szerződés igények",
  //   icon: <DescriptionIcon />,
  //   component: SalesAgreementClaims,
  //   menuItem: true,
  //   showOnNewContract: true,
  //   showOnDocumentUploader: true,
  //   showOnInvoiceManager: false
  // },
  // {
  //   path: "/documents",
  //   text: "Dokumentumtár",
  //   icon: <DocumentsIcon />,
  //   component: DocumentsPage,
  //   menuItem: true,
  // },
  {
    path: "/customer-invoice-groups/:id",
    text: "",
    component: CustomerInvoiceGroupPage,
    menuItem: false,
    showOnFlexipayCustomer: true
  },
  // {
  //   path: "/limit-change-list",
  //   text: "Limitmódosítás",
  //   component: LimitChangeListPage,
  //   menuItem: true,
  //   icon: <img style={{ paddingLeft: "4px" }} src={limitMenuIcon} />,
  //   showOnNewContract: false
  // },
  {
    path: "/limit-change",
    text: "",
    component: LimitChangePage,
    menuItem: false,
    showOnFlexipayCustomer: true,
    showOnNewContract: true
  },
  {
    path: "/limit-change-file-upload/:id",
    text: "",
    component: LimitChangeFileUploadPage,
    menuItem: false,
    showOnFlexipayCustomer: true,
    showOnNewContract: true
  },
  {
    path: "/customer-invoice-groups",
    text: "Számlacsoportok",
    icon: <InvoiceIcon />,
    component: CustomerInvoiceGroupsPage,
    menuItem: true,
    showOnFlexipayCustomer: true,
    hideOnSzamlazzHu: true,
    hideOnKulcsSoft: true
  },
  {
    path: "/coworkers",
    text: "Munkatársak",
    icon: <PeopleIcon />,
    component: CoworkersPage,
    menuItem: true,
    showOnNewContract: true,
    showOnFlexipayCustomer: true,
    showOnFlexipayCustomerPlus: true
  },
  // {
  //   path: "/company-data",
  //   text: "Vállalkozás adatai",
  //   icon: <SettingsIcon />,
  //   component: CompanyDataPage,
  //   menuItem: true,
  //   showOnNewContract: true
  // },
  {
    path: "/profile/nav-data",
    text: "NAV adatkapcsolat",
    icon: (
      <img
        alt={"navMenuIcon"}
        src={navMenuIcon}
        style={{ paddingLeft: "4px" }}
      />
    ),
    component: NavDataPage,
    menuItem: true,
    hideOnSzamlazzHu: true,
    hideOnKulcsSoft: true
  },
  {
    path: "/profile/profile-data",
    text: "Profil",
    icon: <PersonIcon />,
    menuItem: true,
    component: ProfileDataPage,
    showOnNewContract: true,
    showOnDocumentUploader: true,
    showOnFlexipayCustomer: true,
    showOnFlexipayCustomerPlus: true

    // subMenuItems: [
    //   {
    //     path: "/profile/profile-data",
    //     text: "Profil adatai",
    //     icon: <PersonIcon />,
    //     component: ProfilePages,
    //     menuItem: true
    //   },
    //   // {
    //   //   path: "/profile/password-change",
    //   //   text: "Jelszóváltoztatás",
    //   //   icon: <RefreshIcon />,
    //   //   component: ProfilePages,
    //   //   menuItem: true
    //   // },
    //   // {
    //   //   path: "/profile/delete-profile",
    //   //   text: "Adat- és fióktörlés",
    //   //   icon: <DeleteIcon />,
    //   //   component: DeleteProfilePage,
    //   //   menuItem: true
    //   // },
    //
    // ]
  },
  {
    path: "/guide",
    text: "Használati segéd",
    component: GuidePage,
    menuItem: true,
    icon: <img style={{ paddingLeft: "4px" }} src={guideMenuIcon} />,
    showOnNewContract: false
  },

  // {
  //   path: "/logout",
  //   text: "Kijelentkezés",
  //   icon: <ExitToAppIcon />,
  //   component: LogoutPage,
  //   menuItem: true
  // },
  {
    path: "/invoice-group/:id/factor-notification",
    text: "",
    icon: null,
    component: FactorNotificationPage,
    menuItem: false
  },
  {
    path: "/invoice-group/:id",
    text: "",
    icon: null,
    component: InvoiceGroupPage,
    menuItem: false
  },
  {
    path: "/save-mass-invoice/:agreementId?",
    component: SaveMassInvoicePage,
    menuItem: false
  },
  {
    path: "/logout",
    text: "Kijelentkezés",
    icon: <ExitToAppIcon />,
    component: LogoutPage,
    menuItem: false,
    showOnNewContract: true,
    showOnDocumentUploader: true,
    showOnFlexipayCustomer: true
  }
].map(route => {
  const item = { ...route, path: `/app${route.path}` };
  if (item.subMenuItems) {
    item.subMenuItems = item.subMenuItems.map(subMenuItem => ({
      ...subMenuItem,
      path: `/app${subMenuItem.path}`
    }));
  }
  return item;
});

export default privateRoutes;
