import React from "react";
import {Redirect} from "react-router-dom";

const useRedirect = () => {
  const [redirectTo, setRedirectTo] = React.useState(null);

  let redirect = null;

  if (redirectTo) {
    redirect = <Redirect to={redirectTo} push/>;
  }

  return [redirect, setRedirectTo];
};

export default useRedirect;
