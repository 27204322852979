import React from "react";
import Grid from "@material-ui/core/Grid";
import digFaktWhite from "../../images/flexipaybill_hor_white.svg";

const MobileWelcomeHeader = () => {
  return (
    <Grid container style={{ backgroundColor: "#394AB8" }} align={"center"}>
      <Grid xs={12}>
        <img
          alt={"digFaktWhite"}
          src={digFaktWhite}
          style={{ width: "140px", paddingBottom: "20px", paddingTop: "20px" }}
        />
      </Grid>
    </Grid>
  );
};

export default MobileWelcomeHeader;
