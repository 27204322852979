export function convertBooleansToStrings(value) {
  if (Array.isArray(value)) {
    return value.map(convertBooleansToStrings); // Recursively convert array elements
  } else if (typeof value === "object" && value !== null) {
    const newObj = {};
    for (const key in value) {
      newObj[key] = convertBooleansToStrings(value[key]); // Recursively convert object values
    }
    return newObj;
  } else if (typeof value === "boolean") {
    return value.toString(); // Convert boolean to string
  } else {
    return value; // Retain the original value for non-boolean types
  }
}
