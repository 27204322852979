import Dialog from "@material-ui/core/Dialog";
import React from "react";
import withWidth from "@material-ui/core/withWidth";
import {
  DialogActions,
  DialogContent,
  isWidthUp
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";

const DialogWithPadding = withStyles(theme => {
  return {
    root: {
      [theme.breakpoints.down("md")]: {
        padding: "1rem"
      },
      "& > div:first-of-type": {
        backgroundColor: props => props.darkerBackdrop && "rgba(0, 0, 0, 0.8)"
      }
    }
  };
})(Dialog);

const FlexiCustomerDialog = props => {
  return (
    <DialogWithPadding
      fullScreen={props.fullScreen}
      open={props.open}
      onClose={props.onClose}
      darkerBackdrop={props.darkerBackdrop}
      PaperProps={{
        style: {
          maxWidth: props.maxWidth,
          paddingTop: "32px",
          paddingRight: "40px",
          paddingBottom: "40px",
          paddingLeft: "40px"
        }
      }}
    >
      <DialogContent style={{ padding: 0, maxWidth: "500px" }}>
        {isWidthUp("sm", props.width) ? (
          <Grid container>
            <Grid item sm={4} style={{ alignSelf: "center" }}>
              <img alt={"imgSrc"} src={props.imgSrc} style={{ width: "100px" }} />
            </Grid>
            <Grid item sm={8}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant={"h5"} fontSize={24}>
                    {props.title}
                  </Typography>
                  {props.onClose && (
                    <IconButton
                      onClick={props.onClose}
                      style={{ position: "absolute", top: 8, right: 24 }}
                    >
                      <CloseIcon color="action" />
                    </IconButton>
                  )}
                </Grid>
                <Grid item xs={12}>
                  {props.children}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid container justify={"center"}>
            <Grid item style={{ paddingBottom: "10px" }}>
              <Typography
                variant={"h5"}
                fontSize={24}
                style={{ textAlign: "center" }}
              >
                {props.title}
              </Typography>
              {props.onClose && (
                <IconButton
                  style={{ position: "absolute", top: 8, right: 8 }}
                  onClick={props.onClose}
                >
                  <CloseIcon color="action" />
                </IconButton>
              )}
            </Grid>
            {props.hideImage && (
              <Grid item style={{ paddingBottom: "10px" }}>
                <img alt={"imgSrc"} src={props.imgSrc} style={{ width: "100px" }} />
              </Grid>
            )}
            <Grid item>{props.children}</Grid>
          </Grid>
        )}
      </DialogContent>
      {props.dialogActions && (
        <DialogActions>{props.dialogActions}</DialogActions>
      )}
    </DialogWithPadding>
  );
};

FlexiCustomerDialog.propTypes = {
  children: PropTypes.any.isRequired,
  darkerBackdrop: PropTypes.bool,
  dialogActions: PropTypes.array,
  maxWidth: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  width: PropTypes.string.isRequired
};

export default withWidth()(FlexiCustomerDialog);
