import PropTypes from "prop-types";
import { defineMessages } from "react-intl.macro";
import Grid from "@material-ui/core/Grid";
import React, { useState } from "react";
import FilePreview from "./FilePreview";
import Button from "@material-ui/core/Button";
import { useIntl } from "react-intl";

const messages = defineMessages({
  browseFileDescription: {
    id: "label.browseFileDescription",
    defaultMessage:
      "Kérlek, töltsd fel az általad aláírt faktorálási értesítőt!"
  },
  browseFile: {
    id: "label.browseFile",
    defaultMessage: "Faktorálási értesítő feltöltése"
  }
});

const FlexiFileUploadWithPreview = ({
  changeFileButtonText = "MÁSIK FÁJL KIVÁLASZTÁSA",
  uploadButtonText = "FELTÖLTÉS",
  onUploadButtonClick
}) => {
  const [file, setFile] = useState(null);
  const intl = useIntl();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FilePreview
          onFileChange={file => setFile(file)}
          file={file}
          description={intl.formatMessage(messages.browseFileDescription)}
          browseButtonText={intl.formatMessage(messages.browseFile)}
        />
      </Grid>
      <Grid
        item
        xs={12}
        style={{ position: "sticky", bottom: "-8px", backgroundColor: "white" }}
      >
        <Grid container spacing={2} alignItems={"center"}>
          <Grid item xs={7}>
            <Button
              variant={"outlined"}
              fullWidth
              onClick={() => setFile(null)}
              disabled={!file}
            >
              {changeFileButtonText}
            </Button>
          </Grid>
          <Grid item xs={5}>
            <Button
              fullWidth
              onClick={() => onUploadButtonClick(file)}
              disabled={!file}
              tooltip={"asd"}
            >
              {uploadButtonText}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FlexiFileUploadWithPreview;

FlexiFileUploadWithPreview.propTypes = {
  changeFileButtonText: PropTypes.string,
  onUploadButtonClick: PropTypes.func.isRequired,
  uploadButtonText: PropTypes.string
};
