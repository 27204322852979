import Grid from "@material-ui/core/Grid";
import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import { useMutator } from "../../hooks/useApi";
import { selectErrorNotificationMessage } from "../../utils/selectErrorMessage";
import { useErrorOccurredNotification } from "../../hooks/useNotificationState";
import { isWidthUp } from "@material-ui/core";
import { getFlexipayUrl } from "../../utils/urlHelpers";
import DesktopWelcomeHeader from "../../components/WelcomeHeader/desktop";
import FlexiLinearProgress from "../../components/FlexiLinearProgress";
import Footer from "../../components/Footer";
import withWidth from "@material-ui/core/withWidth";
import MobileWelcomeHeader from "../../components/WelcomeHeader/mobile";
import Button from "@material-ui/core/Button";
import confirmBill from "../../images/redesignImages/Grafikai elemek.svg";

const ConfirmPage = props => {
  const token = props.match?.params?.token;
  const [success, setSuccess] = useState(false);
  const [post, postLoading] = useMutator("POST");
  const notifyError = useErrorOccurredNotification();

  React.useEffect(async () => {
    const { data, error } = await post(getFlexipayUrl(`/confirm/${token}`), {
      token
    });
    if (data) {
      setSuccess(true);
    }
    if (error) {
      notifyError(selectErrorNotificationMessage(error));
    }
  }, []);

  return (
    <Grid container>
      {isWidthUp("md", props.width) ? (
        <Grid container direction={"row"}>
          <Grid item xs={6}>
                <DesktopWelcomeHeader />
          </Grid>
          <Grid item xs>
            <Grid container alignItems={"center"} justify={"center"}>
                <Grid item xs>
                  {postLoading && <FlexiLinearProgress />}
                  <Grid
                    container
                    justify={"center"}
                    style={{
                      padding: "0 1rem 1rem",
                      width: "410px",
                      margin: "auto",
                      textAlign: "center"
                    }}
                  >
                    <Grid item xs={12} style={{ paddingTop: "160px" }}>
                      <img alt={"confirmBill"} src={confirmBill} />
                      <Typography
                        variant="h5"
                        align="center"
                        style={{ marginTop: 52, marginBottom: 32 }}                      >
                        {!success
                          ? `Ezt a fiókot már aktiváltuk, lépjen be.`
                          : "Fiókodat sikeresen aktiváltuk!"}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{
                        textAlign: "center"
                      }}
                    >
                      <Button style={{margin: "auto"}} onClick={() => (window.location.href = "/login")}>
                        Bejelentkezés
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{ position: "fixed", bottom: 18 }}
                    >
                      <Footer />
                    </Grid>
                  </Grid>
                </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid container>
          <Grid item xs={12} style={{ paddingBottom: "105px" }}>
            <MobileWelcomeHeader />
          </Grid>
          <Grid item xs={12}>
            {postLoading && <FlexiLinearProgress />}
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              justify={"center"}
              style={{ padding: "0 1rem 1rem" }}
            >
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  align="center"
                  style={{ marginBottom: "2rem" }}
                >
                  {!success
                    ? `Ezt a fiókot már aktiváltuk, lépjen be.`
                    : "Fiókját sikeresen aktiváltuk!"}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  textAlign: "center"
                }}
              >
                <Button onClick={() => (window.location.href = "/login")}>
                  Bejelentkezés
                </Button>
              </Grid>
              <Grid item xs={12} style={{ position: "fixed", bottom: 18 }}>
                <Footer />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
export default withWidth()(ConfirmPage);
