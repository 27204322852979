import React from "react";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import Button from "@material-ui/core/Button";
import FlexiDialog from "@bit/flexibill.flexi.flexi-dialog";
import Grid from "@material-ui/core/Grid";
import FileInput from "../../components/FileInput";
import DownloadIcon from "@material-ui/icons/ArrowDownward";
import Typography from "@material-ui/core/Typography";
import Link from "@bit/flexibill.flexi.flexi-link";
import byHandImg from "../../images/redesignImages/1377-invoice-outline (1) 1.svg";
import bulkImg from "../../images/redesignImages/60-documents-outline 1.png";
import { Box } from "@material-ui/core";

const InvoiceUploadTypeDialog = props => {
  return (
    <FlexiDialog
      size={"small"}
      open={props.open}
      onClose={props.onClose}
      title={"Számla feltöltése"}
    >
      <Grid container spacing={2} justifyContent={"center"}>
        <Grid item xs={6}>
          <Box
            style={{
              border: "1px solid #000000",
              borderRadius: "3px",
              paddingTop: "40px",
              paddingBottom: "40px"
            }}
          >
            <div style={{ textAlign: "center" }}>
              <img
                style={{ cursor: "pointer" }}
                src={byHandImg}
                onClick={props.onSingleUploadClick}
              />
            </div>
            <Typography variant={"body2"} style={{ textAlign: "center" }}>
              Egyénileg / Kézzel
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <FileInput
            onFileChanged={files => {
              if (files && files.length) {
                const file = files[0];
                props.onMassUploadFileChanged(file);
              }
            }}
            accept={
              "text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            }
          >
            {({ inputEl }) => {
              return (
                <>
                  <Box
                    style={{
                      border: "1px solid #000000",
                      borderRadius: "3px",
                      paddingTop: "40px",
                      paddingBottom: "40px"
                    }}
                  >
                    <div style={{ textAlign: "center" }}>
                      <img
                        style={{ cursor: "pointer" }}
                        src={bulkImg}
                        onClick={() => {
                          if (inputEl && inputEl.current) {
                            inputEl.current.click();
                          }
                        }}
                      />
                    </div>
                    <Typography
                      variant={"body2"}
                      style={{ textAlign: "center" }}
                    >
                      Csoportosan
                    </Typography>
                  </Box>
                </>
              );
            }}
          </FileInput>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            display: "flex",
            justifyContent: isWidthUp("sm", props.width) && "flex-start"
          }}
        >
          <Link
            href="https://storage.googleapis.com/flexibill-documents/samples/flexipay-tomoges-feltoltes-minta.csv"
            style={{ color: "inherit", textDecoration: "none" }}
          >
            <Typography variant={"h6"} bold style={{ color: "#2227AC" }}>
              <DownloadIcon
                style={{
                  fontWeight: 700,
                  color: "#2227AC",
                  fontSize: "16px",
                  transform: "translateY(3px)"
                }}
              />{" "}
              CSV minta fájl letöltése
            </Typography>
          </Link>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            display: "flex",
            justifyContent: isWidthUp("sm", props.width) && "flex-end"
          }}
        >
          <Link
            href="https://storage.googleapis.com/flexibill-documents/samples/flexipay-tomeges-szamla-minta_v2.xlsx"
            style={{ color: "inherit", textDecoration: "none" }}
          >
            <Typography variant={"h6"} bold style={{ color: "#2227AC" }}>
              <DownloadIcon
                style={{
                  fontWeight: "bold",
                  color: "#2227AC",
                  fontSize: "16px",
                  transform: "translateY(3px)"
                }}
              />{" "}
              Excel minta fájl letöltése
            </Typography>
          </Link>
        </Grid>
      </Grid>
    </FlexiDialog>
  );
};

export default withWidth()(InvoiceUploadTypeDialog);
