import serverErrorMessages from "../translations/serverErrorMessages";

const selectErrorMessage = data => {
  let errorObj;

  if (data instanceof Error && data.response) {
    errorObj = data.response;
  } else {
    errorObj = data;
  }

  let msg = "Hiba történt";

  if (errorObj) {
    if (errorObj.message) {
      msg = errorObj.message;
    }
  }

  return msg;
};

const selectErrorTranslation = errorString => serverErrorMessages[errorString];

export const selectErrorNotificationMessage = data => {
  const errorString = selectErrorMessage(data);
  const errorTranslation = selectErrorTranslation(errorString);

  return {
    text: errorString,
    translation: errorTranslation
  };
};
