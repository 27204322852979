import AuthPage from "../../pages/AuthPage";
import LoginPage from "../../pages/LoginPage";
import NotFoundPage from "../../pages/NotFoundPage";
import LoadableCustomerConfirmationPage from "../../pages/CustomerConfirmationPage/Loadable";
import RegisterPage from "../../pages/RegisterPage";
import RegisterPageWithoutToken from "../../pages/RegisterPageWithoutToken";
import ForgottenPasswordPage from "../../pages/ForgottenPasswordPage";
import ResetPasswordPage from "../../pages/ResetPasswordPage";
import ConfirmPage from "../../pages/ConfirmPage";
import LoadableNewContractPage from "../../pages/NewContractPage/Loadable";
import ExternalSalesRegisterPage from "../../pages/ExternalSalesRegisterPage";
import SsoPage from "../../pages/SsoPage";
import KulcsSoftSsoPage from "../../pages/SsoPage/kulcsSoftSsoPage";

const publicRoutes = [
  {
    path: "/auth/token/:token/company-id/:companyId",
    component: AuthPage
  },
  {
    path: "/login",
    component: LoginPage
  },
  {
    path: "/sso",
    component: SsoPage
  },
  {
    path: "/kulcssoft/sso",
    component: KulcsSoftSsoPage
  },
  {
    path: "/customer-confirmation/:token",
    component: LoadableCustomerConfirmationPage
  },
  {
    path: "/new-contract/:token",
    component: LoadableNewContractPage
  },
  {
    path: "/404",
    component: NotFoundPage
  },
  {
    path: "/register/external-sales/:token",
    component: ExternalSalesRegisterPage
  },
  {
    path: "/register/token/:token",
    component: RegisterPage
  },
  {
    path: "/register/confirm/:token",
    component: ConfirmPage
  },
  {
    path: "/register",
    component: RegisterPageWithoutToken
  },

  {
    path: "/forgotten-password",
    component: ForgottenPasswordPage
  },
  {
    path: "/resetting/reset/:token",
    component: ResetPasswordPage
  }
];

export default publicRoutes;
