import React from "react";
import { Paper } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { useProfileData } from "../../hooks/useApi";

const EmptySection = ({
  sectionTitle,
  sectionDescription,
  imgSrc,
  buttonText,
  buttonIcon,
  onClick
}) => {
  return (
    <Paper style={{ padding: "32px 40px", height: "calc(100% - 64px)" }}>
      <Grid container justify={"space-between"}>
        <Grid item xs={7}>
          <Typography variant={"h4"} style={{ paddingBottom: "24px" }}>
            {sectionTitle}
          </Typography>
          <Typography variant={"body2"} style={{ paddingBottom: "32px" }}>
            {sectionDescription
              ? sectionDescription
              : "Ez a szekció üres, mert még nem töltöttél fel számlákat. Kezdj faktorálni!"}
          </Typography>
          {buttonText &&
            buttonIcon && (
              <Button onClick={onClick} style={{ whiteSpace: "nowrap" }}>
                <img src={buttonIcon} style={{ paddingRight: "10px" }} />
                {buttonText}
              </Button>
            )}
        </Grid>
        <Grid item style={{ alignSelf: "center" }}>
          <img src={imgSrc} />
        </Grid>
      </Grid>
    </Paper>
  );
};
export default EmptySection;
