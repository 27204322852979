const defaultConfig = {
  APP_ENV: "developer",
  API_URL: "https://api.faktor.beta.flexibill.hu",
  FLEXIBILL_APP_URL: "https://beta.flexibill.hu",
  GA_MEASUREMENT_ID: "G-7PV37EHCHT"
};

const config = window.__APP_CONFIG__ || defaultConfig;

export const getConfig = name => config[name];

export const isBeta = getConfig("APP_ENV") === "staging";
export const isProd = getConfig("APP_ENV") === "production";

// const searchParams = new URLSearchParams(window.location.search);
export const szamlazzhuInUrl = true;
