import React, { useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.css";
import { IntlProvider } from "react-intl";
import Application from "./containers/Application";
import huMessages from "./messages/hu";
import { storeTargetUrl } from "./utils/authentication";
import { ThemeProvider as MUIThemeProvider } from "@material-ui/core/styles";
import { hotjar } from "react-hotjar";
import { isBeta, isProd } from "./utils/config";
import HealthCheck from "./components/HealthCheck";
import muiTheme from "./theme";
import { Box } from "@material-ui/core";
import { szamlazzMuiTheme } from "./theme";
import { useStoredData } from "./hooks/useDataState";

if (process.env.NODE_ENV === "production") {
  if (isBeta) {
    const HOTJAR_HJID = 2359316;
    const HOTJAR_HJSV = 6;
    hotjar.initialize(HOTJAR_HJID, HOTJAR_HJSV);
  }

  if (isProd) {
    const HOTJAR_HJID = 2359317;
    const HOTJAR_HJSV = 6;
    hotjar.initialize(HOTJAR_HJID, HOTJAR_HJSV);
  }
}
const App = () => {
  const szamlazzHu = useStoredData("szamlazzHu");
  const theme = szamlazzHu ? szamlazzMuiTheme : muiTheme;

  useEffect(() => {
    if (
      window.location.pathname !== "/login" &&
      !window.location.pathname.includes("/register")
    ) {
      storeTargetUrl(window.location.pathname);
    }
  }, []);

  return (
    <MUIThemeProvider theme={theme}>
      <IntlProvider locale={"hu"} messages={huMessages}>
        <HealthCheck>
          <Application />
        </HealthCheck>
      </IntlProvider>
    </MUIThemeProvider>
  );
};

export default App;
