import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";
import SvgIcon from "@material-ui/core/SvgIcon";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";

const StatusIcon = ({ icon, color, tooltipText, width }) => {
  return (
    <Tooltip title={tooltipText && tooltipText} arrow>
      <Avatar
        variant="circle"
        style={{
          backgroundColor: color && color,
          width: isWidthDown("sm", width) ? 16 : 30,
          height: isWidthDown("sm", width) ? 16 : 30
        }}
      >
        <SvgIcon
          style={{
            width: isWidthDown("sm", width) ? 14 : 20,
            height: isWidthDown("sm", width) ? 14 : 20
          }}
        >
          {icon && icon}
        </SvgIcon>
      </Avatar>
    </Tooltip>
  );
};

export default withWidth()(StatusIcon);
