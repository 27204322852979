import React from "react";
import { FieldArray } from "react-final-form-arrays";
import FileField from "./FileField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

const MultipleFileField = ({ name, onRemove, onDownload }) => {
  return (
    <FieldArray name={name}>
      {({ fields }) => {
        const allFieldHasFile = Boolean(
          !fields.length || !fields.value?.filter(item => !item).length
        );

        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                {fields.map((name, index) => {
                  const fieldValue = fields.value[index];
                  return (
                    <Grid item xs={12}>
                      <div>
                        <FileField
                          key={name}
                          name={`${name}.file`}
                          browseImmediately={!Boolean()}
                          onRemove={() =>
                            fieldValue?.file?.id
                              ? onRemove(fieldValue.file)
                              : fields.remove(index)
                          }
                          onDownload={onDownload}
                        />
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid item>
              <Button
                outlined
                disabled={!allFieldHasFile}
                onClick={() => fields.push(null)}
              >
                FÁJL HOZZÁADÁSA
              </Button>
            </Grid>
          </Grid>
        );
      }}
    </FieldArray>
  );
};

export default MultipleFileField;
