import { createStore } from "react-sweet-state";

const initialState = {
  fn: null,
  dialogTitle: null,
  dialogDescription: null,
  args: null
};

const confirmDialogStore = createStore({
  initialState,
  actions: {
    showConfirmDialog: ({ fn, dialogDescription, dialogTitle, args }) => ({
      setState
    }) => {
      setState({ fn, dialogDescription, dialogTitle, args });
    },
    hideConfirmDialog: () => ({ setState }) => setState(initialState)
  },
  name: "confirmDialog"
});

export default confirmDialogStore;
