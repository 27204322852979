import React, { useState } from "react";
import { AppBar, Tab, Tabs } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useNewContractPageData } from "../../hooks/useApi";

const TabsFromArray = ({ items, getTabTitle, getTabColor, renderContent }) => {
  const [selected, setSelected] = useState(0);
  const handleChange = (event, newValue) => {
    setSelected(newValue);
  };
  return (
    <>
      <Grid
        style={{ marginLeft: "8px", display: items.length === 1 && "none" }}
      >
        <AppBar
          position={"static"}
          style={{
            backgroundColor: "#fff",
            color: "#000",
            marginBottom: "-8px",
            boxShadow: "0 0 0"
          }}
        >
          <Tabs
            value={selected}
            onChange={handleChange}
            indicatorColor="primary"
          >
            {items.map((item, index) => (
              <Tab
                style={{
                  backgroundColor: getTabColor(item, index),
                  borderLeft: index !== 0 && "2px solid"
                }}
                label={getTabTitle(item, index)}
              />
            ))}
          </Tabs>
        </AppBar>
      </Grid>
      {renderContent(items[selected])}
    </>
  );
};
export default TabsFromArray;
