import * as React from "react";

const useFileToBase64 = file => {
  const [base64, setBase64] = React.useState(null);
  const [error, setError] = React.useState(null);

  React.useEffect(
    () => {
      if (file) {
        const fr = new FileReader();

        fr.onload = () => {
          setBase64(fr.result);
          setError(null);
        };
        fr.onerror = err => {
          setBase64(null);
          setError(err);
        };

        setBase64(null);
        setError(null);
        fr.readAsDataURL(file);
      }
    },
    [file]
  );

  return [base64, error];
};

export default useFileToBase64;
