import React from "react";
import { useHistory } from "react-router-dom";
import { defineMessages } from "react-intl.macro";
import makeInvoiceIdRenderer from "../../cellRenderers/makeInvoiceIdRenderer";
import { makeCurrencyValueFormatter } from "../../utils/makeCurrencyValueFormatter";
import moment from "moment";
import { useIntl } from "react-intl";
import {
  FeedbackChip,
  getChipTranslations
} from "../CustomerConfirmationPage/CustomerConfirmationList";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import Grid from "@material-ui/core/Grid";
import { getRejectReasonText } from "../CustomerConfirmationPage/RejectReasonDialog";
import FlexiExpandableRemoteList from "@bit/flexibill.flexi.flexi-expandable-remote-list";
import IconButton from "@material-ui/core/IconButton";

const makeCustomerConfirmationRenderer = (
  intl,
  isFinanceNotificationRequired
) => ({ value, data }) => {
  if (
    value === "APPROVED" ||
    value === "ON_HOLD" ||
    value === "REJECTED" ||
    value === "PENDING" ||
    (!value && !isFinanceNotificationRequired)
  ) {
    const declineReasonText = getRejectReasonText(data.customerDeclineReason);
    return (
      <Grid container wrap={"nowrap"} alignItems={"center"}>
        <FeedbackChip
          size="small"
          label={intl.formatMessage(getChipTranslations(value))}
          status={value}
          style={{ minWidth: "auto" }}
        />
        {declineReasonText && (
          <Tooltip
            title={`${declineReasonText} ${
              data.customerDeclineComment
                ? ` - ${data.customerDeclineComment}`
                : ""
            }`}
          >
            <div>
              <InfoIcon style={{ fontSize: "1rem", marginTop: 20 }} />
            </div>
          </Tooltip>
        )}
      </Grid>
    );
  }

  if (value === undefined) {
    return <Typography>-</Typography>;
  }

  return null;
};

const columnMessages = defineMessages({
  invoiceId: {
    id: "listColumnHeader.invoiceGroup.invoiceId",
    defaultMessage: "Számla sorszáma"
  },
  totalGrossAmount: {
    id: "listColumnHeader.invoiceGroup.totalGrossAmount",
    defaultMessage: "Bruttó összeg"
  },
  compensation: {
    id: "listColumnHeader.invoiceGroup.compensation",
    defaultMessage: "Beszámítás összege"
  },
  dueDate: {
    id: "listColumnHeader.invoiceGroup.dueDate",
    defaultMessage: "Lejárat"
  },
  modifiedDueDate: {
    id: "listColumnHeader.invoiceGroup.modifiedDueDate",
    defaultMessage: "Módosított lejárat"
  },
  customerConfirmationStatus: {
    id: "listColumnHeader.invoiceGroup.customerConfirmationStatus",
    defaultMessage: "Visszaigazolás"
  }
});

const dateValueFormatter = ({ value }) =>
  value && moment(value).format("YYYY.MM.DD");

const InvoiceGroupList = ({ invoiceGroup, onAttachmentClick }) => {
  const items = invoiceGroup?.invoices;
  const intl = useIntl();
  const { push: redirect } = useHistory();

  const currencyFormatter = makeCurrencyValueFormatter(intl);

  const makeCompensationRenderer = () => ({ value, data }) => {
    if (value === 0) {
      return <Typography>-</Typography>;
    }

    return <Typography>{currencyFormatter({ value, data })}</Typography>;
  };

  const columnDefs = [
    {
      field: "invoiceId",
      headerName: intl.formatMessage(columnMessages.invoiceId),
      cellRendererFramework: makeInvoiceIdRenderer(onAttachmentClick)
    },
    {
      field: "totalGrossAmount",
      headerName: intl.formatMessage(columnMessages.totalGrossAmount),
      valueFormatter: makeCurrencyValueFormatter(intl)
    },
    {
      field: "compensation",
      headerName: intl.formatMessage(columnMessages.compensation),
      valueFormatter: currencyFormatter,
      cellRendererFramework: makeCompensationRenderer(intl)
    },
    {
      field: "dueDate",
      headerName: intl.formatMessage(columnMessages.dueDate),
      valueFormatter: dateValueFormatter
    },
    {
      field: "modifiedDueDate",
      headerName: intl.formatMessage(columnMessages.modifiedDueDate),
      valueFormatter: dateValueFormatter
    },
    {
      field: "customerConfirmationStatus",
      headerName: intl.formatMessage(columnMessages.customerConfirmationStatus),
      cellRendererFramework: makeCustomerConfirmationRenderer(
        intl,
        invoiceGroup?.isFinanceNotificationRequired
      )
    },
    {
      field: "id",
      headerName: "",
      cellRendererFramework: ({ data }) =>
        data.isEditable && (
          <IconButton
            size={"small"}
            onClick={() => redirect(`/app/invoice/edit/${data?.id}`)}
            style={{
              marginLeft: "auto",
              marginRight: "3px"
            }}
          >
            <EditIcon />
          </IconButton>
        )
    }
  ];

  return (
    <FlexiExpandableRemoteList
      columnDefs={columnDefs}
      localItems={items || []}
      suppressExpand
    />
  );
};

export default InvoiceGroupList;
