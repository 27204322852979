/**
 *
 * LogoutPage
 *
 */

import React from "react";
import { Redirect } from "react-router-dom";
import { resetAuthData, resetTargetUrl } from "../../utils/authentication";
import { useProfile } from "../../hooks/useApi";
import { resetListStoredData } from "@bit/flexibill.flexi.flexi-expandable-remote-list";

const LogoutPage = () => {
  resetTargetUrl();
  resetAuthData();
  resetListStoredData();
  const { mutate } = useProfile();
  mutate({}, false);
  return <Redirect to="/login" />;
};

export default LogoutPage;
