import Loadable from "react-loadable";
import { LinearProgress } from "@material-ui/core";
import React from "react";

const LoadableNewContractPage = Loadable({
  loader: () => import("./index"),
  loading: () => <LinearProgress />
});

export default LoadableNewContractPage
