import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import { useMutator } from "../../hooks/useApi";
import { selectErrorNotificationMessage } from "../../utils/selectErrorMessage";
import { useErrorOccurredNotification } from "../../hooks/useNotificationState";
import Footer from "../../components/Footer";
import ResetPasswordForm from "./ResetPasswordForm";
import { isWidthUp } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import selectFieldErrorsFromResponse from "../../utils/selectFieldErrorsFromResponse";
import { getFlexipayUrl } from "../../utils/urlHelpers";
import { resetTargetUrl } from "../../utils/authentication";
import DesktopWelcomeHeader from "../../components/WelcomeHeader/desktop";
import MobileWelcomeHeader from "../../components/WelcomeHeader/mobile";
import FlexiLinearProgress from "../../components/FlexiLinearProgress";
import withWidth from "@material-ui/core/withWidth";
import lockImage from "../../images/redesignImages/Ikon.svg";

const ResetPasswordPage = props => {
  const token = props.match?.params?.token;
  const [post, postLoading] = useMutator("POST");
  const [success, setSuccess] = useState(false);
  const notifyError = useErrorOccurredNotification();

  return (
    <Grid container>
      {isWidthUp("md", props.width) ? (
        <Grid container direction={"row"}>
          <Grid item xs={6}>
            <DesktopWelcomeHeader />
          </Grid>
          <Grid item xs>
            <Grid item container alignItems={"center"} justify={"center"}>
              <Grid item>
                {!success ? (
                  <Grid item xs>
                    {postLoading && <FlexiLinearProgress />}
                    <Grid
                      container
                      justify={"center"}
                      style={{
                        padding: "0 1rem 1rem",
                        width: "410px",
                        textAlign: "center"
                      }}
                    >
                      <Grid item xs={12} style={{ paddingTop: "160px" }}>
                        <img alt={"lockImage"} src={lockImage} />{" "}
                        <ResetPasswordForm
                          onSubmit={async values => {
                            resetTargetUrl();
                            const { data, error } = await post(
                              getFlexipayUrl("/password/reset/confirm"),
                              { ...values, token }
                            );
                            if (data) {
                              setSuccess(true);
                            }
                            if (error) {
                              notifyError(
                                selectErrorNotificationMessage(error)
                              );
                              return selectFieldErrorsFromResponse(error);
                            }
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid
                    container
                    align={"center"}
                    direction={"column"}
                    style={{ padding: "0 1rem 1rem", width: "410px" }}
                  >
                    <Grid item xs={12}>
                      <Typography
                        variant={"h5"}
                        align={"center"}
                        style={{ marginTop: "310px", marginBottom: 32 }}
                      >
                        Sikeres jelszóváltoztatás
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography align="center" variant="body2">
                        <Link
                          style={{
                            textDecoration: "none ",
                            color: "#2227AC",
                            fontWeight: "bold"
                          }}
                          href="/login"
                        >
                          Vissza a bejelentkezéshez
                        </Link>
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12} style={{ position: "fixed", bottom: 18 }}>
                <Footer />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid container>
          <Grid item xs={12} style={{ paddingBottom: "55px" }}>
            <MobileWelcomeHeader />
          </Grid>
          {!success ? (
            <Grid container>
              <Grid item xs={12}>
                <ResetPasswordForm
                  onSubmit={async values => {
                    resetTargetUrl();
                    const { data, error } = await post(
                      getFlexipayUrl("/password/reset/confirm"),
                      { ...values, token }
                    );
                    if (data) {
                      setSuccess(true);
                    }
                    if (error) {
                      notifyError(selectErrorNotificationMessage(error));
                      return selectFieldErrorsFromResponse(error);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} style={{ position: "fixed", bottom: "27px" }}>
                <Footer />
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              direction={"column"}
              style={{ padding: "0 1rem 1rem", width: "410px" }}
            >
              <Grid
                item
                xs={12}
                style={{ paddingTop: "105px", paddingBottom: 30 }}
              >
                <Typography
                  variant={"h5"}
                  align={"center"}
                  style={{ marginBottom: 30 }}
                >
                  Sikeres jelszóváltoztatás
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  align="center"
                  variant="body2"
                  style={{ marginTop: "20px" }}
                >
                  <Link
                    style={{
                      color: "#3f51b5",
                      textDecoration: "none",
                      fontWeight: "bold"
                    }}
                    href="/login"
                  >
                    Vissza a bejelentkezéshez
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ position: "fixed", bottom: "27px" }}>
                <Footer />
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
};
export default withWidth()(ResetPasswordPage);
