import AgreementClaims from "../../../pages/ExternalSales/ExternalSalesAgreementClaims";
import InvoiceIcon from "@material-ui/icons/InsertDriveFile";
import React from "react";
import PersonIcon from "@material-ui/icons/Person";
import ProfileDataPage from "../../../pages/ProfileDataPage";
import ExternalSalesNewContractPage from "../../../pages/ExternalSales/ExternalSalesNewContractPage";

const externalSalesRoutes = [
  {
    path: "/agreement-claims/new-contract/:companyId?/:id?",
    component: ExternalSalesNewContractPage
  },
  {
    path: "/agreement-claims",
    icon: <InvoiceIcon />,
    text: "Szerződés igények",
    menuItem: true,
    component: AgreementClaims
  },
  {
    path: "/profile/profile-data",
    text: "Profil",
    icon: <PersonIcon />,
    menuItem: true,
    component: ProfileDataPage
  }
].map(route => {
  const item = { ...route, path: `/external-sales${route.path}` };
  if (item.subMenuItems) {
    item.subMenuItems = item.subMenuItems.map(subMenuItem => ({
      ...subMenuItem,
      path: `/external-sales${subMenuItem.path}`
    }));
  }
  return item;
});

export default externalSalesRoutes;
