import { getConfig } from "./config";
import { getStoredCompanyId } from "./authentication";

export const getGuestUrl = url =>
  `${getConfig("API_URL")}/flexipay/guest${url}`;

export const getUrl = url => `${getConfig("API_URL")}${url}`;

export const getFlexipayUrl = url => `${getUrl("/flexipay")}${url}`;

export const getUrlWithCompanyId = url =>
  `${getFlexipayUrl("")}/company/${getStoredCompanyId()}${url}`;

export const getUrlWithCustomCompanyId = (url, companyId) => {
  return `${getFlexipayUrl("")}/company/${companyId}${url}`;
};
