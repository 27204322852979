import { createStore } from "react-sweet-state";

const initialState = {};

const dataStore = createStore({
  initialState,
  actions: {
    set: (key, data) => ({ getState, setState }) => {
      setState({ ...getState(), [key]: data });
    }
  }
});

export default dataStore;
