import FlexiPageLayout from "@bit/flexibill.flexi.flexi-page-layout";
import {
  agreementClaimStatusHelper,
  getAgreementClaimStatus,
  getAgreementClaimStepperSteps
} from "../../NewContractPage";
import DescriptionIcon from "@material-ui/icons/Description";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import FlexiIconStepper from "@bit/flexibill.flexi.flexi-icon-stepper";
import { Box, Button, Grid, Paper, Typography } from "@material-ui/core";
import FlexiForm, { Fields } from "@bit/flexibill.flexi.flexi-form";
import { getFlexipayUrl, getUrlWithCompanyId } from "../../../utils/urlHelpers";
import CalculatorForm from "../../NewContractPage/CalculatorForm";
import {
  useExternalSalesAddCustomer,
  useExternalSalesAgreementClaim,
  useExternalSalesCompanyRegistration,
  useExternalSalesCreateAgreementClaim,
  useExternalSalesNewContractPageData,
  useExternalSalesSendAgreementClaim,
  useMutator
} from "../../../hooks/useApi";
import { selectErrorNotificationMessage } from "../../../utils/selectErrorMessage";
import {
  useErrorOccurredNotification,
  useSaveSuccessNotification
} from "../../../hooks/useNotificationState";
import ClaimsReview from "../../NewContractPage/ClaimsReview";
import BillSection from "../../../components/BillSection";
import billHappy from "../../../images/bill/new/bill-happy.svg";
import Hidden from "@material-ui/core/Hidden";
import FlexiInfoBar from "@bit/flexibill.flexi.flexi-info-bar";
import { Claims } from "../../NewContractPage/Claims";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { getStoredToken } from "../../../utils/authentication";

const stepOne = {
  label: "Ajánlatadás",
  status: "CUSTOMER_UPLOAD",
  icon: <DescriptionIcon />
};

const [, ...restSteps] = getAgreementClaimStepperSteps();

const steps = [stepOne, ...restSteps];

const ExternalSalesNewContractPage = () => {
  const { id, companyId: companyIdFromUrl } = useParams();
  const [companyId, setCompanyId] = useState(companyIdFromUrl);
  const [status, setStatus] = useState("CUSTOMER_UPLOAD");
  const [edit, setEdit] = useState(false);
  const [invited, setInvited] = useState(false);
  const history = useHistory();
  const [post] = useMutator("POST");
  const notifyFailure = useErrorOccurredNotification();
  const notifySuccessSave = useSaveSuccessNotification();
  // Ez az uj

  const {
    agreementClaim,
    reloadAgreementClaim,
    customerDocumentTypes,
    customerAgreementClaims,
    downloadDocument,
    deleteDocument,
    supplierDocumentTypes,
    uploadDocuments,
    supplierAgreementClaims,
    agreementClaimLoading,
    loading
  } = useExternalSalesNewContractPageData({
    token: getStoredToken(),
    id,
    companyId: companyIdFromUrl
  });

  // eddig
  const stepperValue = getAgreementClaimStatus(
    agreementClaim?.status || status,
    {
      ...agreementClaimStatusHelper,
      CUSTOMER_UPLOAD: ["CUSTOMER_UPLOAD"],
      CALCULATION: ["CALCULATION", "REGISTRATION"]
    }
  );
  const [
    registerCompany,
    registerCompanyLoading
  ] = useExternalSalesCompanyRegistration();
  const [
    createAgreementClaim,
    createAgreementClaimLoading
  ] = useExternalSalesCreateAgreementClaim();
  const [addCustomer, addCustomerLoading] = useExternalSalesAddCustomer();
  const [
    sendAgreementClaim,
    sendAgreementClaimLoading
  ] = useExternalSalesSendAgreementClaim();

  const allLoading =
    registerCompanyLoading ||
    createAgreementClaimLoading ||
    addCustomerLoading ||
    sendAgreementClaimLoading ||
    loading;

  const statusWithEdit = useMemo(
    () => {
      if (edit) {
        return "CUSTOMER_UPLOAD";
      }
      return stepperValue;
    },
    [stepperValue, edit]
  );

  const nextFnOnDocumentUploadPage = async () => {
    const { data, error } = await post(
      getFlexipayUrl(
        `/company/${companyId}/contractAgreementClaim/${id}/submit`
      )
    );
    if (error) {
      notifyFailure({ text: error.message });
    }
    if (data) {
      reloadAgreementClaim();
    }
  };
  const rightSideComponents = useMemo(
    () => {
      if (agreementClaim?.status === "DOCUMENT_UPLOAD") {
        return [
          {
            props: {
              children: "Mentés",
              onClick: () =>
                notifySuccessSave({
                  text:
                    "Ha kilépsz, innen tudod később folytatni, adataid nem vesznek el!"
                })
              // disabled: hasDocumentUploaderRole
            },
            mobile: {
              placement: "toolbar"
            }
          },
          {
            props: {
              children: "Tovább",
              onClick: nextFnOnDocumentUploadPage
            },
            mobile: {
              placement: "toolbar"
            }
          }
        ];
      }
    },
    [agreementClaim]
  );

  useEffect(
    () => {
      if (agreementClaim) {
        setStatus(agreementClaim.status);
      }
    },
    [agreementClaim]
  );

  const showProcessEnd =
    statusWithEdit !== "CUSTOMER_UPLOAD" &&
    statusWithEdit !== "CALCULATION" &&
    statusWithEdit !== "REGISTRATION" &&
    statusWithEdit !== "DOCUMENT_UPLOAD";

  return (
    <FlexiPageLayout
      loading={allLoading}
      title={steps.find(step => step.status === stepperValue).label}
      backButton
      onBackButtonClick={() => history.push("/external-sales/agreement-claims")}
      rightSideComponents={rightSideComponents}
    >
      <FlexiIconStepper items={steps} value={stepperValue} />

      {!invited &&
        (agreementClaim || !agreementClaimLoading) && (
          <React.Fragment>
            {statusWithEdit === "CUSTOMER_UPLOAD" &&
              !agreementClaim && (
                <div>
                  <FlexiForm
                    FinalFormFormProps={{
                      onSubmit: async values => {
                        const { data, error } = await registerCompany(values);
                        if (!data?.invite) {
                          if (data?.companyId) {
                            const claimResponse = await createAgreementClaim({
                              companyId: data?.companyId
                            });
                            history.push(
                              `/external-sales/agreement-claims/new-contract/${
                                data.companyId
                              }/${claimResponse.data.id}`
                            );
                          } else {
                            notifyFailure(
                              selectErrorNotificationMessage(error)
                            );
                          }
                        } else {
                          setInvited(true);
                        }
                      }
                    }}
                    render={({ invalid, form }) => {
                      return (
                        <div>
                          <Paper>
                            <Grid container spacing={2}>
                              <Grid item md={6}>
                                <Fields
                                  config={[
                                    [
                                      {
                                        fieldType: "bisnodeCompanySearch", // todo /flexipay-es urlt csinaltatni
                                        url: getFlexipayUrl(
                                          "/company/information/companies"
                                        ).replace("flexipay/", ""),
                                        easyStructure: true,
                                        name: `party`,
                                        label: "Ügyfél neve",
                                        required: true,
                                        icon: null
                                      }
                                    ]
                                  ]}
                                />
                              </Grid>
                              <Grid item md={6}>
                                <Fields
                                  config={[
                                    [
                                      {
                                        fieldType: "text",
                                        label: "Vezetéknév",
                                        name: "surname",
                                        required: true
                                      },
                                      {
                                        fieldType: "text",
                                        label: "Keresztnév",
                                        name: "firstname",
                                        required: true
                                      }
                                    ],
                                    [
                                      {
                                        fieldType: "text",
                                        label: "Kapcsolattartó e-mail címe",
                                        name: "email",
                                        required: true
                                      }
                                    ],
                                    [
                                      {
                                        fieldType: "text",
                                        label: "Kapcsolattartó telefonszáma",
                                        name: "phoneNumber",
                                        required: true
                                      }
                                    ]
                                  ]}
                                />
                              </Grid>
                            </Grid>
                          </Paper>
                          <Box
                            display={"flex"}
                            justifyContent={"center"}
                            style={{ gap: "1rem" }}
                            mt={4}
                          >
                            <Button
                              disabled={invalid}
                              type={"submit"}
                              onClick={() =>
                                form.mutators.setValue("invite", true)
                              }
                            >
                              Ügyfél meghívása
                            </Button>
                            <Button
                              type={"submit"}
                              onClick={() =>
                                form.mutators.setValue("invite", false)
                              }
                              disabled={invalid}
                            >
                              Tovább
                            </Button>
                          </Box>
                        </div>
                      );
                    }}
                  />
                </div>
              )}
            {statusWithEdit === "CUSTOMER_UPLOAD" &&
              agreementClaim && (
                <Paper>
                  <CalculatorForm
                    initialValues={
                      agreementClaim?.customers &&
                      agreementClaim?.customers.length
                        ? {
                            companies: agreementClaim?.customers.map(item => ({
                              calculation: {
                                natureOfTransaction:
                                  item.calculation.natureOfTransaction,
                                needInsurance: item.calculation.needInsurance.toString(),
                                currency: item.calculation.currency,
                                monthlyInvoicesTotal:
                                  item.calculation.monthlyInvoicesTotal,
                                invoiceTerms: item.calculation.invoiceTerms
                              },
                              localBusiness:
                                item.party.registrationAddress.country === "HU"
                                  ? "true"
                                  : "false",
                              party: item.party
                            }))
                          }
                        : undefined
                    }
                    onSubmit={async values => {
                      let _id = id;
                      if (!agreementClaim) {
                        const claimResponse = await createAgreementClaim();
                        _id = claimResponse.data.id;
                      }

                      await addCustomer({
                        ...values,
                        companyId: companyIdFromUrl,
                        id: _id
                      });
                      await reloadAgreementClaim();
                      setEdit(false);
                    }}
                  />
                </Paper>
              )}

            {statusWithEdit === "CALCULATION" &&
              !agreementClaim.lastNotificationDate &&
              agreementClaim?.customers && (
                <div>
                  <ClaimsReview
                    data={agreementClaim}
                    showDetailedCalculation={
                      agreementClaim.showDetailedCalculation
                    }
                    renderActionButtons={() => {
                      return (
                        <Grid container spacing={2} justifyContent={"center"}>
                          <Grid item>
                            <Button
                              variant={"outlined"}
                              onClick={() => setEdit(true)}
                            >
                              Módosítom
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              onClick={async () => {
                                await sendAgreementClaim({
                                  companyId: companyIdFromUrl,
                                  id
                                });
                                await reloadAgreementClaim();
                              }}
                            >
                              Küldés az ügyfélnek
                            </Button>
                          </Grid>
                        </Grid>
                      );
                    }}
                  />
                </div>
              )}

            {statusWithEdit === "CALCULATION" &&
              agreementClaim.lastNotificationDate && (
                <BillSection title={"Egyelőre készen vagy!"}>
                  <Typography>
                    Ha az ügyfél beregisztrál a felületre, megtekintheti és
                    elfogadhatja az általad küldött ajánlatot! Amennyiben az
                    ügyfél visszahívást kér, a Módosítom gombra kattinva még
                    változtathatsz az adatokon.
                  </Typography>
                  <Button variant={"outlined"} onClick={() => setEdit(true)}>
                    Módosítom
                  </Button>
                </BillSection>
              )}

            {statusWithEdit === "DOCUMENT_UPLOAD" && (
              <React.Fragment>
                <Grid container>
                  <Hidden mdUp>
                    <Box mb={2}>
                      <FlexiInfoBar info>
                        <Typography>
                          <b>PRO TIPP: </b>Ezt a lépést gyorsabban elvégezheted,
                          ha számítógépről jelentkezel be!
                        </Typography>
                      </FlexiInfoBar>
                    </Box>
                  </Hidden>
                  <Claims
                    title={"Szállítói adatok"}
                    items={supplierAgreementClaims}
                    onUploadDocuments={values =>
                      uploadDocuments(values, "supplier")
                    }
                    Icon={LocalShippingIcon}
                    documentTypes={supplierDocumentTypes}
                    onDocumentRemove={values =>
                      deleteDocument(values, "supplier")
                    }
                    onDocumentDownload={downloadDocument}
                  />
                  <hr style={{ marginTop: 0, marginBottom: "50px" }} />
                  <Claims
                    title={"Vevői adatok"}
                    items={customerAgreementClaims}
                    onUploadDocuments={values =>
                      uploadDocuments(values, "customer")
                    }
                    Icon={AccountCircleIcon}
                    documentTypes={customerDocumentTypes}
                    onDocumentRemove={values =>
                      deleteDocument(values, "customer")
                    }
                    onDocumentDownload={downloadDocument}
                  />
                </Grid>
              </React.Fragment>
            )}

            {showProcessEnd && (
              <BillSection title={"Nagyon mosolygó Bill"}>
                <Typography>fancy szöveggel</Typography>
              </BillSection>
            )}
          </React.Fragment>
        )}

      {invited && (
        <BillSection title={"Nagyon mosolygó Bill"}>
          <Typography>fancy szöveggel</Typography>
        </BillSection>
      )}
    </FlexiPageLayout>
  );
};

export default ExternalSalesNewContractPage;
