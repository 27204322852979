import { Switch, Route, Redirect } from "react-router-dom";
import publicRoutes from "./publicRoutes";
import React from "react";
import { getStoredToken } from "../../utils/authentication";

const OuterApplication = () => {
  return (
    <Switch>
      {publicRoutes.map(route => (
        <Route
          path={route.path}
          exact={route.exact}
          component={route.component}
        />
      ))}
      <Redirect to={!getStoredToken() ? "/login" : "/app"} />
    </Switch>
  );
};

export default OuterApplication;
