import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

const DisabledGrid = withStyles({
  root: {
    pointerEvents: props => props.checked === true && "none",
    opacity: props => props.checked === true && 0.3
  }
})(Grid);

const DisabledByCheckbox = ({ children, label }) => {
  const [checked, setChecked] = useState(false);

  return (
    <Grid container>
      <DisabledGrid checked={checked} item xs={12}>
        {children}
      </DisabledGrid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox checked={checked} onChange={() => setChecked(!checked)} />
          }
          label={label}
        />
      </Grid>
    </Grid>
  );
};

export default DisabledByCheckbox;
