import { getStoredToken, resetAuthData } from "./authentication";

const getAuthHeader = () => {
  const token = getStoredToken();

  if (token) {
    return {
      Authorization: `Bearer ${token}`
    };
  }

  return {};
};

const fetcher = async (url, options = {}) => {
  const optionsWithAuthHeader = {
    ...options,
    headers: { ...options.headers, ...getAuthHeader() }
  };

  const response = await fetch(url, optionsWithAuthHeader);
  const json = await response.json();

  if (!response.ok) {
    if (response.statusCode === 401) {
      resetAuthData();
      window.location.href = "/login";
    } else {
      const error = new Error(json.message || json.error);
      error.response = json;
      throw error;
    }
  }

  return json;
};

export { fetcher };
