import React, { useState } from "react";
import FlexiDialog from "@bit/flexibill.flexi.flexi-dialog";
import { Box, Button, Typography } from "@material-ui/core";
import FlexiForm, {
  makeExternalFormSubmit
} from "@bit/flexibill.flexi.flexi-form";
import yesBillImage from "../../images/bill/2_bill_grin.svg";

const formId = "HBNYRegistrationForm";
const submit = makeExternalFormSubmit(formId);

const HBNYRegistrationDialogs = ({ agreementClaimId }) => {
  const localStorageKey = `${agreementClaimId}-HBNYRegistrationDialogShown`;
  const [firstOpen, setFirstOpen] = useState(
    !window.localStorage.getItem(localStorageKey)
  );
  const [yesOpen, setYesOpen] = useState(false);
  const [noOpen, setNoOpen] = useState(false);

  return (
    <>
      <FlexiDialog
        open={firstOpen}
        title={"HBNY Regisztráció"}
        dialogActions={<Button onClick={submit}>Rendben</Button>}
      >
        <Typography variant={"h6"}>
          Rendelkezel HBNY (Hitelbiztosítéki Nyilvántartás) regisztrációval?
        </Typography>
        <Box marginLeft={"12px"}>
          <FlexiForm
            id={formId}
            FinalFormFormProps={{
              onSubmit: ({ answer }) => {
                setFirstOpen(false);

                if (answer === "yes") {
                  setYesOpen(true);
                } else {
                  setNoOpen(true);
                }
              },
              initialValues: { answer: "yes" }
            }}
            fields={[
              [
                {
                  fieldType: "radio",
                  name: `answer`,
                  label: "Igen",
                  value: "yes"
                },
                {
                  fieldType: "radio",
                  name: `answer`,
                  label: "Nem",
                  value: "no"
                }
              ]
            ]}
          />
        </Box>
      </FlexiDialog>
      <FlexiDialog
        open={yesOpen}
        imgSrc={yesBillImage}
        dialogActions={
          <Button
            onClick={() => {
              setYesOpen(false);
              window.localStorage.setItem(localStorageKey, "1");
            }}
          >
            Rendben
          </Button>
        }
      >
        <Typography variant={"h5"}>Szuper!</Typography>
        <br />
        <Typography>
          Amennyiben rendelkezel HBNY regisztrációval, nincs ezzel kapcsolatban
          további teendőd!
        </Typography>
      </FlexiDialog>
      <FlexiDialog
        open={noOpen}
        title={"HBNY Regisztráció"}
        dialogActions={
          <Button
            onClick={() => {
              setNoOpen(false);
              window.localStorage.setItem(localStorageKey, "1");
            }}
          >
            Rendben
          </Button>
        }
      >
        <Typography>
          A szerződéskötéshez nem szükséges, hogy céged szerepeljen a
          Hitelbiztosítéki Nyilvántartásban, viszont a faktorált számláid
          folyósításához szükséged lesz rá. Amennyiben ez még nem történt meg,
          közjegyző segítségével regisztrálhatod a céged, de addig is a
          szerződést alá tudod írni.
        </Typography>
      </FlexiDialog>
    </>
  );
};

export default HBNYRegistrationDialogs;
