import PageLayout from "../../components/PageLayout";
import React from "react";
import {defineMessages} from "react-intl.macro";
import TasksList from "./TasksList";

const messages = defineMessages({
  pageTitle: {
    id: "pageTitle.tasksPage",
    defaultMessage: "Feladatok"
  },
});

const TasksPage = () => {
  return (
    <PageLayout
      insidePage
      pay
      titleMessage={messages.pageTitle}
    >
      <div><TasksList/></div>    </PageLayout>
  );
};
export default TasksPage;
