import "whatwg-fetch";
import { useCallback } from "react";

const useFetch = ({ token, blob }) => {
  return useCallback((url, options) => {
    let headers;

    if (token) {
      headers = {
        Authorization: `Bearer ${token}`
      };
    }
    return fetch(url, {
      headers,
      ...options
    }).then(resp => {
      if (resp.ok) {
        return blob ? resp.blob() : resp.json();
      }

      return resp.json().then(data => {
        throw new Error(JSON.stringify(data));
      });
    });
  }, []);
};

export default useFetch;
