import Grid from "@material-ui/core/Grid";
import React from "react";
import Typography from "@material-ui/core/Typography";

/* eslint-disable */
const Footer = ({ insidePage }) => (
  <Grid
    container
    style={{
      margin: "15px 0 15px 0",
      position: insidePage && "absolute",
      bottom: insidePage && 0
    }}
  >
    <Grid item xs={12} align="center">
      <Typography
        variant="body2"
        style={{ color: "#858585", fontSize: "12px" }}
      >
        FlexiBill - Digitális Faktoring® -{" "}
        <a href="mailto:hello@flexibill.hu" style={{ color: "#858585" }}>
          hello@flexibill.hu
        </a>
      </Typography>
    </Grid>
  </Grid>
);

export default Footer;
