import PageLayout from "../../components/PageLayout";
import InvoiceForm from "../../components/InvoiceForm";
import React from "react";
import { useHistory } from "react-router-dom";
import { useErrorOccurredNotification } from "../../hooks/useNotificationState";
import { selectErrorNotificationMessage } from "../../utils/selectErrorMessage";
import { useInvoiceEditPageData } from "../../hooks/useApi";
import selectFieldErrorsFromResponse from "../../utils/selectFieldErrorsFromResponse";
import useHasPermission from "../../components/RoleProvider/useHasPermission";
import { ROLE_FLEXIPAY_CUSTOMER_PLUS } from "../../roles";

const submitInvoiceForm = () => {
  document
    .getElementById("invoiceForm")
    .dispatchEvent(new Event("submit", { cancelable: true }));
};

const InvoiceEditPage = ({ match }) => {
  const {
    agreements,
    invoice,
    modify,
    deleteAttachment,
    loading,
    reloadInvoice
  } = useInvoiceEditPageData(match.params.id);
  const hasPermission = useHasPermission();
  const isScf = hasPermission([ROLE_FLEXIPAY_CUSTOMER_PLUS]);
  const history = useHistory();
  const notifyError = useErrorOccurredNotification();

  return (
    <PageLayout
      insidePage
      pay
      backButton
      titleMessage={{
        id: "pageTitle.invoiceFormPage",
        defaultMessage: "Számla szerkesztése"
      }}
      rightSideComponents={[
        {
          props: {
            children: "Mentés",
            disabled: loading,
            onClick: submitInvoiceForm
          },
          mobile: {
            placement: "toolbar"
          }
        }
      ]}
      loading={loading}
    >
      <InvoiceForm
        reloadInvoice={reloadInvoice}
        initialValues={invoice}
        deleteAttachment={deleteAttachment}
        onSubmit={async values => {
          const { files, ...invoiceValues } = values;

          const { data: successResponse, error } = await modify(
            invoiceValues,
            files.filter(file => !file.file.id)
          );

          if (successResponse) {
            history.goBack();
          } else {
            notifyError(selectErrorNotificationMessage(error));
            return selectFieldErrorsFromResponse(error);
          }
        }}
        partnerOptions={
          isScf
            ? (agreements || [])
                .filter(contract => contract.customerFactoring)
                .map(contract => ({
                  value: contract.agreementId,
                  text: contract.customerName,
                  currency: contract.currency
                }))
            :
          (agreements || []).map(contract => ({
                value: contract.agreementId,
                text: contract.customerName,
                currency: contract.currency
              }))
        }
      />
    </PageLayout>
  );
};

export default InvoiceEditPage;
