import dnload from "downloadjs";

var extToMimes = {
  img: "image/jpeg",
  jpg: "image/jpeg",
  jpeg: "image/jpeg",
  png: "image/png",
  pdf: "application/pdf"
};

function download(filename, text) {
  const parts = filename.split(".");
  const mimeType = extToMimes[parts[parts.length - 1]];
  dnload(`data:${mimeType};base64,${text}`, filename, mimeType);
}

export default download;
