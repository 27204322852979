import { defineMessages } from "react-intl.macro";

export const politeMessages = defineMessages({
  titlePolite: {
    id: "titlePolite.welcomeLayout",
    defaultMessage: `Üdvözöljük a FlexiBill - </br>Digitális Faktoring felületén!`
  },
  textPolite: {
    id: "textPolite.welcomeLayout",
    defaultMessage: `Regisztrációja véglegesítéséhez </br> kérem, adja meg jelszavát!`
  },
  status1Polite: {
    id: "status1.invoiceGroups",
    defaultMessage: `A számlák valódiságát Önnek még vissza kell igazolnia.`
  },
  status2Polite: {
    id: "status2.invoiceGroups",
    defaultMessage: `Alá kell írnia a faktorálási értesítő tudomásulvételét.`
  },
  status3Polite: {
    id: "status3.invoiceGroups",
    defaultMessage: `A számlákat visszaigazolta és amennyiben szükséges, aláírta a dokumentumot.`
  },
  status4Polite: {
    id: "status4.invoiceGroups",
    defaultMessage: `A számlákat még vissza kell igazolnia.`
  },
  status5Polite: {
    id: "status5.invoiceGroups",
    defaultMessage: `A számlacsoportban lévő számlák mindegyikét elutasította.`
  },
  infoBarPolite: {
    id: "infoBar.invoiceGroups",
    defaultMessage: `Kérem, igazolja vissza az oldalon található számlákat! Ha ez megtörtént, a véglegesítéshez kattintson a Mentés gombra!<u></u> <b>A visszaigazolásról bővebben ITT olvashat!</b></u>`
  },
  confirmTextPolite: {
    id: "textPolite.invoiceCofirmation",
    defaultMessage: `A számlákat az alábbiak szerint igazolhatja vissza:`
  },
  confirmStatus1Polite: {
    id: "confirmStatus1.invoiceCofirmation",
    defaultMessage: `Itt lehet megadni, ha a számla összegéből szeretne beszámítást levonni, illetve megadhatja, hogy mikor várható a számla tényleges kifizetése.`
  },
  confirmStatus2Polite: {
    id: "confirmStatus2.invoiceCofirmation",
    defaultMessage: `A számla a valóságnak megfelel és minden rendben van vele. Kifizetését tervezi a vállalat.`
  },
  confirmStatus3Polite: {
    id: "confirmStatus3.invoiceCofirmation",
    defaultMessage: `A számlával valami probléma van, így ebben a formában nem tervezi kifizetni.`
  },
  confirmStatus4Polite: {
    id: "confirmStatus4.invoiceCofirmation",
    defaultMessage: `A számlával kapcsolatban nincs elég információja a visszajelzéshez, így később szeretne válaszolni. A számla visszaigazolásával kapcsolatban a Flexibill később újra emlékeztetőt fog küldeni Önnek`
  },
  confirmDialogTitlePolite: {
    id: "confirmDialogTitle.invoiceCofirmation",
    defaultMessage: `Még nem igazolt vissza minden számlát!`
  },
  confirmDialogTextPolite: {
    id: "confirmDialogTitle.invoiceCofirmation",
    defaultMessage: `Az összes számla visszaigazolása után, a Mentés gombra kattintva fejezheti be a folyamatot. Ha még nem érkezett meg minden számla, érdemes azokat várakoztatni, így az elfogadott számlák faktorálása folytatódhat.`
  },
  causeOfDenyDialogText1Polite: {
    id: "causeOfDenyDialogText1.invoiceCofirmation",
    defaultMessage: `Kérem, válassza ki az elutasítás okát az alábbi listából! A megjegyzés mezőben bővebben kifejtheti, amit a szállító is lát majd a saját felületén.`
  },
  causeOfDenyDialogText2Polite: {
    id: "causeOfDenyDialogText2.invoiceCofirmation",
    defaultMessage: `<b>FONTOS:</b> Az elutasítás végleges művelet, később nem módosítható!`
  },
  causeOfDenySelectPolite: {
    id: "causeOfDenySelect.invoiceCofirmation",
    defaultMessage: `- Válasszon! -`
  },
  factoringTitlePolite: {
    id: "title.factoring",
    defaultMessage: `Írja alá a faktorálás tudomásul vételét!`
  },
  factoringText1Polite: {
    id: "text1.factoring",
    defaultMessage: `Ahhoz, hogy a faktorálási folyamat folytatódhasson, szükséges ennek a dokumentumnak az aláírása. Ezt az alábbi módon teheti meg:`
  },
  factoringText2Polite: {
    id: "text2.factoring",
    defaultMessage: `Töltse le az elkészült értesítőt, melyet aláírva visszatölthet az oldalra az “Aláírt fájl feltöltése” gombra kattintva.`
  },
  factoringText3Polite: {
    id: "text3.factoring",
    defaultMessage: `Hamarosan elkészül digitális aláírás modulunk, melynek segítségével még egyszerűbben írhatjá majd alá dokumentumait. `
  },
  factoringText4Polite: {
    id: "text4.factoring",
    defaultMessage: `Ahhoz, hogy a faktorálási folyamat folytatódhasson, szükséges ennek a dokumentumnak az aláírása. Ezt két módon teheti meg:`
  },
  factoringText5Polite: {
    id: "text5.factoring",
    defaultMessage: `Legegyszerűbb, ha aláírja digitálisan az “Aláírás” gombra kattintva. Ez az aláírás rákerül a dokumentumra, ami ugyanannyira hiteles, mintha kézzel írná alá.`
  },
  factoringText6Polite: {
    id: "text6.factoring",
    defaultMessage: `Letöltheti az elkészült értesítőt, melyet aláírva visszatölthet az oldalra az <b>“Aláírt fájl feltöltése”</b> gombra kattintva.`
  },
  deleteProfilePoliteText: {
    id: "text.deleteProfile",
    defaultMessage: `Amennyiben törölni szeretné fiókját, kérjük, vegye fel a kapcsolatot velünk az alábbi e-mail címen:`
  }
});
