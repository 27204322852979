import React from "react";
import { Box, Typography } from "@material-ui/core";
import billHappy from "../../images/bill/new/bill-happy.svg";

const BillSection = ({ children, title, img = billHappy }) => {
  return (
    <Box
      padding={"8px"}
      maxWidth={"710px"}
      display={"grid"}
      gridTemplateAreas={`"image title" "image description"`}
      gridTemplateColumns={"180px auto"}
      gridTemplateRows={"auto 1fr"}
      gridRowGap={"1rem"}
      gridColumnGap={"70px"}
      margin={"auto"}
    >
      <img alt={"img"} src={img} style={{ gridArea: "image" }} />
      <Typography variant={"h3"} style={{ gridArea: "title" }}>
        {title}
      </Typography>
      <Box
        gridArea={"description"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"start"}
        style={{ gap: "24px" }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default BillSection;
