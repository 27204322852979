import { getConfig } from "./config";

let gtag = () => null;
const GA_MEASUREMENT_ID = getConfig("GA_MEASUREMENT_ID");
export const initGtag = ({ clientId } = {}) => {
  const script = document.createElement("script"); // create a script DOM node
  script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`; // set its src to the provided URL

  document.head.appendChild(script); // add it to the end of the head section of the page (could change 'head' to 'body' to add it to the end of the body section instead)
  const dataLayer = (window.dataLayer = window.dataLayer || []);
  gtag = function gtag() {
    return dataLayer.push(arguments);
  };
  gtag("js", new Date());

  if (clientId) {
    gtag("config", GA_MEASUREMENT_ID, { user_id: clientId });
  } else {
    gtag("config", GA_MEASUREMENT_ID);
  }
};

export const getClientId = () =>
  new Promise((resolve, reject) => {
    gtag("get", GA_MEASUREMENT_ID, "client_id", client_id => {
      if (client_id) {
        resolve(client_id);
      } else {
        reject();
      }
    });
  });

export const gtagRegistration = () => gtag("event", "registration");

export const gtagAgreementClaimOfferAccepted = () =>
  gtag("event", "offerAccepted");

export const gtagAgreementClaimDocumentsUploaded = () =>
  gtag("event", "documentsUploaded");

export const gtagAgreementClaimContracted = () => gtag("event", "contracted");

export const gtagAgreementClaimPaymentDone = () => gtag("event", "paymentDone");

// window.gtag = {
//   initGtag,
//   getClientId,
//   gtagRegistration,
//   gtagAgreementClaimPaymentDone,
//   gtagAgreementClaimOfferAccepted,
//   gtagAgreementClaimContracted,
//   gtagAgreementClaimDocumentsUploaded
// };
