import React, { useState } from "react";
import bill from "../../images/bill/Bill help.svg";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const HelperBill = ({ status, newContract }) => {
  const [stepperOpen, setStepperOpen] = useState(false);
  return (
    <Accordion
      style={{ position: "relative" }}
      expanded={stepperOpen}
      onChange={(e, open) => setStepperOpen(open)}
    >
      <AccordionSummary
        style={{ position: "initial" }}
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography style={{ maxWidth: "680px" }}>
          Szia, Bill vagyok! Elakadtál az olalon? Kattints és nyisd le az
          ablakot, hadd segítsek!
        </Typography>
        <img
          src={bill}
          style={{
            position: "absolute",
            bottom: 0,
            right: "100px",
            transition: "width ease 0.3s",
            width: stepperOpen ? "144px" : "72px"
          }}
        />
      </AccordionSummary>
      <AccordionDetails>
        {newContract && (
          <Typography style={{ maxWidth: "680px" }}>
            Most állítsuk be a faktorálási keretedet, tehát azt, milyen
            összegben tudjuk előfinanszírozni a számláidat. Nézd meg, hogy ebben
            a határidőben ennek a vevődnek összesen milyen bruttó végösszegben
            állítasz ki számlát, mert ez alapján határozzuk majd meg a szükséges
            keretet. Semmi gond, ha több vevő számláját szeretnéd faktorálni,
            többet is meg tudsz adni. Ha később módosítani szeretnél, az sem
            probléma, nincs itt semmi kőbe vésve, ügyfélszolgálatos kollégáim
            pedig szívesen segítenek, ha elakadnál.
          </Typography>
        )}
        {status === "CALCULATION" && (
          <Typography style={{ maxWidth: "680px" }}>
            Most állítsuk be a faktorálási keretedet, tehát azt, milyen
            összegben tudjuk előfinanszírozni a számláidat. Nézd meg, hogy ebben
            a határidőben ennek a vevődnek összesen milyen bruttó végösszegben
            állítasz ki számlát, mert ez alapján határozzuk majd meg a szükséges
            keretet. Semmi gond, ha több vevő számláját szeretnéd faktorálni,
            többet is meg tudsz adni. Ha később módosítani szeretnél, az sem
            probléma, nincs itt semmi kőbe vésve, ügyfélszolgálatos kollégáim
            pedig szívesen segítenek, ha elakadnál.
          </Typography>
        )}
        {status === "DOCUMENT_UPLOAD" && (
          <Typography style={{ maxWidth: "680px" }}>
            Most jön a papírmunka, persze a Flexibillben ez is digitális! Az
            online adatlapon néhány logikus és szükséges kérdést teszünk fel,
            hogy jobban megismerjük a céged. Kérjük, hogy töltsd fel hozzánk a
            vevőddel kötött szerződést, hiszen ez alapozza meg a közös
            munkátokat és a számlád kiállításának jogi hátterét. A könyvelődtől
            is szükségünk van néhány dokumentumra a jogi előírások miatt, de
            ezeket is könnyű lesz beszerezni, ráadásul meg is hívhatod a
            Flexibill-fiókodba a könyvelőd, akkor pedig ő is fel tudja tölteni
            az anyagokat. Egyszerű, nem?
          </Typography>
        )}
        {status === "SIGNATURE" && (
          <Typography style={{ maxWidth: "680px" }}>
            Állapodjunk meg, hogy küldhessük a pénzed, amikor szeretnéd! A
            szerződésünk elektronikusan is aláírhatod (a Netlock és a Microsec
            e-aláírásait is elfogadjuk), de azzal sincsen baj, ha szeretnéd
            postán megkapni a szerződésed, csak szólj nekünk! Szereted a
            személyes kontaktot? Semmi gond, írj ügyfélszolgálatos kollégáimnak
            és az Oktogon melletti irodánkban személyesen is aláírhatjuk a
            szerződést. Hogyan lenne a legkényelmesebb számodra?
          </Typography>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
export default HelperBill;
