import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import green from "@material-ui/core/colors/green";
import amber from "@material-ui/core/colors/amber";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import WarningIcon from "@material-ui/icons/Warning";
import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import { Backdrop, Typography } from "@material-ui/core";

const variantIcon = {
  success: props => (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16 0C12.8355 0 9.74208 0.938384 7.11089 2.69649C4.4797 4.45459 2.42894 6.95345 1.21794 9.87707C0.00693261 12.8007 -0.309921 16.0178 0.307443 19.1215C0.924807 22.2252 2.44866 25.0761 4.6863 27.3137C6.92394 29.5514 9.77487 31.0752 12.8786 31.6926C15.9823 32.3099 19.1993 31.9931 22.123 30.7821C25.0466 29.5711 27.5454 27.5203 29.3035 24.8891C31.0616 22.2579 32 19.1645 32 16C31.995 11.7581 30.3077 7.69132 27.3082 4.69182C24.3087 1.69232 20.2419 0.00500432 16 0ZM22.8466 13.392L15.5738 20.6647C15.3011 20.9374 14.9312 21.0906 14.5455 21.0906C14.1598 21.0906 13.7899 20.9374 13.5171 20.6647L9.15347 16.3011C9.01454 16.1669 8.90373 16.0064 8.8275 15.829C8.75127 15.6515 8.71114 15.4606 8.70947 15.2675C8.70779 15.0744 8.74459 14.8828 8.81773 14.7041C8.89086 14.5253 8.99887 14.3629 9.13544 14.2263C9.27201 14.0898 9.43441 13.9818 9.61317 13.9086C9.79193 13.8355 9.98346 13.7987 10.1766 13.8004C10.3697 13.802 10.5606 13.8422 10.7381 13.9184C10.9155 13.9946 11.076 14.1054 11.2102 14.2444L14.5455 17.5796L20.7898 11.3353C21.0642 11.0703 21.4316 10.9237 21.813 10.927C22.1943 10.9303 22.5592 11.0833 22.8288 11.353C23.0985 11.6227 23.2515 11.9875 23.2548 12.3689C23.2581 12.7503 23.1115 13.1177 22.8466 13.392Z"
        fill="white"
      />
    </svg>
  ),
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const styles1 = theme => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.dark
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    marginRight: "18px"
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit
  },
  message: {
    display: "flex",
    alignItems: "center",
    whiteSpace: "pre-line"
  }
});

function MySnackbarContent(props) {
  const { classes, className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          <Typography variant={"h6"}>{message}</Typography>
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ]}
      {...other}
    />
  );
}

MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(["success", "warning", "error", "info"]).isRequired
};

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

const FlexiSnackbar = ({ variant, text, open, onClose, width }) => {
  return (
    <Backdrop open={open} style={{ zIndex: 9999 }}>
      <Snackbar
        anchorOrigin={{
          vertical: "center",
          horizontal: "center"
        }}
        open={open}
        onClose={onClose}
      >
        <MySnackbarContentWrapper
          variant={variant}
          message={text}
          onClose={onClose}
        />
      </Snackbar>
    </Backdrop>
  );
};

export default withWidth()(FlexiSnackbar);
