import FlexiDialog from "@bit/flexibill.flexi.flexi-dialog";
import Button from "@material-ui/core/Button";
import arrayMutators from "final-form-arrays";
import React, { useMemo, useState } from "react";
import { Form } from "react-final-form";
import { Box } from "@material-ui/core";
import { makeExternalFormSubmit } from "../../utils/makeExternalFormSubmit";
import { Fields } from "@bit/flexibill.flexi.flexi-form";

const UploadFilesDialog = ({
  open,
  onClose,
  initialValues,
  onSubmit,
  onRemove,
  onDownload,
  content
}) => {
  const formId = `newContractFiles-${initialValues?.id}`;
  const submitNewContractForm = makeExternalFormSubmit(formId);
  const [disabled, setDisabled] = useState(true);

  const fieldConfig = useMemo(
    () => [
      [
        {
          fieldType: "multipleFile",
          name: "documents",
          onRemove: file =>
            onRemove({ customerId: initialValues.id, documentId: file.id }),
          onDownload
        }
      ]
    ],
    [initialValues?.id]
  );

  return (
    <FlexiDialog
      open={open}
      onClose={onClose}
      title={"Feltöltés"}
      dialogActions={
        <Button
          disabled={disabled}
          onClick={submitNewContractForm}
          style={{ margin: "24px" }}
        >
          Feltöltés
        </Button>
      }
    >
      <Box minWidth={500}>
        {content}
        <Form
          onSubmit={onSubmit}
          mutators={arrayMutators}
          initialValues={initialValues}
          render={({ handleSubmit, values }) => {
            setDisabled(Boolean(!values?.documents?.length));
            return (
              <form onSubmit={handleSubmit} id={formId}>
                <Fields config={fieldConfig} />
              </form>
            );
          }}
        />
      </Box>
    </FlexiDialog>
  );
};

export default UploadFilesDialog;
