import React from "react";
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import logo from "../../images/flexibill-color.svg";
import Typography from "@material-ui/core/Typography";

const DesktopLogo = withStyles({
  root: {
    display: "block",
    width: "120px",
    height: "auto",
    margin: "0 auto 30px auto"
  }
  // eslint-disable-next-line jsx-a11y/alt-text
})(({ classes, ...rest }) => <img alt={"root"} className={classes.root} {...rest} />);

const Wrap = withStyles({
  root: {
    textAlign: "center",
    margin: "0",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  }
})(({ classes, ...rest }) => <div className={classes.root} {...rest} />);

const MaintenancePage = () => (
  <Grid container>
    <Grid item xs={12}>
      <Wrap>
        <DesktopLogo
          src={logo}
          alt="FlexiBill logo"
          title="FlexiBill, az Okosszámla ®"
        />
        <Typography variant="body2">
          Az oldal karbantartás alatt, kérjük látogasson vissza később!
        </Typography>
      </Wrap>
    </Grid>
  </Grid>
);

export default MaintenancePage;
