import {storeCompanyId, storeToken} from "../../utils/authentication";
import React from "react";
import { Redirect } from "react-router-dom";

const AuthPage = props => {
  let redirectTo = "/login";

  if (props.match && props.match.params.token && props.match.params.companyId) {
    storeToken(props.match.params.token);
    storeCompanyId(props.match.params.companyId);
    redirectTo = "/app";
  }

  return <Redirect to={redirectTo} />;
};

export default AuthPage;
