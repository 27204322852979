import React from "react";
import Typography from "@material-ui/core/Typography";
import { defineMessages } from "react-intl.macro";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import faktLogo from "../../images/szamlazzhu/szlahu2/flexi_szlahu_color.svg";
import heartSvg from "../../images/szamlazzhu/heartBlack.svg";
import szamlazzLogo from "../../images/szamlazzhu/szamlazzhu_logo_fekvo_narancs 2.svg";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Box, Paper } from "@material-ui/core";
import stepperBill from "../../images/redesignImages/bill-desk-laptop.svg";
import { useMutator, useProfileData } from "../../hooks/useApi";
import { getUrlWithCompanyId } from "../../utils/urlHelpers";
import FlexiForm, {
  makeExternalFormSubmit
} from "@bit/flexibill.flexi.flexi-form";

const messages = defineMessages({
  title: {
    id: "coWelcomeDialog.title",
    defaultMessage: "A FlexiBill a Számlázz.hu partnere"
  },
  description1: {
    id: "coWelcomeDialog.description1",
    defaultMessage: `Tudjuk, hogy megszoktad a kényelmes számlázást. Ígérjük, a faktorálás is legalább ennyire egyszerű lesz a FlexiBill rendszerében.`
  },
  description2: {
    id: "coWelcomeDialog.description2",
    defaultMessage: `Eddig a számlakiállítás után várnod kellett a partneredre, hogy a pénzedhez juss, mostantól viszont magad döntheted el, mikor szeretnéd a pénzt a bankszámládon látni.`
  },
  description3: {
    id: "coWelcomeDialog.description3",
    defaultMessage: `A Számlázz.hu és a FlexiBill együtt biztosítják, hogy mindig legyen pénz a számládon! Hadd mutassuk be néhány lépésben a faktorálás folyamatát!`
  },
  buttonText: {
    id: "coWelcomeDialog.buttonText",
    defaultMessage: "Rendben"
  }
});

const formId = "MissingPhoneNumberForm";
const submit = makeExternalFormSubmit(formId);

const CoWelcomeDialog = ({ open, onClose }) => {
  const useStyles = makeStyles({
    paper: {
      overflow: "visible"
    }
  });

  const [put] = useMutator("PUT");
  const { mainCompany, reloadCompany } = useProfileData();

  const classes = useStyles();
  return (
    <Dialog
      PaperProps={{
        className: classes.paper
      }}
      style={{ padding: "35px", overflow: "visible !important" }}
      open={open}
    >
      <Grid container style={{ padding: "35px 24px 24px" }}>
        <Grid
          item
          xs={12}
          style={{ display: "inline-flex", paddingLeft: "16px" }}
        >
          <img alt={"digFaktWhite"} src={faktLogo} />
          <img
            src={stepperBill}
            style={{
              position: "absolute",
              right: "60px",
              top: "-46px",
              width: "160px"
            }}
          />
        </Grid>
        <Grid item xs={12} style={{ marginBottom: "32px", marginTop: "24px" }}>
          <Paper style={{ padding: "24px 17px" }}>
            <Typography variant={"body2"}>
              Tudjuk, hogy megszoktad a kényelmes számlázást. Ígérjük, a{" "}
              <b>faktorálás is legalább ennyire egyszerű lesz</b> a FlexiBill
              rendszerében. <br />
              <br /> Eddig a számlakiállítás után várnod kellett a partneredre,
              hogy a pénzedhez juss, mostantól viszont{" "}
              <b>magad döntheted el, mikor szeretnéd a pénzt</b> a bankszámládon
              látni. <br />
              <br />
              <b> A Számlázz.hu és a FlexiBill együtt biztosítják</b>, hogy
              mindig legyen pénz a számládon! Kérjük,{" "}
              <b>add meg a telefonszámodat</b>, hogy ha kérdésed merülne fel,
              kezelőink fel tudják veled venni a kapcsolatot! <br />
              <br /> Ezt követően hadd mutassuk be néhány lépésben a{" "}
              <b>faktorálás folyamatát</b>!
              <br />
            </Typography>
            <Box mb={2} />
            {!mainCompany?.party.contact.telephone && (
              <FlexiForm
                id={formId}
                FinalFormFormProps={{
                  onSubmit: async ({ phoneNumber }) => {
                    const mainCompanyCopy = { ...mainCompany };
                    mainCompanyCopy.party = {
                      ...mainCompanyCopy.party,
                      contact: {
                        ...mainCompanyCopy.party.contact,
                        telephone: phoneNumber
                      }
                    };
                    const { data, error } = await put(
                      getUrlWithCompanyId(``),
                      mainCompanyCopy
                    );

                    if (data) {
                      await reloadCompany();
                      onClose();
                    }
                  }
                }}
                fields={[
                  [
                    {
                      fieldType: "text",
                      required: true,
                      name: "phoneNumber",
                      label: "Telefonszám"
                    }
                  ]
                ]}
              />
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} style={{ paddingLeft: "16px" }}>
          <Button
            onClick={!mainCompany?.party.contact.telephone ? submit : onClose}
          >
            {messages.buttonText.defaultMessage}
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default CoWelcomeDialog;
