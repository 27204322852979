import React from "react";
import Grid from "@material-ui/core/Grid";
import digFaktWhite from "../../images/szamlazzhu/szlahu2/flexi_szlahu_white.svg";
import faktLogo from "../../images/szamlazzhu/Logó.svg";
import szamlazzLogo from "../../images/szamlazzhu/flexiSzlahuKulcs_logo3.svg";
import heartSvg from "../../images/szamlazzhu/Vector.svg";
import moustacheWaterMark from "../../images/redesignImages/HatterLogo.svg";
import Typography from "@material-ui/core/Typography";
import { defineMessages } from "react-intl.macro";
import { FormattedHTMLMessage } from "react-intl";
import { politeMessages } from "../../translations/politeMessages";
import { getConfig, szamlazzhuInUrl } from "../../utils/config";
import Footer from "../Footer";

const messages = defineMessages({
  title: {
    id: "title.welcomeLayout",
    defaultMessage: `Üdvözlünk a FlexiBill - </br>Digitális Faktoring felületén!`
  },
  text: {
    id: "text.welcomeLayout",
    defaultMessage: szamlazzhuInUrl
      ? `Dolgoztál és számláztál, jöhet a bevétel! Ekkor jön képbe a Flexibill: töltsd föl a számláidat, mi pedig utalunk neked, mert a pénzed a legjobb helyen nálad van. Ezentúl a Számlázz.hu felületén létrehozott számláidat akár azonnal faktoráltathatod.`
      : "Dolgoztál és számláztál, jöhet a bevétel! Ekkor jön képbe a Flexibill: töltsd föl a számláidat, mi pedig utalunk neked, mert a pénzed a legjobb helyen nálad van."
  }
});
const DesktopWelcomeHeader = ({ isCustomer }) => {
  return (
    <Grid
      container
      direction={"column"}
      style={{
        backgroundColor: "#2227AC",
        minHeight: "100vh",
        height: "100%"
      }}
    >
      <Grid
        item
        style={{
          paddingLeft: "117px",
          paddingTop: "120px",
          display: "inline-flex"
        }}
      >
        <React.Fragment>
          <img alt={"szamlazzWhite"} src={szamlazzLogo} />
        </React.Fragment>
      </Grid>
      <Grid
        item
        style={{
          paddingLeft: "120px",
          paddingRight: "150px",
          paddingTop: "88px"
        }}
      >
        <Typography variant={"h5"} style={{ color: "white", fontSize: "44px" }}>
          {isCustomer ? (
            <FormattedHTMLMessage {...politeMessages.titlePolite} />
          ) : (
            <FormattedHTMLMessage {...messages.title} />
          )}
        </Typography>
      </Grid>
      <Grid
        item
        style={{
          paddingLeft: "120px",
          paddingRight: "175px",
          paddingTop: "32px"
        }}
      >
        <Typography style={{ color: "white", fontSize: "16px" }}>
          {isCustomer ? (
            <FormattedHTMLMessage {...politeMessages.textPolite} />
          ) : (
            <FormattedHTMLMessage {...messages.text} />
          )}
        </Typography>
      </Grid>
      <Grid
        item
        style={{
          position: "fixed",
          top: 126
        }}
      >
        <img alt={"moustacheWaterMark"} src={moustacheWaterMark} />
      </Grid>
      <Grid style={{ zIndex: "9999999999" }}>
        <Footer />
      </Grid>
    </Grid>
  );
};

export default DesktopWelcomeHeader;
