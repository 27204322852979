import React from "react";
import FlexiDialog from "@bit/flexibill.flexi.flexi-dialog";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { useMutator } from "../../hooks/useApi";
import { getUrlWithCompanyId } from "../../utils/urlHelpers";
import {
  useErrorOccurredNotification,
  useSaveSuccessNotification
} from "../../hooks/useNotificationState";
import { selectErrorNotificationMessage } from "../../utils/selectErrorMessage";

const NavSyncDialog = ({ open, onClose }) => {
  const [get] = useMutator("GET");
  const notifySuccess = useSaveSuccessNotification();
  const notifyError = useErrorOccurredNotification();

  return (
    <FlexiDialog
      open={open}
      onClose={onClose}
      title={"Számlaszinkronizálás"}
      dialogActions={
        <Grid item xs={12} style={{ display: "inherit" }}>
          <Button
            onClick={() =>
              get(getUrlWithCompanyId("/invoice/fetch/nav/30-days")).then(
                ({ data, error }) => {
                  if (data) {
                    notifySuccess({ text: "A szinkronizálás elkezdődött!" });
                    onClose();
                  } else {
                    notifyError(selectErrorNotificationMessage(error));
                  }
                }
              )
            }
          >
            Igen
          </Button>
          <Button variant={"text"} onClick={onClose}>
            Nem
          </Button>
        </Grid>
      }
    >
      Akarod-e az elmúlt 30 nap számláit szinkronizálni?
    </FlexiDialog>
  );
};

export default NavSyncDialog;
