export const ROLE_FLEXIPAY_ASSIGNMENT_EXECUTIVE =
  "ROLE_FLEXIPAY_ASSIGNMENT_EXECUTIVE";
export const ROLE_FLEXIPAY_INVOICE_MANAGER = "ROLE_FLEXIPAY_INVOICE_MANAGER";
export const ROLE_FLEXIPAY_SALES_ADMIN = "ROLE_FLEXIBILL_SALES_ADMIN";
export const ROLE_FLEXIPAY_SUPER_ADMIN = "ROLE_FLEXIPAY_SUPER_ADMIN";
export const ROLE_FLEXIPAY_AGREEMENT_UPLOADER =
  "ROLE_FLEXIPAY_AGREEMENT_UPLOADER";
export const ROLE_FLEXIPAY_CUSTOMER = "ROLE_FLEXIPAY_CUSTOMER";
export const ROLE_FLEXIPAY_CUSTOMER_PLUS
  = "ROLE_FLEXIPAY_CUSTOMER_PLUS";
