import FlexiStatusIcon from "../../components/FlexiStatusIcon";
import React from "react";
import waitingForSupplierSignature from "../../images/redesignImages/WaitingForSupplierSignature.svg";
import waitingForCustomerConfirmation from "../../images/redesignImages/WaitingForCustomerConfirmation.svg";
import waitingForCustomerSignature from "../../images/redesignImages/WaitingForCustomerSignature.svg";
import waitingForBoConfirmation from "../../images/redesignImages/WaitingForBoConfirmation.svg";
import onHold from "../../images/redesignImages/Subtract.svg";
import rejected from "../../images/redesignImages/Group 35.svg"
import approved from "../../images/redesignImages/Approved.svg";
import { defineMessages } from "react-intl.macro";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import InvoiceStatusHelperDialog from "./InvoiceStatusHelperDialog";
import Typography from "@material-ui/core/Typography";
// 'WAITING_FOR_ADMIN_CONFIRMATION'
// 'WAITING_FOR_SUPPLIER_SIGNATURE'
// 'WAITING_FOR_CUSTOMER_SIGNATURE'
// 'WAITING_FOR_CUSTOMER_CONFIRMATION'
// 'APPROVED' amikor be lett küldve a faktoriba
// 'CLOSED' amikor le lett zárva

// todo valahogy az intl-el forgatni az uzeneteket
export const statusMessages = defineMessages({
  waitingForCustomerSignature: {
    id: "label.waitingForCustomerSignature",
    defaultMessage: "Vevő aláírására vár"
  },
  waitingForCustomerConfirmation: {
    id: "label.waitingForCustomerConfirmation",
    defaultMessage: "Vevő visszaigazolásra vár"
  },
  waitingForSupplierSignature: {
    id: "label.waitingForSupplierSignature",
    defaultMessage: "Szállító aláírására vár"
  },
  approved: {
    id: "label.approved",
    defaultMessage: "Faktorált"
  },
  granted: {
    id: "label.granted",
    defaultMessage: "Faktorált"
  }, onHold: {
    id: "label.onHold",
    defaultMessage: "Várakoztatva"
  }, rejected: {
    id: "label.rejected",
    defaultMessage: "Elutasítva"
  },
  waitingForBoConfirmation: {
    id: "label.waitingForBoConfirmation",
    defaultMessage: "Ellenőrzés alatt"
  }
});

const useWhiteIconStyle = makeStyles({
  root: ({ backgroundColor }) => ({
    backgroundColor,
    color: "white",
    "& svg": {
      color: "white"
    }
  })
});

const makeInvoiceStatusIcon = ({ icon, tooltipText, backgroundColor }) => ({
  chip
}) => {
  const whiteIconClasses = useWhiteIconStyle({ backgroundColor });
  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  return (
    <>
      <div style={{ cursor: "help" }} onClick={() => setStatusDialogOpen(true)}>
        {chip ? (
          <Chip
            style={{
              height: "28px",
              borderRadius: "15px"
            }}
            icon={icon}
            label={tooltipText}
            classes={whiteIconClasses}
          />
        ) : (
          <FlexiStatusIcon
            icon={icon}
            tooltipText={tooltipText}
            backgroundColor={backgroundColor}
          />
        )}
      </div>
      <div>
        <InvoiceStatusHelperDialog
          open={statusDialogOpen}
          onClose={() => setStatusDialogOpen(false)}
        />
      </div>
    </>
  );
};

export const WaitingForCustomerSignature = makeInvoiceStatusIcon({
  icon: <img src={waitingForCustomerSignature} />,
  backgroundColor: "#fff",
  tooltipText: statusMessages.waitingForCustomerSignature.defaultMessage
});

export const WaitingForCustomerConfirmation = makeInvoiceStatusIcon({
  icon: <img src={waitingForCustomerConfirmation} />,
  backgroundColor: "#fff",
  tooltipText: statusMessages.waitingForCustomerConfirmation.defaultMessage
});

export const WaitingForSupplierSignature = makeInvoiceStatusIcon({
  icon: <img src={waitingForSupplierSignature} />,
  backgroundColor: "#fff",
  tooltipText: statusMessages.waitingForSupplierSignature.defaultMessage
});

export const Approved = makeInvoiceStatusIcon({
  icon: <img src={approved} />,
  backgroundColor: "#fff",
  tooltipText: statusMessages.approved.defaultMessage
});

export const Granted = makeInvoiceStatusIcon({
  icon: <img src={approved} />,
  backgroundColor: "#fff",
  tooltipText: statusMessages.granted.defaultMessage
});

export const WaitingForBoConfirmation = makeInvoiceStatusIcon({
  icon: <img src={waitingForBoConfirmation} />,
  backgroundColor: "#fff",
  tooltipText: statusMessages.waitingForBoConfirmation.defaultMessage
});
export const Rejected = makeInvoiceStatusIcon({
  icon: <img src={rejected} />,
  backgroundColor: "#fff",
  tooltipText: "Elutasítva"
});

export const OnHold = makeInvoiceStatusIcon({
  icon: <img src={onHold} />,
  backgroundColor: "#fff",
  tooltipText: "Várakoztatva"
});

export const InvoiceStatusIcon = ({ status, chip, withText }) => {
  switch (status) {
    case "WAITING_FOR_CUSTOMER_SIGNATURE":
      return (
        <>
          <img src={waitingForCustomerSignature} />
          {withText && (
            <Typography style={{paddingLeft: "10px"}} variant={"h6"}>
              {statusMessages.waitingForCustomerSignature.defaultMessage}
            </Typography>
          )}
        </>
      );
    case "WAITING_FOR_CUSTOMER_CONFIRMATION":
      return (
        <>
          <img src={waitingForCustomerConfirmation} />
          {withText && (
            <Typography style={{paddingLeft: "10px"}} variant={"h6"}>
              {statusMessages.waitingForCustomerConfirmation.defaultMessage}
            </Typography>
          )}
        </>
      );
    case "WAITING_FOR_SUPPLIER_SIGNATURE":
      return <><img src={waitingForSupplierSignature} /> {withText && (
        <Typography style={{paddingLeft: "10px"}} variant={"h6"}>
          {statusMessages.waitingForSupplierSignature.defaultMessage}
        </Typography>
      )}
      </>;
    case "APPROVED":
      return <><img src={approved} /> {withText && (
        <Typography style={{paddingLeft: "10px"}} variant={"h6"}>
          {statusMessages.approved.defaultMessage}
        </Typography>
      )}
      </>;
    case "WAITING_FOR_ADMIN_CONFIRMATION":
      return <><img src={waitingForBoConfirmation} /> {withText && (
        <Typography style={{paddingLeft: "10px"}} variant={"h6"}>
          {statusMessages.waitingForBoConfirmation.defaultMessage}
        </Typography>
      )}
      </>;
    case "ON_HOLD":
      return <><img src={onHold} /> {withText && (
        <Typography style={{paddingLeft: "10px"}} variant={"h6"}>
          {statusMessages.onHold.defaultMessage}
        </Typography>
      )}
      </>;
    case "REJECTED":
      return <><img src={rejected} /> {withText && (
        <Typography style={{paddingLeft: "10px"}} variant={"h6"}>
          {statusMessages.rejected.defaultMessage}
        </Typography>
      )}
      </>;
    default:
      return status;
  }
};
