import React, { createContext } from "react";

export const roleContext = createContext([]);

const { Provider } = roleContext;

const RoleProvider = ({ children, roles }) => {
  let providerValue;

  if (typeof roles === "string") {
    providerValue = [roles];
  } else if (Array.isArray(roles)) {
    providerValue = roles;
  } else {
    providerValue = [];
  }

  return <Provider value={providerValue}>{children}</Provider>;
};

export default RoleProvider;
