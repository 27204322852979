import Paper from "@material-ui/core/Paper";
import React from "react";
import { isWidthDown, makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import imageSrc from "../../images/redesignImages/Group 59.svg";
import Typography from "@material-ui/core/Typography";
import { FormattedHTMLMessage, useIntl } from "react-intl";
import { defineMessages } from "react-intl.macro";
import Button from "@material-ui/core/Button";
import withWidth from "@material-ui/core/withWidth";

const messages = defineMessages({
  title: {
    id: "label.signHelperBoxTitle",
    defaultMessage: "Elkészült a faktorálási értesítő!"
  },
  signedTitle: {
    id: "label.signHelperBoxSignedTitle",
    defaultMessage: "A dokumentumot aláírtad!"
  },
  notSignedDescription: {
    id: "text.signHelperBoxNotSignedDescription",
    defaultMessage: `Ahhoz, hogy a faktorálási folyamat folytatódhasson, alá kell írnod a dokumentumot az alábbi módon:`
  },
  notSignedDescription2: {
    id: "text.signHelperBoxNotSignedDescription2",
    defaultMessage: `Töltsd le az elkészült értesítőt, nyomtasd ki és írd alá! Az aláírt példányt scanneld be és töltsd vissza az oldalra az “Aláírt fájl feltöltése” gombra kattintva.`
  },
  signedDescription: {
    id: "text.signHelperBoxSignedDescription",
    defaultMessage: `Minden dokumentumot feltöltöttél, így nincs további teendőd. A számlák ellenőrzése folyamatban van.
`
  },
  signedTitlePolite: {
    id: "label.signHelperBoxSignedTitle",
    defaultMessage: "A dokumentumot aláírta!"
  },
  notSignedDescriptionPolite: {
    id: "text.signHelperBoxNotSignedDescription",
    defaultMessage: `Ahhoz, hogy a faktorálási folyamat folytatódhasson, alá kell írnia a dokumentumot az alábbi módon:`
  },
  notSignedDescription2Polite: {
    id: "text.signHelperBoxNotSignedDescription2",
    defaultMessage: `Töltse le az elkészült értesítőt, nyomtassa ki és írja alá! Az aláírt példányt scannelje be és töltse vissza az oldalra az “Aláírt fájl feltöltése” gombra kattintva.`
  },
  signedDescriptionPolite: {
    id: "text.signHelperBoxSignedDescription",
    defaultMessage: `Minden dokumentumot feltöltött, így nincs további teendője. A számlák ellenőrzése folyamatban van.
`
  },
  downloadDocForSign: {
    id: "label.downloadDocForSign",
    defaultMessage: "Aláírandó példány letöltése"
  },
  backToTheList: {
    id: "label.backToTheList",
    defaultMessage: "Vissza a számlákhoz"
  }
});

const SignHelperBox = ({
  type,
  signed,
  onDownloadButtonClick,
  onBackToTheListClick,
  width,
  customer
}) => {
  const classes = useStyles({ signed, type });
  const intl = useIntl();

  const descriptionMessage = signed
    ? customer
      ? messages.signedDescriptionPolite
      : messages.signedDescription
    : customer
    ? messages.notSignedDescriptionPolite
    : messages.notSignedDescription;

  return (
    <Paper elevation={0} style={{ padding: "32px 40px 40px" }}>
      <Grid container direction={"row"}>
        <Grid item xs={3}>
          <img alt={"imageSrc"} src={imageSrc} className={classes.image} />
        </Grid>
        <Grid item xs={9}>
          <Typography variant={"h5"} style={{ paddingBottom: "24px" }}>
            {intl.formatMessage(
              signed
                ? customer
                  ? messages.signedTitlePolite
                  : messages.signedTitle
                : messages.title
            )}
          </Typography>
          <Typography variant={"h6"} style={{ paddingBottom: "24px" }}>
            <FormattedHTMLMessage {...descriptionMessage} />
          </Typography>
          {!signed && (
            <Typography variant={"body2"} style={{ paddingBottom: "24px" }}>
              {customer
                ? intl.formatMessage(messages.notSignedDescription2Polite)
                : intl.formatMessage(messages.notSignedDescription2)}
            </Typography>
          )}
          <Button
            fullWidth={isWidthDown("xs", width)}
            onClick={signed ? onBackToTheListClick : onDownloadButtonClick}
          >
            {intl.formatMessage(
              signed ? messages.backToTheList : messages.downloadDocForSign
            )}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

const useStyles = makeStyles({
  image: {
    margin: "auto",
    display: "block",
    marginBottom: 40
  }
});

export default withWidth()(SignHelperBox);
