import React, { useEffect, useState } from "react";
import { useRemoteDocumentViewer } from "../../hooks/useApi";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import download from "../../utils/downloadBase64";
import FlexiDocumentViewer from "@bit/flexibill.flexi.flexi-document-preview";
import Chip from "@material-ui/core/Chip/Chip";
import DownloadIcon from "@material-ui/icons/GetApp";
import { defineMessages } from "react-intl.macro";
import { useIntl } from "react-intl";

const chipMessages = defineMessages({
  labelinvoice: {
    id: "chip.invoice",
    defaultMessage: "Számla"
  },
  labelcertificate_of_completion: {
    id: "chip.certificate_of_completion",
    defaultMessage: "Teljesítés igazolás"
  }
});

const ButtonColumn = ({ onClick, disabled, icon }) => {
  return (
    <Grid item xs={1}>
      <Grid
        container
        justify={"center"}
        alignItems={"center"}
        style={{ height: "100%" }}
      >
        <IconButton disabled={disabled} onClick={onClick}>
          {icon}
        </IconButton>
      </Grid>
    </Grid>
  );
};

const DocumentsCarousel = ({
  documents,
  getUrl,
  fetcher,
  showTitle,
  index
}) => {
  const [selectedIndex, setSelectedIndex] = useState(index || 0);
  const {
    data: document,
    error,
    viewDocument,
    loading
  } = useRemoteDocumentViewer({
    getUrl,
    fetcher
  });
  const intl = useIntl();
  const activeDocument = documents && documents[selectedIndex];
  const mimeType = activeDocument?.mimeType;

  useEffect(
    () => {
      if (documents) {
        viewDocument(documents[selectedIndex]?.id);
      }
    },
    [selectedIndex]
  );

  const handlePageChange = amount => e =>
    setSelectedIndex(selectedIndex + amount);

  const getChipTranslations = status =>
    chipMessages[`label${status}`] || {
      id: `label${status}-MISSING`,
      defaultMessage: status
    };

  return (
    <Grid container>
      {showTitle && (
        <Grid container spacing={2} style={{ marginBottom: "0.5rem" }}>
          <Grid item xs={12}>
            <Typography variant={"h5"} align={"center"}>
              {document && documents[selectedIndex].invoiceId}
            </Typography>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={5} style={{ alignItems: "flex-end", display: "flex" }}>
            <Chip
              variant="outlined"
              color="primary"
              label={
                document &&
                intl.formatMessage(
                  getChipTranslations(documents[selectedIndex].type)
                )
              }
            />
          </Grid>
          <Grid item xs={5} style={{ textAlign: "right" }}>
            <IconButton
              size={"small"}
              onClick={() =>
                download(activeDocument.originalName, document.image, mimeType)
              }
            >
              <DownloadIcon />
            </IconButton>
          </Grid>
        </Grid>
      )}
      <Grid container xs={12}>
        <ButtonColumn
          onClick={handlePageChange(-1)}
          disabled={selectedIndex < 1}
          icon={<ChevronLeftIcon />}
        />
        <Grid item xs style={{ minHeight: 400 }}>
          <div
            style={{
              border: mimeType === "application/pdf" && "1px solid #232323",
              marginBottom: "1rem",
              minHeight: 400
            }}
          >
            {document &&
              !loading && (
                <FlexiDocumentViewer
                  base64={{
                    mimeType: mimeType,
                    image: document.image
                  }}
                />
              )}
          </div>
        </Grid>
        <ButtonColumn
          onClick={handlePageChange(1)}
          disabled={selectedIndex === documents?.length - 1}
          icon={<ChevronRightIcon />}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography align={"center"}>
          {`${selectedIndex + 1} / ${documents?.length}`}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default DocumentsCarousel;
