import { SvgIcon } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import React from "react";

export const green = "#02BE6F";
export const blue = "#2227AC";
export const lightBlue = "#2227AC";
export const orange = "#FF6630";
export const hoverOrange = "#E14007";
export const disabledOrange = "#FFAD8F";

const CheckedIcon = () => (
  <SvgIcon style={{ fill: "none" }}>
    <rect
      x="3.5"
      y="3.5"
      width="18"
      height="18"
      rx="3.5"
      stroke="#02BE6F"
      strokeWidth="3"
    />
    <rect x="5.5" y="5.5" width="14" height="14" rx="1" fill="#02BE6F" />
  </SvgIcon>
);
const UncheckedIcon = () => (
  <SvgIcon style={{ fill: "none" }}>
    <rect
      x="3.5"
      y="3.5"
      width="18"
      height="18"
      rx="3.5"
      stroke="#02BE6F"
      strokeWidth="3"
    />
  </SvgIcon>
);

const muiTheme = createTheme({
  props: {
    MuiTooltip: { arrow: true },
    MuiButton: { variant: "contained" },
    MuiTextField: { variant: "outlined" },
    MuiSelect: { variant: "outlined" },
    MuiInputLabel: { variant: "outlined" },
    MuiCheckbox: {
      color: "secondary",
      size: "large",
      checkedIcon: <CheckedIcon />,
      icon: <UncheckedIcon />
    }
  },
  palette: {
    primary: {
      main: blue,
      dark: blue,
      light: blue
    },
    secondary: {
      main: green,
      dark: green,
      light: green
    }
  },
  overrides: {MuiTabs: {
      root: {
        "& .MuiTabs-indicator": {
          backgroundColor: "#8371EA",
          height: 5,
          top: 0,

        },
        "& .MuiTabs-indicator.Mui-selected": {
          backgroundColor: "transparent",
          height: 5,
          top: 0

        }
      }
    },
    MuiFormLabel: {
      root: {
        "&$focused, &$filled": {
          transform: "translate(24px, 9px) scale(0.75) !important"
        },
        "&.MuiInputLabel-outlined": {
          transform: "translate(24px, 20px) scale(1)"
        }
      }
    },
    MuiSelect: {
      root: {
        "&:focus": {
          backgroundColor: "transparent !important"
        },
        paddingTop: "5px !important",

        "& .MuiSelect-select": {}
      },
      outlined: {
        padding: "0"
      }
    },
    MuiTooltip: { root: { fontSize: 12 } },
    MuiIconButton: {
      root: {
        width: "30px",
        height: "30px"
      },
      sizeSmall: {
        width: "30px",
        height: "30px",
        padding: "8px",
        boxShadow: "2px 2px 6px rgba(18, 94, 242, 0.1)",
        borderRadius: "5px",
        backgroundColor: "#fff",
        "&:hover": { backgroundColor: "#f0f0f0 !important" },
        "&:disabled": {
          backgroundColor: "#9b9b9b !important",
          color: "#fff !important"
        },
        "& svg": {
          fill: "#4a4a4a",
          width: "20px !important"
        }
      }
    },
    MuiButton: {
      root: {
        borderRadius: "5px !important",
        "& path": {
          transition: "color 250ms"
        },
        textTransform: "none",
        height: "40px",
        transition: "color 250ms",
        fontFamily: "'Poppins'",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "130%",
        display: "flex",
        alignItems: "center",
        padding: "9px 20px !important",
        minWidth: "121px",
        "&:disabled": {
          backgroundColor: "#9b9b9b !important",
          color: "#fff !important"
        },
        "& img": {
          width: "20px !important"
        }
      },
      contained: {
        backgroundColor: `${green} !important`,
        color: "#fff !important",
        "&:hover": { backgroundColor: `${blue} !important` },
        boxShadow: "none"
      },
      outlined: {
        borderRadius: "5px",
        borderColor: green,
        borderWidth: "2px",
        backgroundColor: "transparent !important",
        color: green,
        "&:hover": {
          borderColor: blue,
          color: blue,
          "& path": {
            fill: `${blue} !important`
          }
        }
      },
      text: {
        color: green
      }
    },
    MuiCheckbox: {
      root: {
        "&$checked": {
          color: "#394AB8 !important"
        },
        "& svg": {
          fill: green
        }
      },
      checked: {}
    },
    MuiRadio: {
      root: {
        "& svg": {
          width: "29px",
          height: "29px",
          fill: green
        }
      }
    },
    MuiSwitch: {
      root: {
        padding: 0,
        alignItems: "center",
        "& .MuiButtonBase-root": {
          padding: "4px",
          width: "auto",
          height: "auto"
        },
        "& .MuiSwitch-colorSecondary.Mui-disabled + .MuiSwitch-track": {
          backgroundColor: "inherit !important"
        }
      },
      switchBase: {
        padding: 2,
        position: "relative",
        "&$checked": {
          transform: "translateX(18px)",
          color: green,
          "& + $track": {
            opacity: 1,
            backgroundColor: green,
            borderColor: green
          },
          "& $thumb": {
            backgroundColor: "white"
          }
        }
      },
      thumb: {
        width: "14px",
        height: "14px",
        backgroundColor: green,
        boxShadow: "none"
      },
      track: {
        width: "40px",
        height: "22px",
        border: `3px solid ${green} !important`,
        opacity: "1 !important",
        backgroundColor: "white",
        borderRadius: "25px",
        position: "absolute"
      },
      checked: {}
    },
    MuiOutlinedInput: {
      root: {
        height: "52px",
        borderRadius: "5px",
        padding: "27px 23px",
        backgroundColor: "white"
      }
    },
    MuiAutocomplete: {
      root: {
        "& .MuiOutlinedInput-root": {
          padding: "27px 23px !important"
        },
        "& input": {
          padding: "0 !important"
        }
      }
    },
    MuiTextField: {
      root: {
        fontFamily: "Poppins",
        "& input": {
          fontFamily: "'Poppins'",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: "17px",
          lineHeight: "26px",
          color: "#000000"
        },
        "& .MuiOutlinedInput-notchedOutline": {
          top: 0
        },
        "& .MuiOutlinedInput-input": {
          padding: 0
        },
        "& legend": {
          display: "none"
        }
      },
      outlined: {}
    },
    MuiPaper: {
      elevation1: {
        boxShadow: "10px 14px 40px rgba(18, 94, 242, 0.1)",
        borderRadius: "5px",
        padding: "1rem"
      }
    },
    MuiTypography: {
      root: {
        "&.MuiTypography-colorTextPrimary": {
          color: lightBlue
        }
      },
      colorTextSecondary: {
        color: "white"
      }
    },
    MuiDialogActions: {
      root: {
        justifyContent: "flex-start",
        padding: 0,
        paddingTop: "32px"
      }
    },
    MuiButtonGroup: {
      root: {
        "& .MuiButtonGroup-groupedHorizontal:not(:first-child)": {
          borderTopLeftRadius: "0 !important",
          borderBottomLeftRadius: "0 !important"
        },
        "& .MuiButtonGroup-groupedHorizontal:not(:last-child)": {
          borderTopRightRadius: "0 !important",
          borderBottomRightRadius: "0 !important"
        }
      }
    },
    MuiSnackbarContent: {
      root: {
        boxShadow: "10px 14px 40px rgba(18, 94, 242, 0.1)",
        borderRadius: "5px",
        "&.MySnackbarContent-success-1": {
          backgroundColor: green
        }
      }
    }
  },
  typography: {
    root: {
      fontFamily: "'Poppins'",
      fontStyle: "normal",
      color: "red !important"
    },

    // Folyoszoveg
    body1: {
      fontWeight: 300,
      fontSize: "13px",
      lineHeight: "20px"
    },
    caption: {
      fontWeight: 600,
      fontSize: "13px",
      lineHeight: "20px"
    },
    // H1
    h1: {
      fontWeight: 700,
      fontSize: "60px",
      lineHeight: "120%"
    },
    // cím
    subtitle1: {
      fontWeight: 700,
      fontSize: "22px",
      lineHeight: "34px"
    },
    // H3
    h3: {
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: "120%"
    },
    h4: {
      fontWeight: 700,
      fontSize: "20px",
      lineHeight: "30px"
    },
    h5: {
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "120%"
    },
    h6: {
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: "120%"
    },
    // Megjegyzes
    body2: {
      fontWeight: 300,
      fontSize: "14px",
      lineHeight: "21px"
    }
  }
});
export default muiTheme;

export const szamlazzMuiTheme = createTheme({
  props: {
    MuiTooltip: { arrow: true },
    MuiButton: { variant: "contained" },
    MuiTextField: { variant: "outlined" },
    MuiCheckbox: {
      color: "secondary",
      size: "large",
      checkedIcon: <CheckedIcon />,
      icon: <UncheckedIcon />
    }
  },
  palette: {
    primary: {
      main: orange,
      dark: orange,
      light: orange
    },
    secondary: {
      main: hoverOrange,
      dark: hoverOrange,
      light: hoverOrange
    }
  },
  overrides: {
    MuiFormLabel: {
      root: {
        "&$focused, &$filled": {
          transform: "translate(24px, 9px) scale(0.75) !important"
        },
        "&.MuiInputLabel-outlined": {
          transform: "translate(24px, 20px) scale(1)"
        }
      }
    },
    MuiSelect: {
      root: {
        "&:focus": {
          backgroundColor: "transparent !important"
        },
        paddingTop: "5px !important",

        "& .MuiSelect-select": {}
      },
      outlined: {
        padding: "0"
      }
    },
    MuiTooltip: { root: { fontSize: 12 } },
    MuiChip: {
      root: { "&.MuiChip-colorPrimary": { color: "#fff !important" } }
    },
    MuiIconButton: {
      root: {
        width: "30px",
        height: "30px"
      },
      sizeSmall: {
        width: "30px",
        height: "30px",
        padding: "8px",
        boxShadow: "2px 2px 6px rgba(18, 94, 242, 0.1)",
        borderRadius: "5px",
        backgroundColor: "#fff",
        "&:hover": { backgroundColor: "hoverOrange !important" },
        "&:disabled": {
          backgroundColor: "disabledOrange !important",
          color: "#fff !important"
        },
        "& svg": {
          fill: "#4a4a4a",
          width: "20px !important"
        }
      }
    },
    MuiButton: {
      root: {
        borderRadius: "5px !important",
        "& path": {
          transition: "color 250ms"
        },
        textTransform: "none",
        height: "40px",
        transition: "color 250ms",
        fontFamily: "'Poppins'",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "130%",
        display: "flex",
        alignItems: "center",
        padding: "9px 20px !important",
        minWidth: "121px",
        "&:disabled": {
          backgroundColor: `${disabledOrange} !important`,
          color: "#fff !important"
        },
        "& img": {
          width: "20px !important"
        }
      },
      contained: {
        backgroundColor: `${orange} !important`,
        color: "#fff !important",
        "&:hover": { backgroundColor: `${hoverOrange} !important` },
        boxShadow: "none"
      },
      outlined: {
        borderRadius: "5px",
        borderColor: orange,
        borderWidth: "2px",
        backgroundColor: "transparent !important",
        color: orange,
        "& path": {
          fill: `${orange} !important`
        },
        "&:hover": {
          borderColor: hoverOrange,
          color: hoverOrange,
          "& path": {
            fill: `${hoverOrange} !important`
          }
        }
      },
      text: {
        color: orange
      }
    },
    MuiCheckbox: {
      root: {
        "&$checked": {
          color: "#394AB8 !important"
        },
        "& svg": {
          fill: orange
        }
      },
      checked: {}
    },
    MuiRadio: {
      root: {
        "& svg": {
          width: "29px",
          height: "29px",
          fill: orange
        }
      }
    },
    MuiSwitch: {
      root: {
        padding: 0,
        alignItems: "center",
        "& .MuiButtonBase-root": {
          padding: "4px",
          width: "auto",
          height: "auto"
        },
        "& .MuiSwitch-colorSecondary.Mui-disabled + .MuiSwitch-track": {
          backgroundColor: "inherit !important"
        }
      },
      switchBase: {
        padding: 2,
        position: "relative",
        "&$checked": {
          transform: "translateX(18px)",
          color: orange,
          "& + $track": {
            opacity: 1,
            backgroundColor: orange,
            borderColor: orange
          },
          "& $thumb": {
            backgroundColor: "white"
          }
        }
      },
      thumb: {
        width: "14px",
        height: "14px",
        backgroundColor: orange,
        boxShadow: "none"
      },
      track: {
        width: "40px",
        height: "22px",
        border: `3px solid ${orange} !important`,
        opacity: "1 !important",
        backgroundColor: "white",
        borderRadius: "25px",
        position: "absolute"
      },
      checked: {}
    },
    MuiOutlinedInput: {
      root: {
        height: "52px",
        borderRadius: "5px",
        padding: "27px 23px",
        backgroundColor: "white"
      }
    },
    MuiAutocomplete: {
      root: {
        "& .MuiOutlinedInput-root": {
          padding: "27px 23px !important"
        },
        "& input": {
          padding: "0 !important"
        }
      }
    },
    MuiTextField: {
      root: {
        fontFamily: "Poppins",
        "& input": {
          fontFamily: "'Poppins'",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: "17px",
          lineHeight: "26px",
          color: "#000000"
        },
        "& .MuiOutlinedInput-notchedOutline": {
          top: 0
        },
        "& .MuiOutlinedInput-input": {
          padding: 0
        },
        "& legend": {
          display: "none"
        }
      },
      outlined: {}
    },
    MuiPaper: {
      elevation1: {
        boxShadow: "10px 14px 40px rgba(18, 94, 242, 0.1)",
        borderRadius: "5px",
        padding: "1rem"
      }
    },
    MuiTypography: {
      root: {
        "&.MuiTypography-colorTextPrimary": {
          color: hoverOrange
        }
      },
      colorTextSecondary: {
        color: "white"
      }
    },
    MuiDialogActions: {
      root: {
        justifyContent: "flex-start",
        padding: 0,
        paddingTop: "32px"
      }
    },
    MuiButtonGroup: {
      root: {
        "& .MuiButtonGroup-groupedHorizontal:not(:first-child)": {
          borderTopLeftRadius: "0 !important",
          borderBottomLeftRadius: "0 !important"
        },
        "& .MuiButtonGroup-groupedHorizontal:not(:last-child)": {
          borderTopRightRadius: "0 !important",
          borderBottomRightRadius: "0 !important"
        }
      }
    },
    MuiSnackbarContent: {
      root: {
        boxShadow: "10px 14px 40px rgba(18, 94, 242, 0.1)",
        borderRadius: "5px",
        "&.MySnackbarContent-success-1": {
          backgroundColor: orange
        }
      }
    }
  },
  typography: {
    root: {
      fontFamily: "'Poppins'",
      fontStyle: "normal",
      color: "red !important"
    },

    // Folyoszoveg
    body1: {
      fontWeight: 300,
      fontSize: "13px",
      lineHeight: "20px"
    },
    caption: {
      fontWeight: 600,
      fontSize: "13px",
      lineHeight: "20px"
    },
    // H1
    h1: {
      fontWeight: 700,
      fontSize: "60px",
      lineHeight: "120%"
    },
    // cím
    subtitle1: {
      fontWeight: 700,
      fontSize: "22px",
      lineHeight: "34px"
    },
    // H3
    h3: {
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: "120%"
    },
    h4: {
      fontWeight: 700,
      fontSize: "20px",
      lineHeight: "30px"
    },
    h5: {
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "120%"
    },
    h6: {
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: "120%"
    },
    // Megjegyzes
    body2: {
      fontWeight: 300,
      fontSize: "14px",
      lineHeight: "21px"
    }
  }
});
