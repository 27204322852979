import { createStore } from "react-sweet-state";

const initialState = {
    documentUploadShow: false
};

const documentsPageUploadStore = createStore({
  initialState,
  actions: {
      toggleDocumentUploadShow: () => ({ getState, setState }) => {
      const state = getState();
      setState({ documentUploadShow: !state.documentUploadShow });
    }
  },
  name: "documentsPageUploadStore"
});

export default documentsPageUploadStore;
