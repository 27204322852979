import React from "react";
import PageLayout from "../../components/PageLayout";
import { defineMessages } from "react-intl.macro";
import AgreementClaimsList from "./AgreementClaimsList";
import SaveIcon from "@material-ui/icons/Save";
import { useNewContractAgreementClaimPost } from "../../hooks/useApi";
import useHasPermission from "../../components/RoleProvider/useHasPermission";
import {
  ROLE_FLEXIPAY_AGREEMENT_UPLOADER,
  ROLE_FLEXIPAY_INVOICE_MANAGER,
  ROLE_FLEXIPAY_SUPER_ADMIN
} from "../../roles";

const messages = defineMessages({
  pageTitle: {
    id: "pageTitle.agreementClaims",
    defaultMessage: "Szerződés igények"
  }
});

const AgreementClaims = () => {
  const [
    onNewAgreementClaimClick,
    newAgreementLoading
  ] = useNewContractAgreementClaimPost();
  const hasPermission = useHasPermission();

  return (
    <PageLayout
      insidePage
      pay
      titleMessage={messages.pageTitle}
      loading={newAgreementLoading}
      rightSideComponents={
        !hasPermission([
          ROLE_FLEXIPAY_INVOICE_MANAGER,
          ROLE_FLEXIPAY_AGREEMENT_UPLOADER
        ]) || hasPermission(ROLE_FLEXIPAY_SUPER_ADMIN)
          ? [
              {
                props: {
                  onClick: onNewAgreementClaimClick,
                  children: "+ Új igény"
                },
                mobile: {
                  placement: "fab",
                  tooltip: "Mentés",
                  icon: <SaveIcon />
                }
              }
            ]
          : []
      }
    >
      <AgreementClaimsList
        onNewAgreementClaimClick={onNewAgreementClaimClick}
      />
    </PageLayout>
  );
};

export default AgreementClaims;
