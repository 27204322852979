import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import AgreementClaimsList from "../AgreementClaims/AgreementClaimsList";
import EmptySection from "../../components/EmptySection";
import uploadIcon from "../../images/redesignImages/Icon.svg";
import emptyImage2 from "../../images/redesignImages/345-loader-16 1.svg";
import React from "react";
import { useHistory } from "react-router-dom";

const DashboardPendingAgreementClaims = ({ agreementClaims }) => {
  const { push: redirect } = useHistory();
  return Boolean(agreementClaims?.total) ? (
    <Paper
      style={{
        height: "calc(100% - 2rem)",
        paddingLeft: "40px",
        paddingRight: "40px"
      }}
    >
      <Box>
        <Grid
          item
          style={{
            paddingBottom: "2rem"
          }}
        >
          <Typography variant={"h4"}>
            Folyamatban lévő szerződés igények
          </Typography>
        </Grid>
        <AgreementClaimsList hideSearchBar hidePager />
      </Box>
    </Paper>
  ) : (
    <EmptySection
      buttonText={"Új szerződés igény"}
      sectionTitle={"Folyamatban lévő szerződés igények"}
      buttonIcon={uploadIcon}
      imgSrc={emptyImage2}
      onClick={() => redirect("/app/agreement-claims")}
    />
  );
};

export default DashboardPendingAgreementClaims;
