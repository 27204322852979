import RegisterForm from "../RegisterPage/RegisterForm";
import React from "react";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from "@bit/flexibill.flexi.flexi-link";
import { useMutator } from "../../hooks/useApi";
import { isWidthUp } from "@material-ui/core";
import {
  useErrorOccurredNotification,
  useSaveSuccessNotification
} from "../../hooks/useNotificationState";
import { selectErrorNotificationMessage } from "../../utils/selectErrorMessage";
import Footer from "../../components/Footer";
import selectFieldErrorsFromResponse from "../../utils/selectFieldErrorsFromResponse";
import { getFlexipayUrl } from "../../utils/urlHelpers";
import DesktopWelcomeHeader from "../../components/WelcomeHeader/desktop";
import FlexiLinearProgress from "../../components/FlexiLinearProgress";
import MobileWelcomeHeader from "../../components/WelcomeHeader/mobile";
import withWidth from "@material-ui/core/withWidth";

const urlParams = new URLSearchParams(window.location.search);
const source = urlParams.get("source");

const RegisterPage = props => {
  const [post, postLoading] = useMutator("POST");
  const { push: redirect } = useHistory();
  const notifySuccess = useSaveSuccessNotification();
  const notifyError = useErrorOccurredNotification();

  return (
    <Grid container>
      {isWidthUp("md", props.width) ? (
        <Grid container direction={"row"}>
          <Grid item xs={6}>

                <DesktopWelcomeHeader />

          </Grid>
          <Grid item xs>
            <Grid container justify={"center"}>
              <Grid item xs>
                {postLoading && <FlexiLinearProgress />}
                <Grid
                  container
                  justify={"center"}
                  style={{
                    padding: "0 1rem 1rem",
                    width: "410px",
                    margin: "auto",
                    textAlign: "center"
                  }}
                >
                    <Grid item xs={12} style={{marginTop: "50px"}}>
                      <RegisterForm
                        onSubmit={async values => {
                          const { data, error } = await post(
                            getFlexipayUrl("/register"),
                            // { ...values, token, recaptcha: reCaptchaToken }
                            { ...values, source }
                          );

                          if (data) {
                            notifySuccess({
                              text:
                                "Sikeres regisztráció, ellenőrizd az e-mail címedet a regisztráció befejezéséhez!"
                            });
                            redirect("/login");
                          }

                          if (error) {
                            notifyError(selectErrorNotificationMessage(error));
                            return selectFieldErrorsFromResponse(error);
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs style={{ paddingTop: "10px" }}>
                    <Typography variant="body2" style={{ textAlign: "center" }}>
                      Már van fiókod?{" "}
                      <Link
                        style={{
                          color: "#2227ac",
                          textDecoration: "none",
                          fontWeight: "bold"
                        }}
                        href="/login"
                      >
                        Jelentkezz be!
                      </Link>
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      bottom: 18
                    }}
                  >
                    <Footer />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
        </Grid>
      ) : (
        <Grid container align={"center"}>
          <Grid item xs={12} style={{ paddingBottom: "55px" }}>
            <MobileWelcomeHeader />
          </Grid>
          <Grid container style={{ paddingRight: "16px", paddingLeft: "16px" }}>
            <Grid item xs={12}>
              <RegisterForm
                onSubmit={async values => {
                  const { data, error } = await post(
                    getFlexipayUrl("/register"),
                    // { ...values, token, recaptcha: reCaptchaToken }
                    { ...values, source }
                  );

                  if (data) {
                    notifySuccess({
                      text:
                        "Sikeres regisztráció, ellenőrizd az e-mail címedet a regisztráció befejezéséhez!"
                    });
                    redirect("/login");
                  }

                  if (error) {
                    notifyError(selectErrorNotificationMessage(error));
                    return selectFieldErrorsFromResponse(error);
                  }
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs style={{ paddingTop: "10px" }}>
            <Typography variant="body2" style={{ textAlign: "center" }}>
              Már van fiókod?{" "}
              <Link
                style={{
                  color: "#3f51b5",
                  textDecoration: "none",
                  fontWeight: "bold"
                }}
                href="/login"
              >
                Jelentkezz be!
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Footer />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default withWidth()(RegisterPage);
