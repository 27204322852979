import PageLayout from "../../components/PageLayout";
import React from "react";
import { defineMessages } from "react-intl.macro";
import FlexiBarChart from "@bit/flexibill.flexi.flexi-bar-chart";
import { useHistory } from "react-router-dom";
import ContractList from "../FactorContractsPage/ContractList";
import InProgressList from "./InProgressList";

const messages = defineMessages({
  pageTitle: {
    id: "pageTitle.tasksPage",
    defaultMessage: "Folyamatban lévő ügyek"
  }
});

const AgreementsInProgressPage = ({ statusCountSum, statusCountChartData }) => {
  const { push: redirect } = useHistory();
  return (
    <PageLayout insidePage pay titleMessage={messages.pageTitle}>
      {/*<div>*/}
      {/*  <FlexiBarChart*/}
      {/*    onButtonClick={() => redirect("/app/invoice")}*/}
      {/*    data={statusCountChartData}*/}
      {/*    onStatusClick={status => redirect(`/app/invoice/${status}`)}*/}
      {/*    minHeight={185}*/}
      {/*    height={185}*/}
      {/*  />*/}
      {/*</div>*/}
      <div><InProgressList/></div>
    </PageLayout>
  );
};
export default AgreementsInProgressPage;
