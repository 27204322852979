import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    console.log("from ErrorBoundary: ", error, info);
  }

  render() {
    if (this.state.hasError) {
      const Wrapper = this.props.suppressPaper ? "div" : Paper;

      return (
        <Wrapper>
          <Grid container alignItems={"baseline"}>
            <p style={{ fontSize: "100px", margin: "2rem" }}>🤷‍♂️</p>
            <Typography variant={"h5"}>Valami hiba történt...</Typography>
          </Grid>
        </Wrapper>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
