import { createStore } from "react-sweet-state";

const initialState = {
  variant: "",
  text: "",
  open: false
};

const makeShowNotification = variant => ({ text }) => ({
  setState,
  getState
}) => {
  setState({
    variant,
    text,
    open: true
  });
};

const notificationStore = createStore({
  initialState,
  actions: {
    showSuccess: makeShowNotification("success"),
    showWarning: makeShowNotification("warning"),
    showInfo: makeShowNotification("info"),
    showError: makeShowNotification("error"),
    showNotification: ({ variant, text }) =>
      makeShowNotification(variant)({ text }),
    hideNotification: () => ({ setState, getState }) => {
      setState({ ...getState(), open: false });
    }
  },
  name: "notification"
});

export default notificationStore;
