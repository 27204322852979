import PageLayout from "../../components/PageLayout";
import InvoiceForm from "../../components/InvoiceForm";
import React, { useCallback } from "react";
import useRedirect from "../../hooks/useRedirect";
import { useErrorOccurredNotification } from "../../hooks/useNotificationState";
import {
  useInvoiceFormPageData,
  useProfile,
  useProfileData
} from "../../hooks/useApi";
import { selectErrorNotificationMessage } from "../../utils/selectErrorMessage";
import { defineMessages } from "react-intl.macro";
import selectFieldErrorsFromResponse from "../../utils/selectFieldErrorsFromResponse";
import { ROLE_FLEXIPAY_CUSTOMER_PLUS } from "../../roles";
import useHasPermission from "../../components/RoleProvider/useHasPermission";

const messages = defineMessages({
  pageTitle: {
    id: "pageTitle.invoiceFormPage",
    defaultMessage: "Számla feltöltése"
  }
});

const submitInvoiceForm = () => {
  document
    .getElementById("invoiceForm")
    .dispatchEvent(new Event("submit", { cancelable: true }));
};

const InvoiceFormPage = ({ history }) => {
  const { agreements, create, loading } = useInvoiceFormPageData();
  const { mainCompany } = useProfileData();
  const hasPermission = useHasPermission();
  const isScf = hasPermission();
  const [redirectEl, redirect] = useRedirect();
  const goBack = useCallback(() => history.push("/app/invoice"), []);
  // const notifySuccessSave = useSaveSuccessNotification();
  const notifyError = useErrorOccurredNotification();
  return (
    redirectEl || (
      <PageLayout
        insidePage
        onBackButtonClick={goBack}
        pay
        backButton
        titleMessage={messages.pageTitle}
        rightSideComponents={[
          {
            props: {
              children: "Mentés",
              disabled: loading,
              onClick: submitInvoiceForm
            },
            mobile: {
              placement: "toolbar"
            }
          }
        ]}
        loading={loading}
      >
        {!loading && (
          <InvoiceForm
            onSubmit={async values => {
              const { files, ...invoiceValues } = values;

              const { data: successResponse, error } = await create(
                invoiceValues,
                files
              );

              if (successResponse) {
                redirect("/app/invoice");
                // notifySuccessSave();
              } else {
                notifyError(selectErrorNotificationMessage(error));
                return selectFieldErrorsFromResponse(error);
              }
            }}
            partnerOptions={
              isScf
                ? (agreements || [])
                    .filter(
                      contract =>
                        contract.customerFactoring &&
                        contract.status === "active"
                    )
                    .map(contract => ({
                      value: contract.agreementId,
                      text: contract.customerName,
                      currency: contract.currency
                    }))
                : (agreements || [])
                    .filter(contract => contract.status === "active")
                    .map(contract => ({
                      value: contract.agreementId,
                      text: contract.customerName,
                      currency: contract.currency
                    }))
            }
          />
        )}
      </PageLayout>
    )
  );
};

export default InvoiceFormPage;
