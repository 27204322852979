import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import React, { useState } from "react";
import { TextField } from "final-form-material-ui";
import InputAdornment from "@material-ui/core/InputAdornment";
import { IconButton } from "@material-ui/core";

const PasswordField = props => {
  const [showPass, setShowPass] = useState(false);

  const adornment = showPass ? <VisibilityOffIcon /> : <VisibilityIcon />;

  return (
    <TextField
      {...props}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={() => setShowPass(!showPass)}>
              {adornment}
            </IconButton>
          </InputAdornment>
        )
      }}
      type={showPass ? "text" : "password"}
    />
  );
};

export default PasswordField;
