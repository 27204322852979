import Grid from "@material-ui/core/Grid";
import React from "react";
import { TextField } from "final-form-material-ui";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import { Field, Form } from "react-final-form";
import { composeValidators, email, required } from "../../../utils/validators";

const ForgottenPasswordForm = props => {
  return (
    <Grid
      container
      justify={"center"}
    >
      <Grid item xs={12} style={{ paddingTop: "32px" }}>
        <Typography
          variant={"h5"}
          align={"center"}
          fontSize={22}
          style={{ marginBottom: 24 }}
        >
          Jelszó visszaállítása
        </Typography>
      </Grid>
      <Grid item xs={12} style={{marginBottom: 24}}>
        <Typography variant="body2" align="center" fontSize={12}>
          Add meg a regisztrációnál használt e-mail címed, és elküldjük a jelszó
          helyreállításához szükséges linket.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Form
          onSubmit={props.onSubmit}
          render={({ handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Grid container style={{ marginBottom: "32px" }}>
                  <Grid item style={{ width: "100%" }}>
                    <Field
                      type={"email"}
                      name={"email"}
                      label={"E-mail cím"}
                      component={TextField}
                      validate={composeValidators(email, required)}
                      fullWidth
                      style={{ paddingBottom: 32 }}
                    />
                      <Grid item xs={12} style={{ textAlign: "center" }}>
                        <Button style={{margin: "auto"}} type="submit">Küldés</Button>
                      </Grid>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography
          align="center"
          variant="body2"
        >
          <Link
            style={{
              color: "#2227AC",
              textDecoration: "none",
              fontWeight: "bold"
            }}
            href="/login"
          >
            Vissza a bejelentkezéshez
          </Link>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ForgottenPasswordForm;
