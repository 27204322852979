import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import LeftArrowIcon from "@material-ui/icons/KeyboardArrowLeft";
import RightArrowIcon from "@material-ui/icons/KeyboardArrowRight";
import Typography from "@material-ui/core/Typography";
import slideImage0 from "../../images/navDataTour/0.png";
import slideImage1 from "../../images/navDataTour/1.png";
import slideImage10 from "../../images/navDataTour/10.png";
import slideImage11 from "../../images/navDataTour/11.png";
import slideImage12 from "../../images/navDataTour/12.png";
import slideImage2 from "../../images/navDataTour/2.png";
import slideImage3 from "../../images/navDataTour/3.png";
import slideImage4 from "../../images/navDataTour/4.png";
import slideImage5 from "../../images/navDataTour/5.png";
import slideImage6 from "../../images/navDataTour/6.png";
import slideImage7 from "../../images/navDataTour/7.png";
import slideImage8 from "../../images/navDataTour/8.png";
import slideImage9 from "../../images/navDataTour/9.png";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Slider from "react-slick";
import withStyles from "@material-ui/core/styles/withStyles";

const LeftArrow = withStyles({
  root: {
    position: "absolute",
    left: "-3rem",
    zIndex: 9999,
    padding: "0",
    width: "24px",
    height: "24px",
    "&:before": { content: "none" },
    "&:hover": { color: "rgba(0, 0, 0, 0.54)" },
    "&:focus": {
      color: "rgba(0, 0, 0, 0.54)",
      outline: "none",
      background: "transparent"
    }
  }
})(({ currentSlide, slideCount, ...rest }) => (
  <IconButton {...rest}>
    <LeftArrowIcon />
  </IconButton>
));

const RightArrow = withStyles({
  root: {
    position: "absolute",
    right: "-3rem",
    zIndex: 9999,
    padding: "0",
    width: "24px",
    height: "24px",
    "&:before": { content: "none" },
    "&:hover": { color: "rgba(0, 0, 0, 0.54)" },
    "&:focus": {
      color: "rgba(0, 0, 0, 0.54)",
      outline: "none",
      background: "transparent"
    }
  }
})(({ currentSlide, slideCount, ...rest }) => (
  <IconButton {...rest}>
    <RightArrowIcon />
  </IconButton>
));

const Carousel = withStyles(theme => {
  return {
    root: {
      "& .slick-track": {
        display: "flex",
        alignItems: "center",
        "& .slick-slide": {
          display: "flex"
        }
      },
      "& .slick-dots": {
        bottom: 0
      }
    }
  };
})(Slider);

const items = [
  {
    title: "0. lépés",
    texts: [
      "Tedd magad szabaddá a következő 10 percre és kösd össze a FlexiBill és az Online Számla felületét néhány egyszerű lépéssel.",
      "Gyors tipp: Készítsd elő az ügyfélkapus adataidat és az adószámodat."
    ],
    src: slideImage0
  },
  {
    title: "1. lépés",
    texts: [
      "Menj fel a NAV Online számla oldalára\n" +
        "(https://onlineszamla.nav.gov.hu).\n" +
        "Nagyon fontos, ha a böngészőből keresel rá az oldara, figyel arra, hogy ne a teszt környezetet nyisd meg!\n"
    ],
    src: slideImage1
  },
  {
    title: "2. lépés",
    texts: [
      "A “Regisztrálok” gomra kattintva a következő felületen az “ADATSZOLGÁLTATÁSRA KÖTELEZETT ADÓZÓI REGISZTRÁCIÓ” panelt kell keresned, mellyel elkezdődik a tényleges regisztráció. Ne felejtsd el az adatkezelési tájékoztatót elolvasni és hozzájárulni az adataid kezeléséhez a jelölőnégyzet kipipálásával!"
    ],
    src: slideImage2
  },
  {
    title: "3. lépés",
    texts: [
      "A regisztráció előfeltétele, hogy rendelkezz ügyfélkapuval. Az Ügyfélkapun a belépéshez szükséges adataid megadásával megtörténik az ügyfélkapus azonosítás."
    ],
    src: slideImage3
  },
  {
    title: "4. lépés",
    texts: [
      "Az ügyfélkapus azonosítás után add meg az adóazonosítódat, majd kattints az ellenőrzés gombra.",
      "Ha hibaüzenetet kapnál, ellenőrizd, hogy helyesen adtad-e meg az adóazonosító jeled.",
      "Abban az esetben, ha már regisztráltál, lehetőséged van arra, hogy a regisztrált felhasználónevedet több adószámmal használd. Ez abban az esetben célszerű, ha más nevében is jogosult vagy eljárni. Ez nem kötelező, ezért új felhasználónevet is választhatsz."
    ],
    src: slideImage4
  },
  {
    title: "5. lépés",
    texts: [
      "Válaszd ki, melyik vállalkozás típusba tartozol - gazdálkodó szervezetként (pl. Kft., Bt.) vagy egyéni vállalkozóként (pl. kisadózó, őstermelő, adószámmal rendelkező magánszemély) tudsz regisztrálni.",
      "Ha a gazdálkodó szervezetet választod, akkor kizárólag az adószámot kell feltüntetned. ",
      "Abban az esetben, ha egyéni vállalkozó vagy, az adószám mellett az adóazonosító jelet is meg kell adnod. "
    ],
    src: slideImage5
  },
  {
    title: "6. lépés",
    texts: [
      "Ha a rendszer minden adatot érvényesnek talált, akkor megjelenik az előző oldalon választott felhasználónév, majd egy megfelelő erősségű jelszót kell választanod hozzá. Az adóazonosító szám alapján a rendszer automatikusan betölti a NAV nyilvántartásában szereplő adatokat. ",
      "A feltüntett adatok pontos kitöltése biztosítja az adóhatósággal történő kapcsolatot, ezért kell megadni az e-mail címet, a kapcsolattartás nyelvét, valamint a telefonszámot. A kötelező adatok felvétele nélkül a regisztráció nem folytatható."
    ],
    src: slideImage6
  },
  {
    title: "7. lépés",
    texts: [
      "Az adatszolgáltatás után visszakerülsz a “Bejelentkezés” oldalra, ahol beléphetsz az Online Számla fiókodba, a regisztráció során megadott adatokkal. "
    ],
    src: slideImage7
  },
  {
    title: "8. lépés",
    texts: [
      "Belépés után az Online Számla felületén a “Cég-felhasználó kapcsolatok” alatt megjelenik egy panel az adataiddal. Kattints az azonosítódat tartalmazó gombra."
    ],
    src: slideImage8
  },
  {
    title: "9. lépés",
    texts: [
      "Keresd meg a “Felhasználók” menüpontot, majd kattints rá és hozz létre egy technikai felhasználót az “Új felhasználó” gomb segítségével.\n" +
        "Válaszd a “Technikai felhasználó” opciót.\n"
    ],
    src: slideImage9
  },
  {
    title: "10. lépés",
    texts: [
      "Adj meg egy jelszót a technikai felhasználónak. Ne felejts el jogosultságokat adni, pipáld be a “Számlák kezelése” és a “Számlák lekérdezése” jelölőnégyzeteket.\n" +
        "Ha mindent kitöltöttél és bejelöltél nyomd meg a “Mentés” gombot.\n"
    ],
    src: slideImage10
  },
  {
    title: "11. lépés",
    texts: [
      "A rendszer automatikusan generál egy felhasználónevet és kitölti a kapcsolattartás nyelvét. Kattints a “Kulcsgenerálás” gombra és másold ki a megfelelő mezőkből a rendszer által megadott XML aláírókulcsot és XML cserekulcsot.\n"
    ],
    src: slideImage11
  },
  {
    title: "12. lépés",
    texts: [
      "Nyisd meg a FlexiBillt és navigálj a Beállítások menüpontba, majd a “NAV beállítások” almenüpont alatt található “NAV adatkapcsolat” fülre. \n" +
        "Töltsd ki a mezőket és ha minden adatot megadtál, nyomj rá a “Mentés” gombra. A FlexiBill ellenőrzi, hogy helyesek-e az adatok és ha nem kapsz hibaüzenetet, elmenti a felhasználói információkat és KÉSZ is vagy az összekötéssel! \n" +
        "A FlexiBill ettől kezdve a jogszabályok szerint beküld minden 100 ezer forintos Áfa határt meghaladó számlát a NAV rendszerébe.\n"
    ],
    src: slideImage12
  }
];

class NavCarousel extends Component {
  render() {
    const { width } = this.props;

    const settings = {
      dots: isWidthUp("sm", width),
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      prevArrow: <LeftArrow />,
      nextArrow: <RightArrow />
    };

    return (
      <Carousel {...settings} ref={this.carouselRef}>
        {items.map(item => (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <img
                src={item.src}
                alt=""
                style={{
                  maxHeight: "400px",
                  width: "100%",
                  objectFit: "contain"
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: "flex", alignItems: "center" }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="h3" style={{ marginBottom: "20px" }}>
                  {item.title}
                </Typography>
                {item.texts.map(text => (
                  <Typography variant="body2" style={{ marginBottom: "20px" }}>
                    {text}
                  </Typography>
                ))}
              </div>
            </Grid>
          </Grid>
        ))}
      </Carousel>
    );
  }
}

NavCarousel.propTypes = {
  width: PropTypes.string.isRequired
};

export default withWidth()(NavCarousel);
