import React from "react";

const FileInput = props => {
  const inputEl = React.useRef(null);

  return (
    <React.Fragment>
      <input
        type={"file"}
        ref={inputEl}
        style={{ display: "none" }}
        onChange={e => {
          props.onFileChanged(e.target.files);
        }}
        accept={props.accept}
      />
      {props.children({ inputEl })}
    </React.Fragment>
  );
};

export default FileInput;
