import PageLayout from "../../components/PageLayout";
import React from "react";
import Button from "@material-ui/core/Button";
import { useParsedMassInvoices } from "../../hooks/useDataState";
import { Grid } from "@material-ui/core";
import ChipList from "../../components/FlexiChipList";
import MassInvoiceList from "./MassInvoiceList";
import { useErrorOccurredNotification } from "../../hooks/useNotificationState";
import UploadAttachmentsDialog from "../../components/UploadAttachmentsDialog";
import useRedirect from "../../hooks/useRedirect";
import { selectErrorNotificationMessage } from "../../utils/selectErrorMessage";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
import { useSaveMassInvoicePageData } from "../../hooks/useApi";
import billSmile from "../../images/bill/4_bill_smile.svg";
import { defineMessages } from "react-intl.macro";
import Typography from "@material-ui/core/Typography";
import AgreementSelectField from "../../components/FormFields/AgreementSelectField";
import { Form } from "react-final-form";

const messages = defineMessages({
  pageTitle: {
    id: "pageTitle.massInvoiceUpload",
    defaultMessage: "Tömeges számla rögzítés"
  }
});

const SaveMassInvoicePage = ({ width, match }) => {
  const agreementId = match?.params?.agreementId;
  const [selectedInvoices, setSelectedInvoices] = React.useState([]);
  const [files, setFiles] = React.useState([]);
  const [uploadDialogShow, setUploadDialogShow] = React.useState(false);
  const massInvoice = useParsedMassInvoices() || { fileName: "asd-23-.csv" };

  const { saveMassInvoice, loading, uploading } = useSaveMassInvoicePageData();

  const notifyError = useErrorOccurredNotification();
  // const notifySuccess = useSaveSuccessNotification();
  const [redirectEl, redirect] = useRedirect();

  const saveMassInvoices = async agreementId => {
    const { data, error } = await saveMassInvoice(
      selectedInvoices,
      files,
      agreementId
    );

    if (data) {
      redirect("/app/invoice");
      // notifySuccess();
    } else {
      notifyError(selectErrorNotificationMessage(error));
    }
  };

  return (
    redirectEl || (
      <PageLayout
        insidePage
        pay
        suppressTourButton
        titleMessage={messages.pageTitle}
        rightSideComponents={[
          {
            props: {
              disabled: !selectedInvoices.length,
              onClick: () =>
                document
                  .getElementById("agreementForm")
                  .dispatchEvent(new Event("submit", { cancelable: true })),
              children: "Kijelölt számlák feltöltése"
            },
            mobile: {
              placement: "toolbar"
            }
          }
        ]}
        loading={loading}
      >
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Typography variant={"h5"} style={{ marginBottom: "20px" }}>
              Vevő neve
            </Typography>
            <Form
              initialValues={{ agreementId }}
              onSubmit={async values => {
                await saveMassInvoices(values.agreementId);
              }}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit} id={"agreementForm"}>
                  <AgreementSelectField
                    removeLabel
                    disabled={!uploadDialogShow}
                  />
                </form>
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <ChipList
              label={"Feltöltött fájl neve:"}
              chips={[{ label: massInvoice.fileName }]}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} style={{ marginTop: "1.5rem" }}>
                {Boolean(files.length) && (
                  <ChipList
                    label={"Csatolt fájlok:"}
                    chips={files.map((file, i) => ({
                      label: file.file.name,
                      onDelete: () => {
                        const arr = [...files];
                        arr.splice(i, 1);
                        setFiles(arr);
                      }
                    }))}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm>
                <Button
                  fullWidth={isWidthDown("xs", width) && true}
                  onClick={() => {
                    setUploadDialogShow(true);
                  }}
                >
                  Fájl csatolása a számlákhoz
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <MassInvoiceList
              invoices={massInvoice.data}
              onSelectedInvoicesChange={invoices =>
                setSelectedInvoices(invoices)
              }
            />
          </Grid>
        </Grid>
        <UploadAttachmentsDialog
          imgSrc={billSmile}
          uploading={uploading}
          title={"Fájl feltöltése"}
          description={""}
          open={uploadDialogShow}
          onClose={() => setUploadDialogShow(false)}
          files={files}
          onAddFile={newFiles => setFiles([...files, ...newFiles])}
          onDeleteFile={file =>
            setFiles(files.filter(f => f.file.name !== file.name))
          }
          onFileTypeChanged={(file, type) =>
            setFiles(
              files.map(f => {
                if (f.file.name === file.name) {
                  return { ...f, type };
                }

                return f;
              })
            )
          }
          onSendButtonClick={async () => {
            setUploadDialogShow(false);
          }}
        />
      </PageLayout>
    )
  );
};

export default withWidth()(SaveMassInvoicePage);
