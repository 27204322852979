import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import FlexiDocViewer from "@bit/flexibill.flexi.flexi-doc-viewer";
import pdfFileTypeCover from "../../images/pdf-type-cover.svg";
import fileTypeCover from "../../images/file-type-cover.svg";
import React from "react";
import { useIntl } from "react-intl";
import moment from "moment";

const groupDataByMonth = data => {
  if (data) {
    return data.reduce((acc, item) => {
      const date = moment(item.createdAt);
      const yearMonth = date.format("YYYY.MM");
      const existingGroup = acc.find(group => group.yearMonth === yearMonth);

      if (existingGroup) {
        existingGroup.data.push(item);
      } else {
        acc.push({
          yearMonth,
          data: [item]
        });
      }

      return acc;
    }, []);
  }

  return [];
};

const DocumentsByDate = ({ data, onDownloadClick, onShowClick }) => {
  const intl = useIntl();
  const formattedData = groupDataByMonth(data).sort((a, b) => {
    return new Date(b.yearMonth) - new Date(a.yearMonth);
  });

  return formattedData.map(group => (
    <Grid container key={group.yearMonth}>
      <Grid item xs={12} style={{ marginLeft: 16, marginBottom: "1rem" }}>
        <Typography variant={"h6"}>
          {intl.formatDate(group.yearMonth, {
            month: "long",
            year: "numeric"
          })}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {group.data.map(document => {
            return (
              <FlexiDocViewer
                style={{ cursor: "pointer" }}
                key={document.id}
                imgURL={
                  document.mimeType === "application/pdf"
                    ? pdfFileTypeCover
                    : fileTypeCover
                }
                documentName={document.originalName}
                onDownloadClick={() => {
                  onDownloadClick(document);
                }}
                onShowClick={() => {
                  onShowClick(document);
                }}
              />
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  ));
};

export default DocumentsByDate;
