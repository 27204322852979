import Tour from "reactour";
import React from "react";
import { withRouter } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import withWidth from "@material-ui/core/withWidth";
import useDocumentPageUploadState from "../../hooks/useDocumentsPageUploadState";

const ReactTour = ({
  location: { pathname },
  history,
  isOpen,
  closeTour,
  onRequestClose,
  update,
  width
}) => {
  const [currentStep, setCurrentStep] = React.useState(0);
  const [state, actions] = useDocumentPageUploadState();

  let steps =
    pathname === "/app" || pathname === "/app/"
      ? [
          {
            id: 1,
            sectionColor: "#4caf50",
            selector: ".help-button",
            url: "/app",
            content: () => (
              <div>
                <Typography variant="h3" style={{ marginBottom: 15 }}>
                  Üdvözöllek a Flexipayben!
                </Typography>
                <Typography variant="body1" style={{ marginBottom: 30 }}>
                  Tudtad, hogy a faktorálás már egyáltalán nem elérhetetlen,
                  időigényes vagy bonyolult folyamat? Minimális adminisztráció
                  mellett néhány kattintással Te is biztosíthatod vállalkozásod
                  likviditását.
                </Typography>
              </div>
            )
          },
          {
            id: 2,
            sectionColor: "#4caf50",
            selector: ".flexi-stepper",
            url: "/app",
            content: () => (
              <div>
                <Typography variant="h3" style={{ marginBottom: 15 }}>
                  Főoldal
                </Typography>
                <Typography variant="body1" style={{ marginBottom: 30 }}>
                  Bizonytalan vagy hogyan is történik a faktorálás? Támaszkodj
                  erre a segédletre, ami lépésről lépésre végig vezet a
                  folyamaton.
                </Typography>
              </div>
            )
          }
        ]
      : pathname === "/app/invoice"
        ? [
            {
              id: 3,
              sectionColor: "#af2f54",
              selector: "[id='drawer-menu-item-/app/invoice']",
              url: "/app/invoice",
              content: () => (
                <div>
                  <Typography variant="h3" style={{ marginBottom: 30 }}>
                    Számlák
                  </Typography>
                  <Typography variant="body1" style={{ marginBottom: 30 }}>
                    Ezen az oldalon találod a feltöltött számláid.
                  </Typography>
                </div>
              )
            },
            {
              id: 4,
              sectionColor: "#af2f54",
              selector: ".invoice-upload-button",
              url: "/app/invoice",
              content: () => (
                <div>
                  <Typography variant="h3" style={{ marginBottom: 30 }}>
                    Számlák
                  </Typography>
                  <Typography variant="body1" style={{ marginBottom: 30 }}>
                    Kattints a gombra és töltsd fel azokat a számlákat,
                    amelyeket faktorálni szeretnél.
                  </Typography>
                </div>
              )
            },
            {
              id: 8,
              sectionColor: "#af2f54",
              selector: ".invoice-list-page-tabs",
              url: "/app/invoice",
              content: () => (
                <div>
                  <Typography variant="h3" style={{ marginBottom: 30 }}>
                    Számlák
                  </Typography>
                  <Typography variant="body1" style={{ marginBottom: 30 }}>
                    A lista elnevezések egyben a számlák státuszát is jelölik és
                    tájékoztatnak, hogy melyik számlád hol tart a folyamatban.
                  </Typography>
                </div>
              )
            },
            {
              id: 9,
              sectionColor: "#af2f54",
              selector: ".ag-root",
              url: "/app/invoice",
              content: () => (
                <div>
                  <Typography variant="h3" style={{ marginBottom: 30 }}>
                    Számlák
                  </Typography>
                  <Typography variant="body1" style={{ marginBottom: 30 }}>
                    Jelöld ki a faktorálásra szánt számlákat!
                  </Typography>
                </div>
              )
            },
            {
              id: 10,
              sectionColor: "#af2f54",
              selector: ".invoices-to-factoring",
              url: "/app/invoice",
              content: () => (
                <div>
                  <Typography variant="h3" style={{ marginBottom: 30 }}>
                    Számlák
                  </Typography>
                  <Typography variant="body1" style={{ marginBottom: 30 }}>
                    Kattints a gombra … vigyázz. kész. Elindult a Faktorálás
                    folyamata!
                  </Typography>
                </div>
              )
            }
          ]
        : pathname === "/app/invoice/new"
          ? [
              {
                id: 5,
                sectionColor: "#8FA63E",
                selector: ".route-section",
                url: "/app/invoice/new",
                content: () => (
                  <div>
                    <Typography variant="h3" style={{ marginBottom: 30 }}>
                      Számla feltöltése
                    </Typography>
                    <Typography variant="body1" style={{ marginBottom: 30 }}>
                      Itt rögzítheted a számlákat
                    </Typography>
                  </div>
                )
              },
              {
                id: 6,
                sectionColor: "#8FA63E",
                selector: ".invoice-datas",
                url: "/app/invoice/new",
                content: () => (
                  <div>
                    <Typography variant="h3" style={{ marginBottom: 30 }}>
                      Számla adatok
                    </Typography>
                    <Typography variant="body1" style={{ marginBottom: 30 }}>
                      Töltsd ki az adatmezőket a faktorálandó számlád adataival.
                    </Typography>
                  </div>
                )
              },
              {
                id: 7,
                sectionColor: "#8FA63E",
                selector: ".invoice-attachments-upload",
                url: "/app/invoice/new",
                content: () => (
                  <div>
                    <Typography variant="h3" style={{ marginBottom: 30 }}>
                      Csatolmányok
                    </Typography>
                    <Typography variant="body1" style={{ marginBottom: 30 }}>
                      Csatold hozzá a számlaképet vagy teljesítési igazolást.
                    </Typography>
                  </div>
                )
              }
            ]
          : pathname === "/app/factor-contracts-notifications"
            ? [
                {
                  id: 11,
                  sectionColor: "#8d4caf",
                  selector:
                    "[id='drawer-menu-item-/app/factor-contracts-notifications']",
                  url: "/app/factor-contracts-notifications",
                  content: () => (
                    <div>
                      <Typography variant="h3" style={{ marginBottom: 30 }}>
                        Faktor értesítők
                      </Typography>
                      <Typography variant="body1" style={{ marginBottom: 30 }}>
                        Ez egy hasznos menüpont arra, hogy megtekintsd a
                        faktorálási értesítőket, ha kell letöltsd azokat.
                      </Typography>
                    </div>
                  )
                },
                {
                  id: 12,
                  sectionColor: "#8d4caf",
                  selector: ".route-section",
                  url: "/app/factor-contracts-notifications",
                  content: () => (
                    <div>
                      <Typography variant="h3" style={{ marginBottom: 15 }}>
                        Elkészült a Faktorálási értesítő!
                      </Typography>{" "}
                      <Typography variant="body1" style={{ marginBottom: 15 }}>
                        {" "}
                        szerződésben foglaltak szerint elindul a
                        visszaigazoltatás és teljesítés folyamata, pl. ebben az
                        esetben vissza is töltheted az aláírt példányt
                      </Typography>
                    </div>
                  )
                },
                {
                  id: 13,
                  sectionColor: "#8d4caf",
                  selector: "body",
                  url: "/app/factor-contracts-notifications",
                  content: () => (
                    <div>
                      <Typography variant="h3" style={{ marginBottom: 15 }}>
                        Faktorálási értesítők listája
                      </Typography>{" "}
                      <Typography variant="body1" style={{ marginBottom: 15 }}>
                        Ez egy hasznos menüpont arra, hogy megtekintsd a
                        faktorálási értesítőket, ha kell letöltsd azokat.
                      </Typography>
                    </div>
                  )
                },
                {
                  id: 14,
                  sectionColor: "#8d4caf",
                  selector: "body",
                  url: "/app/factor-contracts-notifications",
                  content: () => (
                    <div>
                      <Typography variant="h3" style={{ marginBottom: 30 }}>
                        Faktor értesítők
                      </Typography>
                      <Typography variant="body1" style={{ marginBottom: 30 }}>
                        Szállító aláírása oszlop tájékoztat, hogy aláírtad-e már
                        a faktorálási értesítőt, ha ez már megtörtént a zöld
                        ikon jelzi és nincs semmi dolgod, ha pedig még nem,
                        piros ikont látsz. A vevői visszaigazolás oszlop ikonja
                        hasonló módon arról tájékoztat, hogy a számla vevője
                        meghatározott módon visszaigazolta-e már a számlát, pl.
                        aláírta a faktorálási értesítőt
                      </Typography>
                    </div>
                  )
                },
                {
                  id: 15,
                  sectionColor: "#379baf",
                  selector: "[id='drawer-menu-item-/app/factor-contracts']",
                  url: "/app/factor-contracts-notifications",
                  content: () => (
                    <div>
                      <Typography variant="h3" style={{ marginBottom: 30 }}>
                        Faktorszerződések
                      </Typography>
                    </div>
                  )
                }
              ]
            : pathname === "/app/factor-contracts"
              ? [
                  {
                    id: 16,
                    sectionColor: "#379baf",
                    selector: ".route-section",
                    url: "/app/factor-contracts",
                    content: () => (
                      <div>
                        <Typography variant="h3" style={{ marginBottom: 30 }}>
                          Faktorszerződések
                        </Typography>
                        <Typography
                          variant="body1"
                          style={{ marginBottom: 30 }}
                        >
                          A faktor céggel kötött keretszerződéseidet láthatod
                          vevőnkénti bontásban. Így mindig tisztában lehetsz a
                          pillanatnyi limit kihasználtsággal.
                        </Typography>
                      </div>
                    )
                  }
                ]
              : pathname === "/app/documents"
                ? [
                    {
                      id: 17,
                      sectionColor: "#379baf",
                      selector: "body",
                      url: "/app/factor-contracts",
                      content: () => (
                        <div>
                          <Typography variant="h3" style={{ marginBottom: 30 }}>
                            Dokumentumok
                          </Typography>
                          <Typography
                            variant="body1"
                            style={{ marginBottom: 30 }}
                          >
                            Ebben a menüpontban megtekintheted a faktorálással
                            kapcsolatos dokumentumokat illetve Te is tölthetsz
                            fel saját iratokat.
                          </Typography>
                        </div>
                      )
                    }
                  ]
                : pathname.includes("/app/invoice/edit")
                  ? [
                      {
                        id: 18,
                        sectionColor: "red",
                        selector: "body",
                        url: "/app/invoice/edit",
                        content: () => (
                          <div>
                            <Typography
                              variant="h3"
                              style={{ marginBottom: 30 }}
                            >
                              Számla szerkesztése
                            </Typography>
                            <Typography
                              variant="body1"
                              style={{ marginBottom: 30 }}
                            >
                              Számla szerkesztése
                            </Typography>
                          </div>
                        )
                      }
                    ]
                  : [];

  return (
    <Tour
      rounded={5}
      badgeContent={(curr, tot) => `${curr} / ${tot}`}
      className="react-tour-bubble"
      getCurrentStep={curr => setCurrentStep(curr)}
      accentColor="#142595"
      steps={steps}
      update={steps}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      closeTour={closeTour}
    />
  );
};

export default withWidth()(withRouter(ReactTour));
