import FlexiDialog from "@bit/flexibill.flexi.flexi-dialog";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Button, Link, Typography } from "@material-ui/core";
import {
  withFlexiForm,
  Fields,
  makeExternalFormSubmit
} from "@bit/flexibill.flexi.flexi-form";
import { getUrlWithCompanyId } from "../../utils/urlHelpers";
import FlexiFileUploader from "@bit/flexibill.flexi.flexi-file-uploader";

const DigitalSignatureInstructions = () => {
  return (
    <div>
      <Typography>
        Az alábbi linkek egyikére kattintva Neked is lehet elektronikus
        aláírásod:
      </Typography>
      <br />
      <Box display={"flex"} justifyContent={"space-around"}>
        <Typography variant={"h6"}>
          <Link href={"https://netlock.hu/"} target={"_blank"}>
            netlock.hu
          </Link>
        </Typography>
        <Typography variant={"h6"}>
          <Link href={"https://microsec.hu/"} target={"_blank"}>
            microsec.hu
          </Link>
        </Typography>
      </Box>
    </div>
  );
};

export const FileUploaderDialog = ({
  title,
  description,
  children,
  onSubmit,
  files
}) => {
  const wrapperRef = useRef();

  const openDialog = useCallback(() => {
    if (wrapperRef.current) {
      wrapperRef.current.querySelector("button").click();
    }
  }, [wrapperRef]);

  useEffect(() => {
    if (wrapperRef.current) {
    }
  }, []);

  return (
    <div>
      {children && children({ openDialog })}
      <div ref={wrapperRef} style={{ display: "none" }}>
        <FlexiFileUploader
          title={title}
          files={files}
          onSubmit={onSubmit}
          getUploadDialogContent={() => <Typography>{description}</Typography>}
        />
      </div>
    </div>
  );
};

const BasicSection = () => (
  <div>
    <Typography>
      Ha nem szeretnél elektornikus aláírást használni, akkor aláírhatod a
      szerződést más módon is:
    </Typography>
    <ul>
      <li>
        <Typography>
          gyere be az irodába (1066 Budapest, Mozsár utca 16.)
        </Typography>
      </li>
      <li>
        <Typography>értékesítő házhoz viszi</Typography>
      </li>
      <li>
        <Typography>
          elküldjük e-mailben, nyomtasd ki 3 példányban, írd alá és 2 példányt
          küldj el nekünk postán
        </Typography>
      </li>
    </ul>
  </div>
);

const submit = makeExternalFormSubmit("digital-signature");
const FormSection = withFlexiForm({ id: "digital-signature" })(
  ({ values, form }) => {
    useEffect(() => {
      if (values.signature !== "NO") {
        form.mutators.setValue("signatureWanted", "true");
      }
    }, [values.signature, values.signatureWanted]);
    return (
      <div>
        <Typography>
          A dokumentumot hitelesítheted Digitális aláírás funkciónk
          segítségével. Így időt spórolhatsz meg, hiszen nem kell a kinyomtatott
          és aláírt formátumot feltöltened, mégis ugyanúgy hiteles, mintha
          kézzel írtad volna alá!
        </Typography>
        <Fields
          config={[
            [
              {
                fieldType: "inputGroup",
                groupName: "Van már céges elektronikus aláírásod?",
                fieldsConfig: [
                  [
                    {
                      fieldType: "radio",
                      name: "signature",
                      value: "NETLOCK",
                      label: "Igen, Netlock",
                      gridSize: "auto",
                      required: true
                    },
                    {
                      fieldType: "radio",
                      name: "signature",
                      value: "MICROSEC",
                      label: "Igen, Microsec",
                      gridSize: "auto",
                      required: true
                    },
                    {
                      fieldType: "radio",
                      name: "signature",
                      value: "NO",
                      label: "Nincs",
                      gridSize: "auto",
                      required: true
                    }
                  ]
                ]
              }
            ]
          ]}
        />
        <div style={{ opacity: values?.signature !== "NO" && "0.6" }}>
          <Fields
            config={[
              [
                {
                  fieldType: "inputGroup",
                  groupName: "Amennyiben nincs, szeretnél?",
                  fieldsConfig: [
                    [
                      {
                        fieldType: "radio",
                        name: "signatureWanted",
                        value: "true",
                        label: "Igen",
                        gridSize: "auto",
                        required: values.signature === "NO",
                        disabled: values.signature !== "NO" || !values.signature
                      },
                      {
                        fieldType: "radio",
                        name: "signatureWanted",
                        value: "false",
                        label: "Nem",
                        gridSize: "auto",
                        required: values.signature === "NO",
                        disabled: values.signature !== "NO" || !values.signature
                      }
                    ]
                  ]
                }
              ]
            ]}
          />
        </div>
      </div>
    );
  }
);

const DigitalSignatureDialog = ({ open, onClose, onSubmit, files }) => {
  const [state, setState] = useState("FORM");

  useEffect(() => {
    if (!open) {
      setTimeout(() => setState("FORM"), 300);
    }
  }, [open]);

  let dialogActions;
  switch (state) {
    case "FORM":
      dialogActions = (
        <Box display={"flex"} style={{ gap: "1rem" }}>
          <Button onClick={submit}>Tovább</Button>
          <Button variant={"text"} onClick={onClose}>
            Mégse
          </Button>
        </Box>
      );
      break;

    case "NETLOCK":
    case "MICROSEC":
    case "BASIC":
    case "INSTRUCTIONS":
      dialogActions = <Button onClick={onClose}>Rendben</Button>;
      break;
  }

  let title;
  switch (state) {
    case "NETLOCK":
    case "MICROSEC":
    case "FORM":
      title = "Digitális aláírás";
      break;

    case "BASIC":
      title = "Így írhatod alá kézzel";
      break;
    case "INSTRUCTIONS":
      title = "Te is szeretnél e-aláírást?";
      break;
  }

  return (
    <FileUploaderDialog onSubmit={onSubmit} files={files}>
      {({ openDialog }) => (
        <FlexiDialog
          title={title}
          open={open}
          dialogActions={dialogActions}
          size={"small"}
        >
          {(state === "FORM" ||
            state === "MICROSEC" ||
            state === "NETLOCK") && (
            <FormSection
              onSubmit={values => {
                if (values.signature) {
                  if (values.signature !== "NO") {
                    setState(values.signature);
                  } else {
                    if (values.signatureWanted === "true") {
                      setState("INSTRUCTIONS");
                    } else {
                      setState("BASIC");
                    }
                  }
                }
              }}
            />
          )}

          {state === "MICROSEC" && openDialog()}
          {state === "NETLOCK" && openDialog()}

          {state === "INSTRUCTIONS" && <DigitalSignatureInstructions />}
          {state === "BASIC" && <BasicSection />}
        </FlexiDialog>
      )}
    </FileUploaderDialog>
  );
};

export default DigitalSignatureDialog;
