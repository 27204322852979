import { createStore } from "react-sweet-state";

const initialState = {
  isTourOpen: false
};

const tourStore = createStore({
  initialState,
  actions: {
    toggleTourOpen: () => ({ getState, setState }) => {
      const state = getState();
      setState({ isTourOpen: !state.isTourOpen });
    }
  },
  name: "tour"
});

export default tourStore;
