import React, { useEffect, useState } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import MaintenancePage from "./MaintenancePage";
import { getConfig } from "../../utils/config";

const LOADING_STATE = "LOADING";
const SUCCESS_STATE = "SUCCESS";
const MAINTENANCE_STATE = "MAINTENANCE";

const HealthCheck = ({ children }) => {
  const [state, setState] = useState(LOADING_STATE);

  useEffect(() => {
    fetch(`${getConfig("API_URL")}/healthz`)
      .then(response => {
        if (response.ok) {
          setState(SUCCESS_STATE);
        } else {
          setState(MAINTENANCE_STATE);
        }
      })
      .catch(() => setState(MAINTENANCE_STATE));
  }, []);

  switch (state) {
    case LOADING_STATE:
      return <LinearProgress />;

    case SUCCESS_STATE:
      return children;

    case MAINTENANCE_STATE:
      return <MaintenancePage />;

    default:
      return <MaintenancePage />;
  }
};

export default HealthCheck;
