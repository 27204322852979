import React from "react";
import FlexiDialog from "@bit/flexibill.flexi.flexi-dialog";
import { defineMessages } from "react-intl.macro";
import { FormattedHTMLMessage } from "react-intl";

const messages = defineMessages({
  title: {
    id: "tempDialog.title",
    defaultMessage: "Kedves Ügyfelünk!"
  },
  description: {
    id: "tempDialog.description",
    defaultMessage: `2023.10.13-án, pénteken cégünk továbbképzést tart dolgozóinak a még gördülékenyebb ügymenet
előteremtésének érdekében.<br><br>
Ezen a napon az ügyfélszolgálat nem lesz elérhető, számlák feldolgozásra nem kerülnek és az utalások is szünetelnek.
A Flexibill természetesen zavartalanul működik, az ide feltöltött és emailben érkező számlákat, kéréseket is hétfőn,
2023.10.16-án fogjuk feldolgozni.<br><br>
Megértéseteket köszönjük!<br><br>
A Flexibill és Báv Faktor csapata`
  }
});

const TemporaryDialog = ({ open, onClose }) => {
  return (
    <FlexiDialog open={open} onClose={onClose} title={"Kedves Ügyfelünk!"}>
      <FormattedHTMLMessage {...messages.description} />
    </FlexiDialog>
  );
};
export default TemporaryDialog;
