import React from "react";
import { useIntl } from "react-intl";
import { defineMessages } from "react-intl.macro";
import useRoleFilter from "../../hooks/useRoleFilter";
import FlexiExpandableRemoteList from "@bit/flexibill.flexi.flexi-expandable-remote-list";
import { getFlexipayUrl } from "../../utils/urlHelpers";
import { getStoredToken } from "../../utils/authentication";
import Chip from "@material-ui/core/Chip";
import redirect from "react-router-dom/es/Redirect";
import VisibilityIcon from "@material-ui/icons/Visibility";

const columnMessages = defineMessages({
  supplierName: {
    id: "listColumnHeader.coworkers.supplierName",
    defaultMessage: "Szállító neve"
  },
  customerName: {
    id: "listColumnHeader.coworkers.customerName",
    defaultMessage: "Vevő(k) neve"
  },
  type: {
    id: "listColumnHeader.coworkers.type",
    defaultMessage: "Típus"
  }
});


const getColumns = intl => {
  return [
    {
      headerName: intl.formatMessage(columnMessages.supplierName),
      field: "supplierName",
      sortable: true,
      autoHeight: true,
      resizable: true,
      mobileCellXs: 12,
      cellClass: "cell-wrap-text"
    },
    {
      headerName: intl.formatMessage(columnMessages.customerName),
      field: "customersName",
      sortable: true,
      autoHeight: true,
      cellClass: "cell-wrap-text"
    },
    {
      headerName: intl.formatMessage(columnMessages.type),
      field: "type",
      sortable: true,
      autoHeight: true,
      cellClass: "cell-wrap-text"
    },
    {
      headerName: "",
      field: "status",
      sortable: true,
      resizable: true,
      autoHeight: true,
      cellRendererFramework: ({ value }) => {
        switch (value) {
          case "overdue":
            return (
              <Chip
                style={{ backgroundColor: "#EDD6D9", color: "#D3302F" }}
                label={"Lejárt feladat"}
              />
            );
          case "no_Task":
            return (
              <Chip
                style={{ backgroundColor: "#CDF6E5", color: "#02BE6F" }}
                label={"Nincs feladat"}
              />
            );
          case "has_task":
            return (
              <Chip
                style={{ backgroundColor: "#FBE9CB", color: "#DD8900" }}
                label={"Feladatod van"}
              />
            );
        }
      }
    },
    {
      headerName: "",
      field: "link",
      sortable: true,
      resizable: true,
      autoHeight: true,
      cellRendererFramework: ({ value }) => (
        <a href={"https://admin.faktor.beta.flexibill.hu " + `${value}`}>
          Megnézem adminon
        </a>
      )
    },
  ];
};

const TasksList = ({
  listRef
}) => {
  const intl = useIntl();

  // const getMenuItems = makeGetMenuItems(
  //   intl,
  //   onResendEmailClick,
  //   onActivateClick,
  //   onDeactivationClick,
  //   onEditClick,
  //   onDeleteClick
  // );


  let columnDefs = useRoleFilter(
    getColumns(intl)
  );

  return (
    <>
      <FlexiExpandableRemoteList
        ref={listRef}
        columnDefs={columnDefs}
        mobileCellSpacing={0}
        url={getFlexipayUrl("/sales/task")}
        token={getStoredToken()}
        suppressExpand
      />
    </>
  );
};

export default TasksList;
